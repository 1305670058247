/* eslint-disable */
import { eventEmitter } from "../../events";
import { moduleManager } from "../../moduleManager";
import { renderScript } from '../../utilities/renderScript';
import { dom } from "../../global";
import CONSTANTS from '../../constants.json';
import { getUnits } from "../../unitManager";
import { bbLogger } from "../../utilities/logger";
import { bidCache } from "../../services/bidCache";
import { getConfig } from "../../config";
import { auction } from "../../bidbarrel";
import { errorReporting } from '../../services/errorReporting';
import errorReplacer from '../../utilities/helpers/errorReplacer';

const {EVENTS: {BAD_BID, BAD_AD}, MODULES: {CONFIANT}} = CONSTANTS;

const confiantBase = (function(){

  let cancelEventListener = () => false;

  function register(){
    confiantVendor();
  }

  function initialize(){
    if(getConfig('confiant.badAdRefresh')){
      cancelEventListener = eventEmitter.on(BAD_AD, unit => {
        bbLogger.logInfo("Refreshing unit due to bad ad detected", unit);
        auction([unit.code]);
      })
    }
  }

  function deregister(){
    cancelEventListener();
  }

  return {
    name: CONFIANT,
    register,
    initialize,
    deregister
  }

})()

export const confiant = moduleManager.register(confiantBase);

function confiantVendor(){
    //From documentation linked in https://atlassian.cbsi.com/jira/browse/REVSYS-1365
    var myPropertyId='J3UXFee1xclY-bfFlWh1mIZ_phU';
    var w = dom().window;

    w.confiant = w.confiant || {};
    w.confiant[myPropertyId] = w.confiant[myPropertyId] || {clientSettings: {}};
    var clientSettings = window.confiant[myPropertyId].clientSettings || (window.confiant[myPropertyId].clientSettings = {});

    clientSettings.callback = function(blockingType, blockingId, isBlocked, wrapperId, tagId, impressionData) {
      try {
        const units = getUnits();
        let unitCode;
        if(impressionData && impressionData.prebid  && impressionData.prebid.adId) {
          unitCode = impressionData.prebid.s;
          eventEmitter.emit(BAD_BID, impressionData.prebid.adId, units[impressionData.prebid.s], bidCache.getBidByAdId(impressionData.prebid.adId), ...arguments);
        } else {
          unitCode = impressionData.dfp.s;
        }
        bbLogger.atVerbosity(4).logInfo("Confiant Blocked an ad", units[unitCode], arguments);
        eventEmitter.emit(BAD_AD, units[unitCode], ...arguments);
      } catch (err) {
        bbLogger.logError("Error in confiant bad ad detected callback", err);
        const errorObj = new Error(`Error in confiant bad ad detected callback. ${JSON.stringify(err,errorReplacer)}`);
        errorReporting.report(errorObj);
      }
     };

    renderScript({
        src: "https://cdn.confiant-integrations.net/J3UXFee1xclY-bfFlWh1mIZ_phU/gpt_and_prebid/config.js",
        async: true,
        id: 'bb-confiant'
    });
}
