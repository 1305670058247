import CONSTANTS from './constants.json';
import { logger } from './utilities/logger.js';
import { exposureApi } from './exposureApi.js';
import isEmpty from './utilities/helpers/isEmpty.js';
import { errorReporting } from './services/errorReporting.js';

const { REQUEST_MANAGER } = CONSTANTS.LOGS;
const rmLogger = logger({ name: REQUEST_MANAGER.LABEL, bgColor: REQUEST_MANAGER.COLOR, textColor: '#FFF' });

/**
 * Request Manager module. This module handles logic specifically around holding up the ad server request until all requesters have been returned
 *
 * @module requestManager
 * @private
 */
export const requestManager = (function reqMan () {
	/**
	 * Variable to track the requesters and if they're back
	 *
	 * @memberof requestManager
	 * @private
	 */
	const requestRegistry = {};
	/**
	 * Queued Callback array that get cleared after each request "set"
	 *
	 * @memberof requestManager
	 * @private
	 */
	let callbacks = [];
	/**
	 * Registers a requester
	 *
	 * @param {string} id the id of the requester
	 * @memberof requestManager
	 * @private
	 */
	function register(id) {
		if (typeof requestRegistry[id] === 'undefined') {
			requestRegistry[id] = false;
			rmLogger.atVerbosity(2).logInfo('Registering Requester', id);
		}
	}
	/**
	 * Resets the request set
	 *
	 * @memberof requestManager
	 * @private
	 */
  function reset() {
    rmLogger.atVerbosity(2).logInfo('Reseting requests');
    Object.keys(requestRegistry).forEach((requester) => {
      requestRegistry[requester] = false;
    });
  }
  /**
	 * Method to run all queue items
	 *
	 * @memberof requestManager
	 * @private
	 */
	function runQueueItems() {
		rmLogger.atVerbosity(2).logInfo('Running Request Manager callbacks');
		for (let index = 0; index < callbacks.length; index+=1) {
			try {
				callbacks[index]();
			} catch (err) {
				rmLogger.atVerbosity(1).logError(err);
				errorReporting.report(err);
			}
		}
		callbacks = [];
	}
	/**
	 * Method to register when a requester is finished
	 *
	 * @param {String} id
	 * @memberof requestManager
	 * @private
	 */
	function done(id) {
		rmLogger.atVerbosity(2).logInfo('Request done', id);
		requestRegistry[id] = true;
		if (isEmpty(requestRegistry) || Object.values(requestRegistry).indexOf(false) === -1) {
			runQueueItems();
		}
	}
	/**
	 * Adds a callback to the queue. If the registered requesters object is empty the callback is ran immediately
	 *
	 * @param {Function} callback
	 * @memberof requestManager
	 * @private
	 */
	function enqueue(callback) {
		if (isEmpty(requestRegistry)) {
			rmLogger.logInfo('No registered requesters. Running callback.');
			callback();
		} else {
			callbacks.push(callback);
		}
	}

	/**
	 * Gets all registered requesters
	 *
	 * Available via `BidBarrel.exposedApi().getRequesters()`
	 *
	 * @returns {String[]}
	 * @memberof requestManager
	 * @exposed
	 */
	function getRequesters() {
		return Object.keys(requestRegistry);
	}
	exposureApi.expose({
		getRequesters
	});
	return {
		register,
		reset,
		done,
		enqueue
	};
})();
export default requestManager;
