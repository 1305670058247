import { richObject } from './utilities/richObject.js';

/**
 * Page targeting object
 *
 * @type {Object}
 * @memberof Targeting
 * @exposed readonly
 */
 export const pageTargetingObj = richObject({});
 export function getPageTargeting(...args) {
	return pageTargetingObj.getValue(...args);
}
pageTargetingObj.getPageTargeting=getPageTargeting;

export default pageTargetingObj;
