import { dom } from '../global.js';
import { logger } from '../utilities/logger.js';

const bcLogger = logger({ name: 'bidcaching', textColor: '#FFF', bgColor: '#00aa00' });
/*
 * A stub for bid caching logic
  Note the meat of this has been move to the bidcachingModule
 *
 * @private
 */
// eslint-disable-next-line func-names
export const bidCache = (function() {

    function getBidByAdId() { bcLogger.logInfo("stub getBidByAdId"); return []; }
    function getLatestPendingBid() { bcLogger.logInfo("stub getLatestPendingBid"); return true && []; }
    function getAllBids(){ bcLogger.logInfo("stub getAllBids"); return {}; }
    function evaluateWinningBids() { bcLogger.logInfo("stub evaluateWinningBids"); return []; }

    /**
	 * Renders winning ad to page
	 *
	 * @param {Document} doc
	 * @param {string} id
	 * @memberof bidCache
	 * @private
	 */
	function renderAd(doc, id) {
		// eslint-disable-next-line no-undef
		if (typeof pbjs.renderAd !== 'undefined') {
			// eslint-disable-next-line no-undef
			pbjs.renderAd(doc, id);
		}
	}
    /**
         * Bootstraps service
         *
         * @memberof bidCache
         * @private
         */
    function register() {
        dom().window.adFlow = dom().window.adFlow || {};
        dom().window.adFlow.renderAd = renderAd;
    }
    register();

    return {
        getAllBids,
        evaluateWinningBids,
        getBidByAdId,
        getLatestPendingBid
    };
})();
export default bidCache;
