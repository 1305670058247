/**
 * Provides mapping objects used by bidders for categoryTranslation type logic for Adpod feature
 */
export const APPNEXUS_CATEGORY_MAPPING = {
  '1': 'IAB20-3',
  '2': 'IAB18-5',
  '3': 'IAB10-1',
  '4': 'IAB2-3',
  '5': 'IAB19-8',
  '6': 'IAB22-1',
  '7': 'IAB18-1',
  '8': 'IAB12-3',
  '9': 'IAB5-1',
  '10': 'IAB4-5',
  '11': 'IAB13-4',
  '12': 'IAB8-7',
  '13': 'IAB9-7',
  '14': 'IAB7-1',
  '15': 'IAB20-18',
  '16': 'IAB10-7',
  '17': 'IAB19-18',
  '18': 'IAB13-6',
  '19': 'IAB18-4',
  '20': 'IAB1-5',
  '21': 'IAB1-6',
  '22': 'IAB3-4',
  '23': 'IAB19-13',
  '24': 'IAB22-2',
  '25': 'IAB3-9',
  '26': 'IAB17-18',
  '27': 'IAB19-6',
  '28': 'IAB1-7',
  '29': 'IAB9-30',
  '30': 'IAB20-7',
  '31': 'IAB20-17',
  '32': 'IAB7-32',
  '33': 'IAB16-5',
  '34': 'IAB19-34',
  '35': 'IAB11-5',
  '36': 'IAB12-3',
  '37': 'IAB11-4',
  '38': 'IAB12-3',
  '39': 'IAB9-30',
  '41': 'IAB7-44',
  '42': 'IAB7-1',
  '43': 'IAB7-30',
  '50': 'IAB19-30',
  '51': 'IAB17-12',
  '52': 'IAB19-30',
  '53': 'IAB3-1',
  '55': 'IAB13-2',
  '56': 'IAB19-30',
  '57': 'IAB19-30',
  '58': 'IAB7-39',
  '59': 'IAB22-1',
  '60': 'IAB7-39',
  '61': 'IAB21-3',
  '62': 'IAB5-1',
  '63': 'IAB12-3',
  '64': 'IAB20-18',
  '65': 'IAB11-2',
  '66': 'IAB17-18',
  '67': 'IAB9-9',
  '68': 'IAB9-5',
  '69': 'IAB7-44',
  '71': 'IAB22-3',
  '73': 'IAB19-30',
  '74': 'IAB8-5',
  '78': 'IAB22-1',
  '85': 'IAB12-2',
  '86': 'IAB22-3',
  '87': 'IAB11-3',
  '112': 'IAB7-32',
  '113': 'IAB7-32',
  '114': 'IAB7-32',
  '115': 'IAB7-32',
  '118': 'IAB9-5',
  '119': 'IAB9-5',
  '120': 'IAB9-5',
  '121': 'IAB9-5',
  '122': 'IAB9-5',
  '123': 'IAB9-5',
  '124': 'IAB9-5',
  '125': 'IAB9-5',
  '126': 'IAB9-5',
  '127': 'IAB22-1',
  '132': 'IAB1-2',
  '133': 'IAB19-30',
  '137': 'IAB3-9',
  '138': 'IAB19-3',
  '140': 'IAB2-3',
  '141': 'IAB2-1',
  '142': 'IAB2-3',
  '143': 'IAB17-13',
  '166': 'IAB11-4',
  '175': 'IAB3-1',
  '176': 'IAB13-4',
  '182': 'IAB8-9',
  '183': 'IAB3-5'
};
