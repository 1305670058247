import { dom } from "../global";

const results = {};
/**
 * Converts a nested url query to usable js object
 *
 * {@link https://stackoverflow.com/a/43513777/1994935|SO Comment Source}
 *
 * @export
 * @param {string} query
 * @returns {object}
 */
export function urlQueryAsObject(query = dom().window.location.href) {
	let originalQuery = '';
	if (results[query]) {
		return results[query];
	} else {
		originalQuery = JSON.stringify(query);
	}

	query = query.substring(query.indexOf('?') + 1);
	if (query.indexOf('#') >= 0) {
		query = query.split('#')[0];
	}
	var re = /([^&=]+)=?([^&]*)/g;
	var decodeRE = /\+/g;

	var decode = function(str) {
		return decodeURIComponent(str.replace(decodeRE, ' '));
	};

	var params = {};
		var e;
	while ((e = re.exec(query))) {
		var k = decode(e[1]);
			var v = decode(e[2]);
		if (k.substring(k.length - 2) === '[]') {
			k = k.substring(0, k.length - 2);
			(params[k] || (params[k] = [])).push(v);
		} else params[k] = v;
	}

	var assign = function(obj, keyPath, value) {
		var lastKeyIndex = keyPath.length - 1;
		for (var i = 0; i < lastKeyIndex; ++i) {
			var key = keyPath[i];
			if (key === "_proto_" || key === "__proto__" || key === "constructor" || key === "prototype") continue; // skip assign if assigning to prototype
			if (!(key in obj)) obj[key] = {};
			obj = obj[key];
		}
		obj[keyPath[lastKeyIndex]] = value;
	};

	for (var prop in params) {
		var structure = prop.split('[');
		if (structure.length > 1) {
			var levels = [];
			structure.forEach(function(item, i) {
				var key = item.replace(/[?[\]\\ ]/g, '');
				levels.push(key);
			});
			assign(params, levels, params[prop]);
			delete params[prop];
		}
	}
	if (originalQuery !== '') {
		results[originalQuery] = params;
	}
	return params;
}
