import isEqual from 'lodash/isEqual';
import { dom } from '../global.js';
import { cloneDeep } from './cloneDeep.js';

import get from './helpers/get.js';

// eslint-disable-next-line func-names
const variableChecker = function (globalWindowPathOrFn) {
	let currentResult;
	let onChangeCallbacks = [];
	let cancelled = false;

	function varChanged() {
		for (let index = 0; index < onChangeCallbacks.length; index += 1) {
			const cb = onChangeCallbacks[index];
			cb(currentResult);
		}
	}
	function check() {
		if (cancelled) return;
		const checkResult = typeof globalWindowPathOrFn === 'string' ? get(dom().window, globalWindowPathOrFn) : globalWindowPathOrFn();
		if (checkResult !== 'undefined' && !isEqual(currentResult, checkResult)) {
			currentResult = cloneDeep(checkResult);
			varChanged();
		}
	}
	function onChange(cb, options = { skipInitialRun: false }) {
		if (currentResult && (!options || (typeof options === 'object' && !options.skipInitialRun))) {
			cb(currentResult);
		}
		onChangeCallbacks.push(cb);
		return () => {
			onChangeCallbacks = onChangeCallbacks.filter((v) => v === cb);
		};
	}
	function getResult() {
		return currentResult;
	}
	function cancel() {
		cancelled = true;
	}

	check();

	return {
		check,
		onChange,
		getResult,
		cancel,
	};
};

export default variableChecker;
