import { auction } from '../../bidbarrel.js';
import { runQueue } from '../../utilities/queue.js';
import { logger } from '../../utilities/logger.js';
import { moduleManager } from '../../moduleManager.js';
import omit from '../../utilities/helpers/omit.js';
import get from '../../utilities/helpers/get.js';
import { renderScript } from '../../utilities/renderScript.js';
import { dom } from '../../global.js';
import { setInitialPageTargeting } from '../../targeting.js';
import { gptModule } from './googletag.js';
import { getConfig, setConfig } from '../../config.js';
import { eventEmitter } from '../../events.js';
import isEmpty from '../../utilities/helpers/isEmpty.js';
import { cookieStorageEngine } from '../../services/storage/engines/cookie.js';
import { localStorageEngine } from '../../services/storage/engines/localStorage.js';
import { exposureApi } from '../../exposureApi.js';
import { errorReporting } from '../../services/errorReporting.js';
import { parseOptanonCookie, applyLibBootstrapCode, setConsentGiven, getConsentGiven, setConsentFired, getConsentFired, getConsentConfig, setConsentConfig } from './consentWorker.js';

import CONSTANTS from '../../constants.json';
// import { isStagingEnv } from '../../utilities/environment.js';

const otLogger = logger({ name: 'rvconsent', textColor: '#FFF', bgColor: '#550000' });
const { CONSENT } = CONSTANTS.MODULES;

let consentTimeoutId;
/**
 * Compliance module
 *
 * Ad Library integration with the compliance library
 *
 * @module rvconsent
 * @private
 */
// eslint-disable-next-line func-names
export const rvconsentBase = (function () {
	/**
	 * List of script ids to prevent from rendering prior to consent
	 *
	 * @memberof rvconsent
	 * @type {string[]}
	 * @private
	 */
	const consentGivenScriptIds = ['ix-identity', 'bb-sharethrough'];
	/**
	 * Run queue for when ads have been given the all-clear to process as per normal
	 *
	 * @memberof rvconsent
	 * @type {Object}
	 * @private
	 */
	const consentGivenQueue = runQueue('consentGiven');
	consentGivenQueue.push(() => otLogger.logInfo(`Targeting ConsentGiven queue run`));
	/**
	 * Run queue for when the non-personalized ads flag has been set
	 *
	 * @memberof rvconsent
	 * @type {Object}
	 * @private
	 */
	const npaFlagSetQueue = runQueue('npaFlagSet');
	npaFlagSetQueue.push(() => otLogger.logInfo(`NPAFlagSet queue run`));
	/**
	 * Queue cancellation item to ensure setInitialPageTargeting is not called too many times
	 *
	 * @memberof rvconsent
	 * @type {Object}
	 * @private
	 */
	let cancelSetInitPageTargetingNpaItem;

	/**
	 * Wrapper method for when the consent changes
   * used only by cbsioptanon
	 *
	 * @param {Function} callback function that accepts the consent given result when consent changes
	 * @memberof rvconsent
	 * @private
	 */
   function onConsentChange(callback) {
		if (typeof dom().window.cbsoptanon !== 'undefined') {
			dom().window.cbsoptanon.cmd.push((cmp) => {
				cmp.ot.addOnConsentChangedHandler(() => {
					cmp.ads.getNpaFlag((flag) => {
						// eslint-disable-next-line eqeqeq
						const hasConsent = flag == 0;
						callback(hasConsent);
					});
				});
			});
		}
	}

	/**
	 * Handles the consent signal given by optanon
	 *
	 * @param {Boolean} allowed flag indicated if consent is given(true) or not(false)
	 * @param {Boolean} fromOnChange Flag to prevent infinite recursion
	 * @memberof rvconsent
	 * @private
	 */
   function consentHandler(allowed, fromOnChange = false) {
		otLogger.logInfo(`Targeting Consent Given`, allowed);
		setConsentFired();
		const config = getConsentConfig();
		const consent = setConsentGiven(allowed);
		setConfig('consent', consent);

		if (config.type === 'cbsioptanon') {
			parseOptanonCookie();
		}
		if (consent) {
			if (moduleManager.gateways.isOpen('consentGiven') !== true) {
				moduleManager.gateways.open('consentGiven');
			}
			if (config.type === 'onetrust') {
				// eslint-disable-next-line func-names, no-undef
				dom().window.googletag.cmd.push(() => {
					// eslint-disable-next-line no-undef
					googletag.pubads().setRequestNonPersonalizedAds(0);
				});
				otLogger.logInfo(`NPA flag set to '0' - Personalized ads can be shown`);
			}
		} else {
			// eslint-disable-next-line func-names, no-undef
			dom().window.googletag.cmd.push(() => {
				// eslint-disable-next-line no-undef
        googletag.pubads().setRequestNonPersonalizedAds(1);
			});
			otLogger.logInfo(`NPA flag set to '1'`);
		}
		if (moduleManager.gateways.isOpen('npaFlagSet') !== true) {
			moduleManager.gateways.open('npaFlagSet');
		}
		if (config.type === 'onetrust') {
			eventEmitter.emit('consentReady', consent);
		} else if (!fromOnChange) {
			eventEmitter.emit('consentReady', consent);
			onConsentChange((consentSent) => {
				otLogger.logInfo(`Consent Changed`, consentSent);
				if (!consentSent) {
					consentHandler(consentSent, true);
				}
				eventEmitter.emit('consentChanged', consentSent);
			});
		}
	}

	/* *************************************
	 * START FUNCTIONS USED ONLY BY CBSIOPTANON
	 * **************************************** */
	/**
	 * Checks if Optanon is version 1.1 or later
	 *
	 * @param {Object} cmp CBS Optanon object
	 * @memberof rvconsent
	 * @private
	 */
   function isLatest(cmp) {
		const major = get(cmp, 'constants.VERSION.major');
		const minor = get(cmp, 'constants.VERSION.minor');
		return major && minor && major >= 1 && minor >= 1;
	}
	/**
	 * Applies the latest (more optimized) listener for the consent signal
	 *
	 * @memberof rvconsent
	 * @private
	 */
	function latestListener() {
		if (typeof dom().window.cbsoptanon !== 'undefined') {
			dom().window.cbsoptanon.cmd.push((cmp) => {
				cmp.ot.targetingAllowed((allowed) => {
					consentHandler(allowed);
				});
			});
		}
	}


	/**
	 * Applies the legacy listener for the consent signal
	 *
	 * @memberof rvconsent
	 * @private
	 */
	function legacyListener() {
		if (typeof dom().window.cbsoptanon !== 'undefined') {
			dom().window.cbsoptanon.onAdsReady((_localOptanon, adOptions) => {
				// eslint-disable-next-line eqeqeq
				consentHandler(adOptions.npaFlag == 0);
			});
		}
	}


	/**
	 * Applies configuration changes to cbsioptanon
	 *
	 * @param {Object} cfg object with partial config change
	 * @memberof rvconsent
	 * @private
	 */
	function applyConfig(cfg) {
		otLogger.logInfo(`applyConfig called`, cfg);
		if (cfg && typeof cfg === 'object' && !Array.isArray(cfg) && typeof dom().window.cbsoptanon !== 'undefined') {
			dom().window.cbsoptanon.config.push(cfg);
		}
	}
	/* *************************************
	 * END FUNCTIONS USED ONLY BY CBSIOPTANON
	 * **************************************** */

/**
	 * Function to setup global variables
	 *
	 * @memberof rvconsent
	 * @private
	 */
 function bootstrap() {
  // Per the optanon docs we need to ensure googletag is on page and available before using the ad functionality
  dom().window.googletag = dom().window.googletag || {};
  dom().window.googletag.cmd = dom().window.googletag.cmd || [];
  if (typeof dom().window.cbsoptanon !== 'undefined') {
    applyLibBootstrapCode();
  }
  moduleManager.gateways.register('consentGiven');
  moduleManager.gateways.register('npaFlagSet');
  moduleManager.gateways.onOpen(() => {
    // run npa queue
    npaFlagSetQueue.run(1);
  }, 'npaFlagSet');
  moduleManager.gateways.onOpen(() => {
    consentGivenQueue.run(getConsentGiven());
  }, 'consentGiven');
}

/**
 * Adds the needed hooks for this module
 *
 * @memberof rvconsent
 * @private
 */
function applyHooks() {
  otLogger.logInfo(`ApplyHooks called`);
  renderScript.before((next, scriptConfig) => {
    if (consentGivenScriptIds.indexOf(scriptConfig.id) >= 0 || get(scriptConfig, 'filter.requiresConsent', false)) {
      consentGivenQueue.push(() => next(scriptConfig));
    } else {
      next(scriptConfig);
    }
  }, 1);
  cookieStorageEngine.setCookie.before((next, ...args) => {
    if (getConsentGiven()) {
      next(...args);
    } else {
      // consentGivenQueue.push(() => setCookie(...args));
      next(null);
    }
  }, 1);
  cookieStorageEngine.raw.setValue.before((next, ...args) => {
    if (getConsentGiven()) {
      next(...args);
    } else {
      // consentGivenQueue.push(() => setCookie(...args));
      next(null);
    }
  }, 1);
  cookieStorageEngine.getCookie.before((next, ...args) => {
    if (getConsentGiven()) {
      next(...args);
    } else {
      next(null);
    }
  }, 1);
  cookieStorageEngine.raw.getValue.before((next, ...args) => {
    if (getConsentGiven()) {
      next(...args);
    } else {
      next(null);
    }
  }, 1);
  localStorageEngine.raw.setValue.before((next, ...args) => {
    if (getConsentGiven()) {
      next(...args);
    } else {
      consentGivenQueue.push(() => localStorageEngine.raw.setValue(...args));
      // next(null);
    }
  }, 1);
  localStorageEngine.raw.getValue.before((next, ...args) => {
    if (getConsentGiven()) {
      next(...args);
    } else {
      next(null);
    }
  }, 1);
  setInitialPageTargeting.before((next, pageTargeting, forceRun = false) => {
    if (!getConsentGiven()) {
      consentGivenQueue.push(() => {
        cancelSetInitPageTargetingNpaItem();
        next(pageTargeting, true);
      });
      cancelSetInitPageTargetingNpaItem = npaFlagSetQueue.push(() => next(pageTargeting, forceRun));
    } else {
      next(pageTargeting, forceRun);
    }
  }, 1);
  gptModule.enableGoogletagServices.before((next) => {
    npaFlagSetQueue.push(() => next());
  }, 1);
  auction.before((next, units) => {
    npaFlagSetQueue.push(() => next(units));
  }, 1);
}

	/**
	 * This method handles the primary actionable integration with cbsoptanon
	 *
	 * @memberof rvconsent
	 * @private
	 */
   function applyListener() {
		otLogger.logInfo(`applyListerner() fired`);
		if (typeof dom().window.cbsoptanon !== 'undefined') {
			dom().window.cbsoptanon.cmd.push((cmp) => {
				if (isLatest(cmp)) {
					latestListener();
				} else {
					legacyListener();
				}
			});
		}
	}


	/**
	 * Handles all "setup" phase functionality
	 *
	 * @memberof rvconsent
	 * @private
	 */
	function register() {
		otLogger.logInfo(`Register called. Consent is ${getConsentGiven()}`);
		setConfig('consent', false);
		bootstrap();
		applyHooks();
		if (typeof dom().window.cbsoptanon !== 'undefined') {
			applyListener();
		}
		getConfig('rvconsent', (newConfig) => {
			const config = setConsentConfig(newConfig);
			if (typeof dom().window.cbsoptanon !== 'undefined') {
				applyConfig(omit(config, ['type', 'scriptUrls', 'renderScript', 'timeout']));
			}
		});
	}

	/**
	 * Method fired by page for OptAnon when Consent has been loaded
	 *
	 * @memberof onetrust
	 * @public
	 */
   function consentLoaded() {
		clearTimeout(consentTimeoutId);
		// We only care about the targeting consent
		// that will be C0004 or 4
		const consent = typeof dom().window.OptanonActiveGroups === 'string' && (dom().window.OptanonActiveGroups.indexOf(',C0004,') !== -1 || dom().window.OptanonActiveGroups.indexOf(',4,') !== -1);
		eventEmitter.emit('consentChanged', consent);
		consentHandler(consent);
	}

  function consentTimeout() {
		otLogger.logInfo(`Consent timeout expired.  Consent down or not loaded in page.`);
		// log message to adlib analytics
		const errorObj = new Error(`Consent timeout expired. Consent down or not loaded in page.`);
		errorReporting.report(errorObj);
		consentLoaded();
	}

  /**
	 * Module initialization
	 *
	 * @private
	 * @memberof rvconsent
	 */
	function initialize() {
		otLogger.logInfo(`Initalize called`);
		let config = getConsentConfig();
		if (isEmpty(config)) {
			otLogger.logInfo(`Config empty, re-getting`);
			config = setConsentConfig(getConfig('rvconsent'));
			if (config.type === 'cbsioptanon') {
				applyConfig(omit(config, ['type', 'scriptUrls', 'renderScript', 'timeout']));
			} else {
				consentLoaded();
			}
		}

		if (getConsentFired() === false) {
			// wait X ms timeout then fire consentLoaded();
			consentTimeoutId = setTimeout(() => {
				consentTimeout();
			}, config.timeout);
		}
	}





	// intercepting the OptanonWrapper function from the page to add our call when Consent is loaded
	// OptanonWrapper is the function that will be fired by OneTrust/Optanon when it's loaded or
	// any time the consent state is updated
	// eslint-disable-next-line func-names
	(function () {
		// eslint-disable-next-line func-names
		let oldOptanonWrapper = function () {};
		if (typeof OptanonWrapper !== 'function') {
			// MLS: Convert the following to logError once all sites are off cbsioptanon and that is removed from this moduel
			otLogger.logInfo(
				`OptanonWrapper function not found.  Be sure to initialize the AdLibrary AFTER the consent manager and OptanonWrapper function are declared.\nDeclaring the OptanonWrapper function after the Ad Library can cause unexpected behavior.`
			);
			// eslint-disable-next-line func-names
			dom().window.OptanonWrapper = function () {};
		}
		// eslint-disable-next-line no-undef
		oldOptanonWrapper = OptanonWrapper;
		// eslint-disable-next-line no-undef, func-names
		OptanonWrapper = function () {
			consentLoaded();
			oldOptanonWrapper();
		};
	})();

	// Expose methods
	exposureApi.expose({
		consentLoaded,
	});
	return {
		consentLoaded,
		onConsentGiven: consentGivenQueue.push,
		onNpaFlagSet: npaFlagSetQueue.push,
		name: CONSENT,
		register,
		initialize,
	};
})();

export const rvconsent = moduleManager.register(rvconsentBase);
