export default function errorReplacer(key, value){
    if (value instanceof Error) {
      return {
        ...value,
        name: value.name,
        message: value.message,
        stack: value.stack,
      }
    }
    return value
  }