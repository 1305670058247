import { bbLogger } from '../../utilities/logger.js';
import { createReduceMerger } from '../../utilities/reduceMerger.js';
import { cloneDeep } from '../../utilities/cloneDeep.js';
import defaultsDeep from '../../utilities/helpers/defaultsDeep.js';
import { moduleManager } from '../../moduleManager.js';
import { getUnits } from '../../unitManager.js';
import { getConfig } from '../../config.js';
import { isMobile } from '../../bidbarrel.js';
import CONSTANTS from '../../constants.json';
import isEmpty from '../../utilities/helpers/isEmpty.js';
import { errorReporting } from '../../services/errorReporting.js';
import errorReplacer from '../../utilities/helpers/errorReplacer.js';
import { getPageTargeting } from '../../targeting.js';


// constants
const { DYNAMIC_BIDDERS } = CONSTANTS.MODULES;

/**
 * Dynamic Bidder Module
 *
 * Handles dynamically exclusion/inclusion/modifying bidders based on configured rulesets
 *
 * @module DynamicBidders
 * @private
 */
const dynamicBiddersModule = (() => {
	/**
	 * Property to track cacheing of DynamicBidder results on a per-unit basis
	 *
	 * @memberof DynamicBidders
	 * @private
	 */
	// const cache = {};
	/**
	 * Bidder context objects
	 *
	 * @memberof DynamicBidders
	 * @private
	 */
	let bidderContexts = {};
	/**
	 * General config for adapter
	 *
	 * @memberof DynamicBidders
	 * @private
	 */
	let config = {};
	function register() {
		getConfig('dynamicBidders', db => {
			config = db;
		});
	}
  function getIdentityContext(bidder){
		if(!bidderContexts[bidder]){
			bidderContexts[bidder] = {};
		}
		return {
			dfpPath: getConfig("dfpPathObj"),
			regionCode:() =>  getConfig("geo.regionCode") || "us",
			isMobile,
			bidderContext: bidderContexts[bidder],
			pageTargeting: getPageTargeting()
		}
	}
/**
	 * Evaluations dynamic bidder configurations to render an end result
	 *
	 * @param {object|Array<function|object>|function} configEval - a multi-type evaluation config that can be a merge object, a function that creates a merge object dynamically, or a mixed array of the two
	 * @param {BidBarrel~Unit} unit - the current unit being operated on
	 * @param {object} existingParams - existing/pre-configured bidder params
	 * @param {string} bidder - bidder code
	 * @memberof DynamicBidders
	 * @private
	 */
 function bidConfigEvaluation(configEval, unit, existingParams, bidder) {
  const rm = createReduceMerger(configEval);
  return rm.processObject(existingParams, {arguments: [unit, getIdentityContext(bidder)]});
}
/**
	 * Parses unit's bids and applies the appropriate dynamic parameters
	 *
	 * @param {any} bids - unit.bids value
	 * @param {string} code - unit.code value
	 * @returns {array|any} - returns an array of bid configs or the original bidders value
	 * @memberof DynamicBidders
	 * @private
	 *
	 */
 function parseBidders(bids, code) {
  // if (!cache[code]) {
  const result = [];
  for (let index = 0; index < bids.length; index+=1) {
    const bidConfig = cloneDeep(bids[index]);
    if (config[bidConfig.bidder]) {
      try {
        const evaluatedMerge = bidConfigEvaluation(config[bidConfig.bidder], getUnits()[code], bidConfig.params, bidConfig.bidder);
        if (evaluatedMerge) {
          const applyConfig = bidderConfig => {
            if(!bidderConfig) return;
            const res = {
              bidder: bidConfig.bidder,
              params: defaultsDeep({}, bidderConfig, bidConfig.params),
              userId: bidConfig.userId,
              userIdAsEids: bidConfig.userIdAsEids,
            }
            if(typeof res.params.included !== "undefined"){
              delete res.params.included;
            }
            if(!isEmpty(res.params)){
              result.push(res);
            }
          }
          if(Array.isArray(evaluatedMerge)){
            for (let ix = 0; ix < evaluatedMerge.length; ix+=1) {
              const val = evaluatedMerge[ix];
              applyConfig(val);
            }
          } else {
            applyConfig(evaluatedMerge);
          }
        } else {
          bbLogger.atVerbosity(3).logInfo('Excluding bidder', bidConfig.bidder, 'from', code, 'dynamic bidder evaluated to falsy');
        }
      } catch (err) {
        bbLogger.atVerbosity(1).logError('Issue evaluating dynamic bidder for ad unit', code, err);
        const errorObj = new Error(`Issue evaluating dynamic bidder for ad unit. ${JSON.stringify(err,errorReplacer)}`);
        errorObj.unit = code;
        errorReporting.report(errorObj);
      }
    } else {
      result.push(bidConfig);
    }
  }
  return result;
}
	/**
	 * Hook method for pbjs.requestBids
	 *
	 * @param {object} adUnits - the current auction ad units
	 * @returns {function} next function with modified values
	 * @memberof DynamicBidders
	 * @private
	 */
	function bidRequest(adUnits) {
		// eslint-disable-next-line no-param-reassign
		adUnits = adUnits.map(unit => {
			// eslint-disable-next-line no-param-reassign
			unit.bids = parseBidders(unit.bids, unit.code);
			return unit;
		})
		bbLogger.logInfo('Bidder Contexts:', JSON.parse(JSON.stringify(bidderContexts)));
		bidderContexts = {};
		bbLogger.atVerbosity(1).logInfo('Applied Dynamic Bidders', adUnits);
		return adUnits;
	}
	return {
		bidRequest,
		register,
		protected: true,
		name: DYNAMIC_BIDDERS
	};
})();

// Register module
export const dynamicBidders = moduleManager.register(dynamicBiddersModule, null, { gate: 'consentGiven' });
export default dynamicBidders;
