import { logger } from './utilities/logger.js';
import { urlQueryAsObject } from './utilities/queryParams.js';
import { hookedFn } from './utilities/hookedFunction.js';
import { exposureApi } from './exposureApi.js';
import { dom } from './global.js';
import errorReplacer from './utilities/helpers/errorReplacer.js';
import CONSTANTS from './constants.json';

const { ERROR_REPORT } = CONSTANTS.EVENTS;

const eeLogger = logger({ name: 'eventEmitter', bgColor: '#F5F1ED', textColor: '#252323' });

/**
 * An event emitter construct for BidBarrel internal event handling
 *
 * @module eventEmitter
 * @private
 */
export const eventEmitter = (function ee() {
	/**
	 * Query param object
	 *
	 * @memberof eventEmitter
	 * @type {Object}
	 * @private
	 */
	const query = urlQueryAsObject();
	/**
	 * Event registry
	 *
	 * @memberof eventEmitter
	 * @type {Object}
	 * @private
	 */
	const eventsRegistry = { once: {} };
	/**
   * Function to register event listeners on Bid Barrel
   *
   * {@link guides/events/#event-handling-in-bidbarrel|Read the guide for BidBarrel events}
   *
   * @param {String|String[]} eventName The event(s) to regester the callback for
   * @param {Function} callback The callback to call once the event is emited
   * @param {boolean} once Flag to register the event to only be called once
   * @public
   * @memberof eventEmitter
   * @exposed
   *
   * @example
   BidBarrel.on('leader-top.viewable', function(unit){
     // Do something when Leader Top's ad has rendered to the page
    })
   */
	function on(eventName, callback, once = false) {
		if (eventName === 'once') return;
		if (eventName.constructor === Array) {
			for (let index = 0; index < eventName.length; index += 1) {
				const eventNameSingle = eventName[index];
				on(eventNameSingle, callback, once);
			}
			return;
		}
		if (once) {
			if (!eventsRegistry.once[eventName]) {
				eventsRegistry.once[eventName] = [];
			}
			eventsRegistry.once[eventName].push(callback);
		} else {
			if (!eventsRegistry[eventName]) {
				eventsRegistry[eventName] = [];
			}
			eventsRegistry[eventName].push(callback);
		}
	}
	/**
	 * Function to fire an event off of Bid Barrel
	 *
	 * @param {String|String[]} eventName Event name(s) to emit
	 * @param {Object} data event data
	 * @private
	 * @type {Function}
	 * @method
	 * @memberof eventEmitter
	 */
	const emit = hookedFn('sync', (eventName, ...data) => {
		if (eventName.constructor === Array) {
			for (let index = 0; index < eventName.length; index += 1) {
				const eventNameSingle = eventName[index];
				// eslint-disable-next-line no-use-before-define
				emitSingleEvent.apply(this, [eventNameSingle, ...data]);
			}
		} else {
			// eslint-disable-next-line no-use-before-define
			emitSingleEvent.apply(this, [eventName, ...data]);
		}
	});

	function emitSingleEvent(eventName, ...data) {
		if (query.bb_dom_dispatch === 'true') {
			// eslint-disable-next-line no-undef
			dom().window.document.dispatchEvent(new CustomEvent(`__bb.${eventName}`, { detail: data }));
		}
		eeLogger.atVerbosity(5).logInfo('Emitting Event', eventName, 'with arguments', data);
		if ((!eventsRegistry[eventName] || !eventsRegistry[eventName].length) && (!eventsRegistry.once[eventName] || !eventsRegistry.once[eventName].length)) {
			return;
		}
		if (eventsRegistry[eventName]) {
			for (let index = 0; index < eventsRegistry[eventName].length; index += 1) {
				const callback = eventsRegistry[eventName][index];
				try {
					eeLogger.atVerbosity(4).logInfo(eventName, 'emitting callback', callback);
					callback(...data);
					// callback.apply(null, data);
				} catch (err) {
					eeLogger.atVerbosity(1).logError('Callback for event', eventName, 'with args', data, 'returned an error', err);
					const errorObj = new Error(`Callback for event ${eventName} with args ${data} returned an error. ${JSON.stringify(err, errorReplacer)}`);
					emit(ERROR_REPORT, errorObj);
				}
			}
		}
		if (eventsRegistry.once[eventName]) {
			for (let index = 0; index < eventsRegistry.once[eventName].length; index += 1) {
				const callback = eventsRegistry.once[eventName][index];
				try {
					eeLogger.atVerbosity(4).logInfo(eventName, 'emitting callback', callback);
					callback(...data);
					// callback.apply(null, data);
				} catch (err) {
					eeLogger.atVerbosity(1).logError('Run once callback for event', eventName, 'returned an error', err);
					const errorObj = new Error(`Run once callback for event ${eventName} returned an error. ${JSON.stringify(err, errorReplacer)}`);
					emit(ERROR_REPORT, errorObj);
				}
			}
			eventsRegistry.once[eventName] = [];
		}
	}

	const addEventListener = on;
	const subscribe = on;

	exposureApi.rootScope({
		on,
	});

	return { emit, on, addEventListener, subscribe };
})();
export default eventEmitter;
