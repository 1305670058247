import sortBy from "./sortBy";

export default function orderBy(arr, keys, directions = ['desc']){
    if(!Array.isArray(keys)){
        keys = [keys];
    }
    if(!Array.isArray(directions)){
        directions = [directions];
    }
    const _keys = keys.reverse();
    const _directions = directions.reverse();

    return _keys.reduce((result, key, index) => sortBy(result, key, _directions[index] || 'desc'), arr)
}