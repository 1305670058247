
function getStackTrace(){
    let stack = '';
    try {
        throw new Error('');
    } catch (err) {
        stack = err.stack + "" || '';
    }
    return stack;
}

module.exports = { getStackTrace };