import { bbLogger } from './logger';

export default function readOnly(object, property, value) {
	// if(typeof object[property] !== "undefined"){
	// 	bbLogger.logWarn("Cannot set read only property on object when property is already defined", object, property, value);
	// } else {
		Object.defineProperty(object, property, {
			value,
			writable: false
		});
	// }
}

export function readOnlyGetter(object, property, getter, setterMsg = `Cannot set read-only property ${property}`) {
	// if(!hasGetter(object, property)){
	// 	bbLogger.logWarn("Cannot set read only property on object when property is already defined", object, property);
	// } else {
		Object.defineProperty(object, property, {
			get: getter,
			set: () => {
				bbLogger.logError(setterMsg);
			}
		});
	// }
}

export function hasGetter(obj, property){
	const descriptor = Object.getOwnPropertyDescriptor(obj, property);
	return descriptor ? typeof descriptor.get !== "undefined" : false;
}