import { hookedFn } from "../../../utilities/hookedFunction";
import { dom } from "../../../global";
import { richObject } from '../../../utilities/richObject';
import { bbLogger } from "../../../utilities/logger";
import defaultsDeep from "../../../utilities/helpers/defaultsDeep";
// import LZString from 'lz-string';

const compressionEngine = {
    compress: val => val,
    decompress: val => val
}

/**
 * Local Storage Engine 
 * 
 * Faciliates usage of localStorage
 * 
 * @module localStorageEngine
 * @private
 */
export const localStorageEngine = (function(){
    /**
     * Underlying rich object to facilitate data updates
     * 
     * This allows the engine to still operate even when the underlying tech isn't available or enabled(consent)
     * 
     * @memberof localStorageEngine
     * @private
     */
    const data = richObject({});
    /**
     * Flag for tracking if we can use localStorage
     * 
     * @memberof localStorageEngine
     * @private
     */
    const canUseLs = typeof dom().window.localStorage !== "undefined";
    /**
     * Local var ref for localStorage
     * 
     * @memberof localStorageEngine
     * @private
     */
    const localStorage = dom().window.localStorage;
    /**
     * Hookable method for setting localStorage value
     * 
     * @memberof localStorageEngine
     * @private
     */
    const setLs = hookedFn('sync', _setLs);
    /**
     * Hookable method for setting raw LS
     * @memberof localStorageEngine
     * @private
     */
    const setRawLs = hookedFn('sync', _setRawLs);
    /**
     * Hookable method for getting localStorage value
     * 
     * @memberof localStorageEngine
     * @private
     */
    const getLs = hookedFn('sync', _getLs);
    /**
     * Hookable method for setting localStorage value
     * 
     * @memberof localStorageEngine
     * @private
     */
    const getRawLs = hookedFn('sync', _getRawLs);
    /**
     * Underlying method for setting the localStorage value
     * 
     * @param {object} value The value to set as the localStorage value
     * @memberof localStorageEngine
     * @private
     */
    function _setLs(value){
        if(canUseLs && value){
            const meta = data.getMeta();
            try {
                setRawLs('_BB.ls', compressionEngine.compress(JSON.stringify({value, meta})));
                // localStorage.setItem('_BB.ls', compressionEngine.compress(JSON.stringify({value, meta})));
            } catch (err) {
                bbLogger.logInfo("Unable to set value for localStorage", {value, meta});
            }
        }
    }
    /**
     * Underlying method for getting the localStorage value
     * 
     * @memberof localStorageEngine
     * @private
     */
    function _getLs(){
        if(canUseLs){
            const val = compressionEngine.decompress(getRawLs('_BB.ls'));
            if(val){
                const existingValue = JSON.parse(val);
                for (const topicKey in existingValue.value) {
                    if (existingValue.value.hasOwnProperty(topicKey)) {
                        const value = existingValue.value[topicKey];
                        const meta = existingValue.meta[topicKey];
                        data.setValue(topicKey, value, {id: "localStorageUpdate", ...meta});
                    }
                }
            }
        }
        return null;
    }
    /**
     * Underyling method to interact with localStorage for getting items
     *
     * @param {String} key localStorage key
     * @returns {null|undefined|string} localStorage value
     * @memberof localStorageEngine
     * @private
     */
    function _getRawLs(key){
        return canUseLs ? localStorage.getItem(key) : null;
    }
    /**
     * Underyling method to interact with localStorage for setting items
     *
     * @param {String} key localStorage key
     * @param {String} value localStorage value
     * @memberof localStorageEngine
     * @private
     */
    function _setRawLs(key, value){
        if(canUseLs && value) {
            localStorage.setItem(key, value);
        }
    }
    /**
     * Deletes a key from localStorage
     * 
     * @param {string} key key to delete from localStorage
     */
    function deleteRawLs(key){
        if(canUseLs){
            localStorage.removeItem(key);
        }
    }
    /**
     * Method to setup engine
     * 
     * @memberof localStorageEngine
     * @private
     */
    function setup(){
        pullValueFromLs();
        data.getValue("*", (value, setOpts) => {
            if(!setOpts || setOpts.id !== "localStorageUpdate") {
                setLs(value);
            }
        })
        listenForChanges()
    }
    /**
     * Extracts value (if exists) from localStorage
     * 
     * @memberof localStorageEngine
     * @private
     */
    function pullValueFromLs(){
        const existingValue = getLs();
        if(existingValue){
            data.setValue("*", existingValue.value, {id: "localStorageUpdate"});
            data.setMeta(existingValue.meta);
        }
    }
    /**
     * Listens for changes to our localStorage variable
     * 
     * @memberof localStorageEngine
     * @private
     */
    function listenForChanges(){
        dom().window.addEventListener('storage', (event) => {
            if(event.key === "_BB.ls"){
                pullValueFromLs();
            }
        })
    }
    /**
     * Extension of richObject.setValue
     * 
     * @param {String|Object} topic topic or full object value
     * @param {*} value value if topic
     * @param {object} setOptions richObject options
     * @memberof localStorageEngine
     * @private
     */
    function setValue(topic, value, setOptions){
        let modifiedOptions = setOptions;
        if(modifiedOptions){
            const optionsExtended = {expires:{id: `ls.${topic}`}};
            modifiedOptions = defaultsDeep(modifiedOptions, optionsExtended);
        }
        data.setValue(topic, value, modifiedOptions);
    }
    /**
     * Wrapper for underlying richObject.getValue
     * 
     * @param  {...any} args 
     * @memberof localStorageEngine
     * @private
     */
    function getValue(...args){
        return data.getValue(...args);
    }
    /**
     * deletes a key from the tracked localStorage object
     * 
     * @param {string} key 
     */
    function deleteKey(key){
        return data.deleteKey(key);
    }

    return {
        name: 'localStorage',
        async: false,
        setup,
        setValue,
        getValue,
        deleteKey,
        setLs,
        getLs,
        raw: {
            getValue: getRawLs,
            setValue: setRawLs,
            deleteKey: deleteRawLs
        }
    }
})()