
const distinctionRules = {
  seconds: {
      isMatch: (distinction) => ['s', 'sec', 'seconds', 'secs'].indexOf(distinction) >= 0,
      getMsAmount: amount => amount * 1000
  },
  minutes: {
      isMatch: (distinction) => ['m', 'min', 'minutes', 'mins'].indexOf(distinction) >= 0,
      getMsAmount: amount => amount * 60 * 1000
  },
  hours: {
      isMatch: (distinction) => ['h', 'hr', 'hours', 'hrs'].indexOf(distinction) >= 0,
      getMsAmount: amount => amount * 60 * 60 * 1000
  },
  days: {
      isMatch: (distinction) => ['d', 'day', 'days'].indexOf(distinction) >= 0,
      getMsAmount: amount => amount * 24 * 60 * 60 * 1000
  },
  weeks: {
      isMatch: (distinction) => ['wk', 'wks', 'w', 'week', 'weeks'].indexOf(distinction) >= 0,
      getMsAmount: amount => amount * 7 * 24 * 60 * 60 * 1000
  },
  months: {
      isMatch: (distinction) => ['M', 'mo', 'mos', 'Mo', 'Mos', 'month', 'months'].indexOf(distinction) >= 0,
      getMsAmount: amount => amount * 30 * 24 * 60 * 60 * 1000
  },
  years: {
      isMatch: (distinction) => ['y', 'yr', 'years', 'year', 'yrs'].indexOf(distinction) >= 0,
      getMsAmount: amount => amount * 12 * 30 * 24 * 60 * 60 * 1000
  },
  midnight: {
      isMatch: (distinction) => distinction === "midnight",
      absolute: true,
      getMsAmount: amount => getMidnight().getTime()
  },
  midnightEst: {
      isMatch: (distinction) => distinction === "midnightEst",
      absolute: true,
      getMsAmount: amount => getMidnight(-4).getTime()

  }
}
/**
 * Translates a string ("3 days 22 minutes") into number of ms offset from now.
 * Also works for "midnight" and "midnightEst".
 *
 * @private
 * @param value string
 * @returns Date
 * @type {Function}
 *
 */
function translateStringExpiration(value) {
  const amounts = value.match(/(\d+)/);
  const distinctions = value.match(/(\D+)/gm);
  let timestamp = new Date().getTime();
  let absDecrement = 0;
  for (let index = 0; index < distinctions.length; index += 1) {
      const distinction = distinctions[index].trim();
      const itIx = index - absDecrement;
      // eslint-disable-next-line no-restricted-syntax
      for (const key in distinctionRules) {
          if (Object.prototype.hasOwnProperty.call(distinctionRules, key)) {
              const rule = distinctionRules[key];
              if (rule.isMatch(distinction)) {
                  if(rule.absolute){
                      timestamp = rule.getMsAmount(amounts && amounts[itIx] ? parseInt(amounts[itIx], 10) : null);
                      absDecrement+=1;
                  } else {
                      timestamp += rule.getMsAmount(amounts && amounts[itIx] ? parseInt(amounts[itIx], 10) : null);
                  }
                  break;
              }
          }
      }
  }
  return timestamp;
}


/**
 * Gets the timestamp for the value.
 * If nothing is passed in, returns the timestamp for now.
 * If an initialized Date() is passed in this gets the timestamp for that Date().
 * If a number >= 1500000000000 is passed in, that amount of ms is used as the timestamp.
 * If any other number is passed in number is added to now and that is returned as the timestamp.
 * If a string is passed in the number of ms of the is calculated and it's added to now and returned as a timestamp.
 *
 * @param distinction null|int|string
 * @returns int
 * @type {Function}
 *
 */

export function getTimestamp(distinction) {
  if(typeof distinction.getTime !== "undefined"){
      return distinction.getTime();
  } else if(typeof distinction === "string"){
      return translateStringExpiration(distinction);
  } else if(typeof distinction === "number" && distinction >= 1500000000000){
      return distinction;
  } else if(typeof distinction === "number"){
      return new Date().getTime() + distinction;
  }
}
/**
 * Returns a Date object setup by distinction.  All returned dates are in UTC.
 *  - If it is not passed any parameter a new Date is returned initialzed to time now.
 *  - If it is a Date object then the same Date object is returned.
 *  - If it is a number >= 1500000000000 a new Date is initialized with that value and the Date is returned.
 *  - If it is any other number that number of ms is added to the Date now and returned.
 *  - If it is the string "midnight" a Date set to the server's midnight is returned.
 *  - If it is the string "midnightEst" a Date is set to midnightEst is returned.
 *  - If it is a string it's parsed into time parts and that number of ms is added to Date now and returned
 *
 * Example strings:
 *  "2 days"
 *  "1 month 2 days 15 minutes"
 *
 * @param {*} distinction default is 0
 */
export function getDate(distinction = 0) {
  if(typeof distinction.getTime !== "undefined"){
      return distinction;
  }
  return new Date(getTimestamp(distinction));
}
/**
 * Get the expiration object from the options.
 * @param {*} options
 * @returns Object which contains .at, .expiresTimestamp, .fromNowMs and .isExpired
 */
export function getExpiration(options) {
  const now = new Date().getTime();
  const result = options.expires || options || {};
  let expiresTimestamp = result.expiresTimestamp;
  if(!expiresTimestamp){
      expiresTimestamp = getTimestamp(options.at || options.expires || options);
      result.expiresTimestamp = expiresTimestamp;
  }
  result.fromNowMs = expiresTimestamp - now;
  result.isExpired = result.fromNowMs <= 0;
  return result;
}

/**
 * Get UTC hour offset for the server
 *
 * @returns int
 * @type {Function}
 *
 */
export function getTimezoneHourOffset() {
  const now = new Date();
  return now.getUTCHours() < now.getHours() ? now.getHours() - (now.getUTCHours() + 24) : now.getHours() - now.getUTCHours();
}
/**
 * The next midnight for timezonehouroffset expressed in UTC
 *
 * @param tzOffset int between -12 and 14
 * @returns Date
 * @type {Function}
 *
 */
export function getMidnight(tzOffset = getTimezoneHourOffset()){
  const midnight = new Date();
  if(tzOffset < 0){
      midnight.setUTCHours(Math.abs(tzOffset), 0, 0, 0);
  } else {
      midnight.setUTCHours(tzOffset, 0, 0, 0);
  }

  midnight.setDate(midnight.getDate() + 1);

  return midnight;
}

/**
 * Figures out if the Eastern Timezone will be in Daylight savings time on the date received.
 * @param Date dt default is now in server time
 * @returns boolean true if Eastern Time zone will be in Daylight savings on the date passed in
 */
export function isEDT(dt = new Date()) {
	// dt = dt || new Date(); // default to current date
	const month = dt.getUTCMonth(); // get month in UTC
	if (month > 2 && month < 10) return true; // in EDT if Apr to Oct
	if (month < 2 || month > 10) return false; // not in EDT if Jan,Feb,Dec
	const date = dt.getUTCDate(); // get day of month in UTC
	const day = dt.getUTCDay(); // get day of week in UTC
	// eslint-disable-next-line eqeqeq
	if (month == 10) {
		// November
		if (date > 7) return false; // after first week, not in EDT
		// eslint-disable-next-line eqeqeq
		if (day == 0) {
			// Sunday of first week, check time
			if (dt.getUTCHours() < 6) return true; // before 6am UTC, in EDT
			return false; // 6am UTC or later, not in EDT
		}
		if (date > day) return false; // Sunday has passed, not in EDT
		return true; // Sunday has not passed, in EDT
	}

	// March
	if (date < 8) return false; // still first week, not in EDT
	if (date > 14) return true; // after second week, in EDT
	// eslint-disable-next-line eqeqeq
	if (day == 0) {
		// Sunday of second week, check time
		if (dt.getUTCHours() > 6) return true; // 7am UTC or later, in EDT
		return false; // before 7am UTC, not in EDT
	}
	if (date - day > 7) return true; // Sunday has passed, in EDT
	return false; // Sunday has not passed, not in EDT
}

/**
 * The next midnight for Eastern time zone.
 *
 * @param {*} dt Date
 * @returns Date
 */
// export function getNextMidnightET(dt = new Date()) {
// 	let exp = getMidnight(-5, dt);
// 	const tomorrow = new Date(exp.getTime() + 1000 * 60 * 60 * 24); // add a day

// 	if (exp.getTime() < dt.getTime()) {
// 		if (isEDT(exp) && !isEDT(tomorrow)) {
// 			exp = new Date(exp.getTime() + 1000 * 60 * 60 * 25); // add a day
// 		} else if (!isEDT(exp) && isEDT(tomorrow)) {
// 			exp = new Date(exp.getTime() + 1000 * 60 * 60 * 23); // add a day
// 		} else {
// 			exp = tomorrow;
// 		}
// 	}
// 	return exp;
// }
