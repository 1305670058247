import {bbLogger} from './logger.js';

export function makeSafe(callback, logError = bbLogger.logError, doInstead = () => undefined){
    try {
        return callback()
    } catch(err){
        logError(err);
        return doInstead();
    }
}
export default makeSafe;
