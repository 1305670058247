import memoize from './helpers/memoize';
import { dom } from '../global';

function _parseUrl(url){
    if(!dom().window.document.createElement) return {};
    const parser = dom().window.document.createElement('a');
    parser.href = url;
    return parser;
}

const parseUrl = memoize(_parseUrl);

export default parseUrl;