import { moduleManager } from '../../moduleManager.js';
import { cloneDeep } from '../../utilities/cloneDeep.js';
import { features } from '../../features.js';
import { logger } from '../../utilities/logger.js';
import { postProcessUnit } from '../../unitManager.js';
import CONSTANTS from '../../constants.json';

const {
	MODULES: { BIDBARREL_FEATURES },
	QUERY_PARAMS: { BID_SUPPRESS },
} = CONSTANTS;
const feLogger = logger({ name: 'Feature Enforcement', bgColor: '#FFF', textColor: '#DA4167' });

const enforceFeaturesBase = (() => {
	function applyAdSuppression(next, unit) {
		if (!unit) next(unit);
		const modifiedUnit = unit;
    if(typeof modifiedUnit.sizes !== 'undefined') {
      modifiedUnit.sizes = modifiedUnit.sizes.filter((size) => {
        const sizeStr = typeof size === 'string' ? size : size.join('x');
        return !features.get([`sizeSuppress.${sizeStr}`, `sizeSuppress.${modifiedUnit.code}.${sizeStr}`]);
      });
    }
		if (!features.get([`adSuppress.${modifiedUnit.code}`, `adSuppress.all`])) {
			next(modifiedUnit);
		} else {
			feLogger.atVerbosity(2).logInfo('Suppressing Ad', modifiedUnit);
		}
	}
	function initialize() {
		postProcessUnit.before(applyAdSuppression);
	}
	function deregister() {
		postProcessUnit.getHooks({ hook: applyAdSuppression }).remove();
	}
	function bidRequest(unitCollection) {
		const resultUnits = [];
		for (let index = 0; index < unitCollection.length; index += 1) {
			const originalUnit = unitCollection[index];
			const unit = cloneDeep(originalUnit);
			unit.bids = [];
			for (let ix = 0; ix < originalUnit.bids.length; ix += 1) {
				const bidConfig = originalUnit.bids[ix];
				if (!features.get([`${BID_SUPPRESS}.${bidConfig.bidder}`, `${BID_SUPPRESS}.${unit.code}.${bidConfig.bidder}`, `${BID_SUPPRESS}.${unit.code}.all`, `${BID_SUPPRESS}.all`])) {
					unit.bids.push(bidConfig);
				} else {
					feLogger.atVerbosity(2).logInfo('Suppressing Bidder', bidConfig, originalUnit);
				}
			}
			resultUnits.push(unit);
		}
		return resultUnits;
	}
	// function handleForcedBids(prebidName, units, auction){
	// const fakeBids = [];
	// for (let index = 0; index < units.length; index++) {
	//     const unit = units[index];
	//     for (let ix = 0; ix < unit.bids.length; ix++) {
	//         const bidConfig = unit.bids[ix];
	//         if(features.get([`forceBids.${bidConfig.bidder}`, `forceBids.${unit.code}.${bidConfig.bidder}`])){
	//             let fakeBid = makeFakeBid(unit, bidConfig);
	//             fakeBid.auctionId = auction.getAuctionId();
	//             feLogger.atVerbosity(2).logInfo("Making fake bid", fakeBid);
	//             fakeBids.push(fakeBid);
	//         }
	//     }
	// }
	// auction.addBidReceived(fakeBids);
	// }
	// function getRandomSize(sizes){
	//     let result = sizes[Math.floor(Math.random()*sizes.length)];
	//     if(result[0] <= 50){ return [320,50] };
	//     return result;
	// }
	// function getAdServerTargeting(bidData){
	//     return {
	//         hb_bidder: bidData.bidder,
	//         hb_adid: bidData.adId,
	//         hb_format: bidData.mediaType,
	//         hb_pb: bidData.cpm.toString(),
	//         hb_size: bidData.getSize(),
	//         hb_source: bidData.source
	//     }
	// }
	// function makeFakeBid(unit, bidConfig){
	//     const randomSize = getRandomSize(unit.mappedSizes || unit.sizes);
	//     let bidData = {
	//         bidderCode: bidConfig.bidder,
	//         width: randomSize[0],
	//         height: randomSize[1],
	//         statusMessage: "Bid available",
	//         adId: generateUuid(),
	//         creativeId: "fake-creative",
	//         currency: 'USD',
	//         mediaType: "banner",
	//         netRevenue: true,
	//         requestId: "none",
	//         requestTimestamp: Date.now(),
	//         responseTimestamp: Date.now(),
	//         source: "client",
	//         timeToRespond: 5,
	//         getStatusCode: () => 1,
	//         getSize: () => randomSize.join("x"),
	//         ad: `<img src="https://fakeimg.pl/${randomSize.join("x")}/6B5CA5/FFFFFF/?text=BidBarrel+fake+bid&font=noto" />`,
	//         bidder: bidConfig.bidder,
	//         adUnitCode: unit.code,
	//         cpm: 30,
	//         ttl: 300,
	//         isFake: true,
	//     }
	//     bidData.adserverTargeting = getAdServerTargeting(bidData);
	//     return bidData;
	// }
	// eventEmitter.on(`${PREBID_HEADER_BIDDING}.${HEADER_BIDDING_REQUEST}`, handleForcedBids);
	return {
		bidRequest,
		initialize,
		deregister,
		name: BIDBARREL_FEATURES,
	};
})();

export const enforceFeatures = moduleManager.register(enforceFeaturesBase);
export default enforceFeatures;
