import { getConfig } from "../../../config.js";
/* eslint-disable no-use-before-define */
import CONSTANTS from '../../../constants.json';
import { dom } from "../../../global.js";
import { moduleManager } from "../../../moduleManager.js";
import get from '../../../utilities/helpers/get.js';
import { bbLogger } from "../../../utilities/logger.js";

const { VISUALLY_READY, INTERACTIVE, FIRST_INTERACTION, FIRST_PAINT, FIRST_CONTENTFUL_PAINT, PAGE_LOAD, FIRST_BYTE } = CONSTANTS.ANALYTICS_RECORD_DEFAULTS;
const { BIDBARREL_ANALYTICS, ANALYTICS_TIMINGS_BOOMR, ANALYTICS_TIMINGS } = CONSTANTS.MODULES;

// Mappings for BOOMR.plugins.Continuity.metrics

const METRIC_MAPPINGS = {
    timeToFirstInteraction: {
        name: FIRST_INTERACTION.NAME,
        slug: FIRST_INTERACTION.SLUG,
    },
    timeToInteractive: {
        name: INTERACTIVE.NAME,
        slug: INTERACTIVE.SLUG,
    },
    timeToVisuallyReady: {
        name: VISUALLY_READY.NAME,
        slug: VISUALLY_READY.SLUG,
    }
}

/**
 * Mappings for BOOMR.plugins.PaintTiming.getTimingFor
 * @ignore
 */
const PAINT_MAPPINGS = {
    [FIRST_PAINT.SLUG]: {
        metricName: 'first-paint',
        name: FIRST_PAINT.NAME,
        slug: FIRST_PAINT.SLUG,
    },
    [FIRST_PAINT.SLUG]: {
        metricName: 'first-contentful-paint',
        name: FIRST_CONTENTFUL_PAINT.NAME,
        slug: FIRST_CONTENTFUL_PAINT.SLUG,
    }
}
/**
 * Timings handler for BOOMR(boomerangjs)
 *
 * This module interfaces with on page library BOOMR to acquire performance data
 *
 * @module timingsBoomr
 * @private
 */
const timingsBoomrHandler = (function tbh(){
    /**
     * Config for module
     *
     * @memberof timingsBoomr
     * @private
     */
    let config = {timeout: 60000};
    /**
     * Timeout for handling follow up if boomerang event fails to capture all timings
     *
     * @memberof timingsBoomr
     * @private
     */
    let boomrTimeout;
    /**
     * Registry for tracking timings recorded
     *
     * @memberof timingsBoomr
     * @private
     */
    const reported = {
        [VISUALLY_READY.SLUG]: false,
        [INTERACTIVE.SLUG]: false,
        [FIRST_INTERACTION.SLUG]: false,
        [FIRST_PAINT.SLUG]: false,
        [FIRST_CONTENTFUL_PAINT.SLUG]: false,
        [PAGE_LOAD.SLUG]: false,
        [FIRST_BYTE.SLUG]: false
    }
    /**
     * Report method
     *
     * @param {Object} record Analytics record to record
     * @memberof timingsBoomr
     * @private
     */
    function report(record){
      if(typeof record.amount !== "undefined" && !reported[record.slug]){
          reported[record.slug] = true;
          moduleManager.viaModule(ANALYTICS_TIMINGS, ({ addTimingRecord }) => {
            addTimingRecord({
                type: 'timing',
                timingType: 'mark',
                ...record
            });
          })
      }
  }
    /**
     * Captures all timings from BOOMR object
     *
     * @memberof timingsBoomr
     * @private
     */
    function reportAllTimings(){
        if(Object.values(reported).indexOf(false) === -1) {
            if(boomrTimeout){
                clearTimeout(boomrTimeout);
            }
            return
        };
        const {BOOMR} = dom().window;
        if(!BOOMR) return;

        const continuity = get(BOOMR, 'plugins.Continuity');
        if(continuity && continuity.is_complete()){
            const {metrics} = continuity;
            Object.keys(METRIC_MAPPINGS).forEach(metricName => {
                if (Object.prototype.hasOwnProperty.call(METRIC_MAPPINGS, metricName) && get(metrics, metricName)) {
                    const recordData = METRIC_MAPPINGS[metricName];
                    recordData.amount = metrics[metricName]();
                    report(recordData);
                }
            });
        } else {
            bbLogger.logInfo("Contintuity not complete")
        }

        if(BOOMR.getPerformance){
            const perfTimings = get(BOOMR.getPerformance(), 'timing');
            report({
                timingType: 'measure',
                name: FIRST_BYTE.NAME,
                slug: FIRST_BYTE.SLUG,
                amount: perfTimings.responseStart - perfTimings.requestStart
            });
            report({
                name: PAGE_LOAD.NAME,
                slug: PAGE_LOAD.SLUG,
                amount: perfTimings.domContentLoadedEventEnd - perfTimings.domainLookupEnd
            });
        }

        const paintTiming = get(BOOMR, 'plugins.PaintTiming');
        if(paintTiming && paintTiming.is_complete()){
            Object.keys(PAINT_MAPPINGS).forEach(slug => {
                const {metricName, ...recordData} = PAINT_MAPPINGS[slug];
                recordData.amount = paintTiming.getTimingFor(metricName)
                report(recordData);
            });
        }
        if(Object.values(reported).indexOf(false) !== -1 && (dom().window.performance.now() - startTime) < config.timeout ){
            if(!startTime){
                startTime = dom().window.performance.now();
            }
            boomrTimeout = setTimeout(() => {
                reportAllTimings()
            }, 500)
        }
    }

    /**
     * Sets up the event listener for boomerang
     *
     * @memberof timingsBoomr
     * @private
     */
    function setupListener(){
        dom().window.document.addEventListener("onBeforeBoomerangBeacon", () => {
            reportAllTimings();
        }, {once: true})
    }
    /**
     * The start time for the recsurive look up loop
     *
     * @memberof timingsBoomr
     * @private
     */
    let startTime;
    /**
     * Registration function for module
     *
     * @memberof timingsBoomr
     * @private
     */
    function register(){
        getConfig("analyticsTimings", cfg => {
            config = cfg;
        })
        if(get(dom().window, "BOOMR")){
            const isComplete = get(dom().window, "BOOMR.plugins.Continuity.is_complete");
            if(isComplete && isComplete()){
                reportAllTimings();
            } else {
                setupListener();
            }
        } else {
            setupListener();
        }
    }

    return {
        name: ANALYTICS_TIMINGS_BOOMR,
        register,
    }

})()
// Register module
export const timingsBoomr = moduleManager.register(timingsBoomrHandler, [BIDBARREL_ANALYTICS, ANALYTICS_TIMINGS]);
export default timingsBoomr;
