import uniq from './helpers/uniq';

const _cache = {};

/**
 * Converts a string into a set of boolean flags. This function also caches the results
 *
 * @export
 * @param {String} flags the string to parse
 * @param {boolean} [implicit=true] whether to implicitly set all other evaluations to true or false
 * @returns {object} a stringFlags object
 * @example
 * ```javascript
 * console.log(stringFlags("!top,bottom").results)
 * // { top: false, bottom: true }
 * console.log(stringFlags("!top,bottom,explicit").isEnabled("middle"))
 * // false
 * console.log(stringFlags("!top,bottom,!explicit").isEnabled("middle"))
 * // true
 * console.log(stringFlags("!top,bottom,!implicit").isEnabled("middle"))
 * // false
 * console.log(stringFlags("!top,bottom,implicit").isEnabled("middle"))
 * // true
 * console.log(stringFlags("!top,bottom,all").isEnabled("middle"))
 * // true
 * console.log(stringFlags("!top,bottom,!all").isEnabled("middle"))
 * // false
 * ```
 */
export function stringFlags(flags, implicit = true) {
	if (flags.constructor === Array) {
		flags = uniq(flags).join(',');
	}
	if (_cache[flags]) return _cache[flags];

	const specified = uniq(flags.split(',')).reduce(makeObj, {});
	const results = {};

	for (const key in specified) {
		if (specified.hasOwnProperty(key)) {
			if (key.charAt(0) === '!') {
				if (key.indexOf('explicit') >= 0) {
					results.all = true;
				} else if (key.indexOf('implicit') >= 0) {
					results.all = false;
				}
				results[key.replace('!', '')] = false;
			} else {
				if (key.indexOf('explicit') >= 0) {
					results.all = false;
				} else if (key.indexOf('implicit') >= 0) {
					results.all = true;
				}
				results[key] = true;
			}
		}
	}

	_cache[flags] = {
		string: flags,
		specified: specified,
		results: results,
		isEnabled: val => isFlagEnabled(val, results, implicit)
	};

	return _cache[flags];
}

function makeObj(result, currentVal) {
	result[currentVal] = true;
	return result;
}
function isFlagEnabled(val, results, implicit = true) {
	if (typeof results[val] !== 'undefined') return results[val];
	if (typeof results.implicit !== 'undefined') return results.implicit;
	if (typeof results.explicit !== 'undefined') return !results.explicit;
	if (typeof results.all !== 'undefined') return results.all;
	return implicit;
}
