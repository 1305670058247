import isMatch from 'lodash/isMatch';
// eslint-disable-next-line import/no-relative-packages
import { EVENTS } from '../../../prebid/src/constants.js';
// eslint-disable-next-line import/no-relative-packages
import { getGlobal } from '../../../prebid/src/prebidGlobal.js';
import CONSTANTS from '../../constants.json';
import { eventEmitter } from '../../events.js';
import { exposureApi } from '../../exposureApi.js';
import { moduleManager } from '../../moduleManager.js';
import { bidCache } from '../../services/bidCache.js';
import { errorReporting } from '../../services/errorReporting.js';
import { getUnits, getUnitCodes } from '../../unitManager.js';
import errorReplacer from '../../utilities/helpers/errorReplacer.js';
import memoize from '../../utilities/helpers/memoize.js';
import orderBy from '../../utilities/helpers/orderBy.js';
import { hookedFn } from '../../utilities/hookedFunction.js';
import { logger } from '../../utilities/logger.js';

const { BIDCACHING } = CONSTANTS.MODULES;
const bcLogger = logger({ name: 'bidcaching', textColor: '#FFF', bgColor: '#00aa00' });
/**
 * BidCaching
 *
 * This module adds the BidCaching for header bidding
 *
 * @module BidCaching
 * @private
 */

// Prebid global
const $$PREBID_GLOBAL$$ = getGlobal();
const { BID_WON } = EVENTS;

const { AD_VIEWABLE, BAD_BID, BID_USED, BID_RECYCLED } = CONSTANTS.EVENTS;

/**
 * Handles bid caching logic
 *
 * @private
 */
// eslint-disable-next-line
export var bidCache2 = (function () {
	/**
	 * Where all existing bids are stored regardless of caching
	 *
	 * @memberof bidCache
	 * @type {Object}
	 * @private
	 */
	const allBids = {};
	/**
	 * Where all existing cached bids are stored
	 *
	 * @memberof bidCache
	 * @type {Object}
	 * @private
	 */
	const cachedBids = {};
	/**
	 * Object registry for storing bid ad ids such that they can resolve
	 * faster when determining if they should go into the cachedBids array
	 *
	 * @memberof bidCache
	 * @type {Object}
	 * @private
	 */
	const pushRestrictionReference = {};
	/**
	 * Object registry for storing winning/bad bids such that they can
	 * resolve faster on runtime
	 *
	 * @type {Object}
	 * @memberof bidCache
	 * @private
	 */
	const winRestrictionReference = {};
	/**
	 * List of winning bid objects
	 *
	 * @memberof bidCache
	 * @private
	 * @type {BidBarrel~Bid[]}
	 */
	const winningBids = [];
	/**
	 * Collection of bad ads intercepted by ad quality vendor
	 *
	 * @memberof bidCache
	 * @private
	 * @type {BidBarrel~Bid[]}
	 */
	const badBids = [];
	/**
	 * Registry for tracking pending bid requests
	 *
	 * @memberof bidCache
	 * @private
	 * @type {Object}
	 */
	const pendingRegistry = {};
	/**
	 * Bootstraps service
	 *
	 * @memberof bidCache
	 * @private
	 */

	/**
	 * Checks to see if the bid has already won on page or is a bad ad
	 *
	 * @param {BidBarrel~Bid} bidObj
	 * @returns {boolean}
	 * @memberof bidCache
	 * @private
	 */
	function isExcluded(bidObj) {
		return Boolean(winRestrictionReference[bidObj.adId]);
	}
	function getUnitByCode(unitCode) {
		let code = unitCode;
		let index;
		let unit;
		if (code.indexOf('--') >= 0) {
			const [parsedCode, indexStr] = unitCode.split('--');
			code = parsedCode;
			index = parseInt(indexStr, 10);
			unit = getUnits()[code];
			unit.video = unit.getVideoSpec(index);
		} else {
			unit = getUnits()[code];
		}
		if (typeof index !== 'undefined' && unit) {
			unit.codeIndex = index;
		}
		return unit;
	}
	/**
	 * Checks to see if a bid matches a size of the given ad unit
	 *
	 * @param {string} size
	 * @param {string} adUnitCode
	 * @returns {boolean}
	 * @method
	 * @type {Function}
	 * @memberof bidCache
	 * @private
	 */
	const isMatchingSize = memoize(
		(size, adUnitCode) => {
			const unit = getUnitByCode(adUnitCode);
			if (unit) {
				if (unit.isVideo || unit.allowedTypes.video) return true;
				if (adUnitCode.constructor === Array) {
					const result = adUnitCode.map((code) => isMatchingSize(size, code));
					return result.indexOf(true) >= 0;
				}
				const sizes = unit.getSizes().map((s) => (typeof s === 'string' || typeof s === 'number' ? s : s.join('x')));
				return sizes.indexOf(size) >= 0;
			}
			return false;
		},
		(size, adUnitCode) => `${JSON.stringify(size)}|${adUnitCode}`,
	);
	/**
	 * Checks to see if the bid is currently in a pending state with a previous DFP ad call
	 *
	 * @param {string} id
	 * @returns {boolean}
	 * @memberof bidCache
	 * @private
	 */
	function isPendingBid(id) {
		return !!cachedBids[id] && cachedBids[id].pending;
	}

	/**
	 * Determines if the ad unit can pull from its own pool of bids or all matching bids
	 *
	 * @param {BidBarrel~Bid} bid
	 * @param {string} adUnitCode
	 * @returns {boolean}
	 * @memberof bidCache
	 * @type {Function}
	 * @method
	 * @private
	 */
	const canPullFromCache = memoize(
		(bid, adUnitCode) => {
			const adUnit = getUnitByCode(adUnitCode);
			return (adUnit.cache || adUnit.lazyLoad) && bid.bidderCode !== 'medianet' ? true : bid.adUnitCode === adUnitCode;
		},
		(bid, adUnitCode) => `${bid.adId}|${adUnitCode}`,
	);

	/**
	 * Determines if the bid and ad unit match via mediaType
	 *
	 * @param {BidBarrel~Bid} bid
	 * @param {string} adUnitCode
	 * @returns {boolean}
	 * @memberof bidCache
	 * @type {Function}
	 * @method
	 * @private
	 */
	const matchesVideo = memoize(
		(bid, adUnitCode, evalOptions) => {
			const unit = getUnitByCode(adUnitCode);
			let result;
			if (typeof evalOptions.match === 'object') {
				const bidUnit = getUnitByCode(bid.adUnitCode);
				result = isMatch(bidUnit, evalOptions.match);
			} else {
				result = !unit.isVideo || !unit.allowedTypes.video || ((unit.isVideo || unit.allowedTypes.video) && bid.mediaType === 'video');
			}
			return result;
		},
		(bid, adUnitCode) => `${bid.adId}|${adUnitCode}`,
	);
	/**
	 * Determines if the bid object's mediaType exists in the unit's allowedTypes
	 *
	 * @param {PrebidBid} bidObj prebid bid object
	 * @param {String} adUnitCode Ad unit code
	 * @returns {boolean} whether the bid is compatible with the ad unit
	 * @memberof bidCache
	 * @private
	 */
	function matchesMedia(bidObj, adUnitCode, evalOptions) {
		try {
			const unit = getUnitByCode(adUnitCode);
			if (bidObj.mediaType && bidObj.mediaType === 'native' && unit.allowedTypes.native) {
				return true;
			}
			if (bidObj.mediaType && bidObj.mediaType === 'banner' && unit.allowedTypes.banner) {
				return isMatchingSize(bidObj.size, adUnitCode);
			}
			if (bidObj.mediaType && bidObj.mediaType === 'video' && (unit.allowedTypes.video || unit.isVideo)) {
				return matchesVideo(bidObj, adUnitCode, evalOptions);
			}
			return false;
		} catch (err) {
			bcLogger.logError(err);
			return false;
		}
	}
	/**
	 * Checks to see if the current time has passed the bids TTL
	 *
	 * @param {number} expiration
	 * @returns {boolean}
	 * @memberof bidCache
	 * @private
	 */
	function isExpired(expiration) {
		return expiration < Date.now();
	}
	/**
	 * Evaluates the kargo bidder specifically and removes their bid from consideration where the bid is of size 320x50 and did not originally come in for that unit
	 *
	 * @param {PrebidBid} bidObj prebid bid object
	 * @param {String} adUnitCode Ad unit code
	 * @returns {boolean} whether the bid is compatible with the ad unit
	 * @memberof bidCache
	 * @private
	 */
	function kargoMatches(bidObj, adUnitCode) {
		try {
			if (bidObj.bidderCode === 'kargo' && `${bidObj.width}x${bidObj.height}` === '320x50') {
				return bidObj.originalCode ? bidObj.originalCode === adUnitCode : bidObj.adUnitCode === adUnitCode;
			}
		} catch (err) {
			bcLogger.logError(err);
			const errorObj = new Error(JSON.stringify(err, errorReplacer));
			errorReporting.report(errorObj);
			return false;
		}
		return true;
	}
	/**
	 * Filter function to determine if a bid is compatible with a specific ad unit object
	 *
	 * @param {PrebidBid} bidObj prebid bid object
	 * @param {String} adUnitCode ad unit code
	 * @param {object} evalOptions options object to adjust how evaluation occurs
	 * @returns {boolean} whether the bid is compatible with the ad unit
	 * @memberof bidCache
	 * @private
	 */
	function isCompatible(bidObj, adUnitCode, evalOptions) {
		const result =
			(evalOptions.forTargeting ? !isPendingBid(bidObj.adId) : isPendingBid(bidObj.adId)) &&
			canPullFromCache(bidObj, adUnitCode) &&
			matchesMedia(bidObj, adUnitCode, evalOptions) &&
			kargoMatches(bidObj, adUnitCode);
		// bcLogger.logInfo("isCompatible", result, [
		// 	(evalOptions.forTargeting ? !isPendingBid(bidObj.adId) : isPendingBid(bidObj.adId) )
		// 	,canPullFromCache(bidObj, adUnitCode)
		// 	,isMatchingSize(bidObj.size, adUnitCode)
		// 	,kargoMatches(bidObj, adUnitCode)
		// 	,matchesMedia(bidObj, adUnitCode, evalOptions)
		// ]);
		return result;
	}

	/**
	 * Handles filtering out invalid bids for a specific ad unit
	 *
	 * @param {BidBarrel~Bid} bidObj
	 * @param {string} adUnitCode
	 * @returns {boolean}
	 * @type {Function}
	 * @method
	 * @memberof bidCache
	 * @exposed
	 */
	// eslint-disable-next-line arrow-body-style
	const bidFilter = hookedFn('sync', (bidObj, evaluationOptions = { forTargeting: false }) => {
		// console.log(adUnitCode, {
		//   matchingSize: isMatchingSize(bidObj.size, adUnitCode),
		//   "!isPending": !isPendingBid(bidObj.adId),
		//   canPullFromCache: canPullFromCache(bidObj, adUnitCode),
		//   "!isExpired": !isExpired(bidObj.expireTime)
		// }, bidObj)

		return (
			!isExcluded(bidObj) &&
			// && (evaluationOptions.match ? isMatch(bidObj, evaluationOptions.match) : isMatch(bidObj, {mediaType: 'banner'}))
			(evaluationOptions.forTargeting ? !isPendingBid(bidObj.adId) : isPendingBid(bidObj.adId)) &&
			!isExpired(bidObj.expireTime)
		);
	});
	/**
	 * Filters all ad units that are not currently registered with Bid Barrel
	 *
	 * @param {String[]} units
	 * @returns {array}
	 * @memberof bidCache
	 * @private
	 */
	function filterAdUnits(units) {
		// window.BidBarrel.unitRegistry = pbjs.adUnits
		//   .filter(u => !window.BidBarrel.unitRegistry[u.code])
		//   .reduce((res,val) => {
		//     res[val.code] = val;
		//     return res;
		//   }, window.BidBarrel.unitRegistry);
		return units.filter((u) => typeof getUnitByCode(u) !== 'undefined');
	}
	/**
	 * Pushes all recieved bids onto the cached bids stack
	 *
	 * @param {PrebidBid[]} bidsReceived
	 * @memberof bidCache
	 * @private
	 */
	function pushBids(bidsReceived) {
		const date = Date.now();
		const arrLength = bidsReceived.length;
		for (let i = 0; i < arrLength; i += 1) {
			const ttl = bidsReceived[i].ttl > 0 ? bidsReceived[i].ttl : 300;
			if (!pushRestrictionReference[bidsReceived[i].adId] && bidsReceived[i].cpm > 0) {
				// eslint-disable-next-line no-param-reassign
				bidsReceived[i].expireTime = date + ttl * 1000;
				bcLogger.atVerbosity(5).logMessage('Caching Bid', bidsReceived[i]);
				cachedBids[bidsReceived[i].adId] = bidsReceived[i];
				allBids[bidsReceived[i].adId] = bidsReceived[i];
				// cachedBids.push(bidsReceived[i]);
				pushRestrictionReference[bidsReceived[i].adId] = true;
			} else if (bidsReceived <= 0) {
				pushRestrictionReference[bidsReceived[i].adId] = true;
			}
		}
		// const pushAds = cachedBids.length > 100 ? cachedBids.slice(0, 100) : cacheAds;
		// localStorage.setItem('localBids', JSON.stringify(pushAds));
	}
	/**
	 * Sets a bid to a pending state
	 *
	 * @param {BidBarrel~Bid} bidObj
	 * @param {String} adUnitCode
	 * @returns {void}
	 * @memberof bidCache
	 * @private
	 */
	function makePending(bidObj, adUnitCode) {
		if (bidObj.adId === undefined) {
			return;
		}
		if (!cachedBids[bidObj.adId].originalCode) {
			cachedBids[bidObj.adId].originalCode = cachedBids[bidObj.adId].adUnitCode;
		}
		pendingRegistry[bidObj.adUnitCode] = bidObj.adId;
		cachedBids[bidObj.adId].adUnitCode = adUnitCode;
		cachedBids[bidObj.adId].pendingFor = bidObj.adUnitCode;
		cachedBids[bidObj.adId].pendingTimestamp = Date.now();
		cachedBids[bidObj.adId].pending = true;
	}
	/**
	 * Applies the unit priority order to the current unit code set
	 *
	 * @param {String[]} unitCodes Unit codes passed from prebid
	 * @returns {String[]} Codes sorted by priority order
	 * @type {Function}
	 * @method
	 * @memberof bidCache
	 * @private
	 */
	const applyPriorityOrder = hookedFn('sync', (unitCodes) => unitCodes);
	/**
	 * Method for handling detection of bad bids.
	 *
	 * @param {String} adId The prebid bid ad id
	 * @memberof bidCache
	 * @private
	 */
	function registerBadBid(adId) {
		if (adId) {
			for (let i = 0; i < winningBids.length; i += 1) {
				const bid = winningBids[i];
				if (bid.adId === adId) {
					badBids.push(bid);
					winRestrictionReference[bid.adId] = true;
					bcLogger.atVerbosity(3).logInfo('Bad bid ad render attempted and intercepted', bid);
					break;
				}
			}
		}
	}
	/**
	 * Removes pending state from a bid
	 *
	 * @param {string} code
	 * @returns {void}
	 * @memberof bidCache
	 * @private
	 */
	function removePending(code) {
		if (code === undefined) {
			return;
		}
		if (!!pendingRegistry[code] && cachedBids[pendingRegistry[code]]) {
			const id = cachedBids[pendingRegistry[code]].adId;
			if (winRestrictionReference[id]) {
				return;
			}
			bcLogger.atVerbosity(1).logMessage('Recycling Bid', cachedBids[id]);
			eventEmitter.emit(BID_RECYCLED, cachedBids[id]);
			cachedBids[id].pending = false;
			cachedBids[id].pendingFor = null;
			delete pendingRegistry[code];
		}
	}
	/**
	 * Marks a bid as winning
	 *
	 * @param {string} id adId of the bid
	 * @returns {void}
	 * @memberof bidCache
	 * @private
	 */
	function markWinner(id) {
		if (cachedBids[id]) {
			bcLogger.atVerbosity(1).logMessage('Used Bid', cachedBids[id]);
			eventEmitter.emit(BID_USED, cachedBids[id]);
			winRestrictionReference[id] = true;
			winningBids.push(cachedBids[id]);
		}
	}
	/**
	 * Renders winning ad to page
	 *
	 * @param {Document} doc
	 * @param {string} id
	 * @memberof bidCache
	 * @private
	 */
	// function renderAd(doc, id) {
	// 	// eslint-disable-next-line no-undef
	// 	if (typeof pbjs.renderAd !== 'undefined') {
	// 		// eslint-disable-next-line no-undef
	// 		pbjs.renderAd(doc, id);
	// 	}
	// }
	/**
	 * Function getter for getting cached bids
	 *
	 * @param {String|String[]} code unit code(s) to lookup
	 * @returns {BidBarrel~Bid[]} cached bids array
	 * @memberof bidCache
	 * @exposed
	 */
	function getCachedBids(code) {
		if (!code) return Object.values(cachedBids);
		return Array.isArray(code) ? code.map(getCachedBids).reduce((a, b) => a.concat(b), []) : Object.values(cachedBids).filter((bid) => bid.adUnitCode === code);
	}
	/**
	 * Function getter for getting winning bids
	 *
	 * @param {String|String[]} code unit code(s) to lookup
	 * @returns {BidBarrel~Bid[]} winning bids array
	 * @memberof bidCache
	 * @exposed
	 */
	function getWinningBids(code) {
		if (code) {
			return Array.isArray(code) ? code.map(getWinningBids).reduce((a, b) => a.concat(b), []) : winningBids.filter((bid) => bid.adUnitCode === code);
		}
		return winningBids;
	}
	function getLatestPendingBid(unitCode) {
		// eslint-disable-next-line no-shadow
		const cachedBids = getCachedBids();
		const matchingBids = [];
		for (let index = 0; index < cachedBids.length; index += 1) {
			const bid = cachedBids[index];
			if (bid.pendingFor === unitCode) {
				matchingBids.push(bid);
			}
		}
		if (matchingBids.length === 1) {
			return matchingBids[0];
		}
		if (matchingBids.length === 0) {
			return undefined;
		}
		return orderBy(matchingBids, ['pendingTimestamp'], ['desc'])[0];
	}
	function getLatestPendingBids(adUnitCodes) {
		let returnFirst = false;
		if (!Array.isArray(adUnitCodes)) {
			// eslint-disable-next-line no-param-reassign
			adUnitCodes = [adUnitCodes];
			returnFirst = true;
		}
		const codes = getUnitCodes(adUnitCodes);
		const results = [];
		for (let index = 0; index < codes.length; index += 1) {
			const code = codes[index];
			const latestPendingBid = getLatestPendingBid(code);
			if (latestPendingBid) {
				results.push(latestPendingBid);
			}
		}
		return returnFirst && results.length >= 1 ? results[0] : results;
	}
	/**
	 * This function handles evaluating the winning bids post auction and replaces Prebid's getWinningBids function logic.
	 *
	 * @param {String[]} adUnitCodes Array of ad unit codes
	 * @param {PrebidBid[]} bidsReceived Array of the most recently recieved bids
	 * @param {object} evaluationOptions options object to adjust how evaluation occurs
	 * @returns {PrebidBid[]} array of winning bids
	 * @private
	 * @memberof bidCache
	 */
	// eslint-disable-next-line default-param-last
	function evaluateWinningBids(adUnitCodes, bidsReceived = [], evaluationOptions) {
		if (!evaluationOptions.forTargeting) return getLatestPendingBids(adUnitCodes);
		// eslint-disable-next-line no-param-reassign
		adUnitCodes = filterAdUnits(adUnitCodes);
		// eslint-disable-next-line no-param-reassign
		adUnitCodes = applyPriorityOrder(adUnitCodes);
		bcLogger.atVerbosity(5).logInfo('Ad codes by Bid Priority Order', adUnitCodes);
		if (bidsReceived && Array.isArray(bidsReceived) && bidsReceived.length > 0) {
			pushBids(bidsReceived);
		}
		const filteredBids = getCachedBids().filter((bid) => bidFilter(bid, evaluationOptions));
		bcLogger.logInfo('Evaluating winning bids for', adUnitCodes.join(', '), filteredBids);
		// eslint-disable-next-line no-param-reassign
		bidsReceived = orderBy(filteredBids, ['cpm', 'responseTimestamp'], ['desc', 'asc']);
		const localWinningBids = [];
		for (let index = 0; index < adUnitCodes.length; index += 1) {
			const adUnitCode = adUnitCodes[index];
			for (let ix = 0; ix < bidsReceived.length; ix += 1) {
				const bid = bidsReceived[ix];
				if (isCompatible(bid, adUnitCode, evaluationOptions)) {
					if (evaluationOptions.forTargeting) {
						bcLogger.logMessage(`Winning Bid Found for ${adUnitCode} - adId: ${bid.adId}, cpm: ${bid.cpm}, mediaType: ${bid.mediaType}, bidder: ${bid.bidderCode}`);
						makePending(bid, adUnitCode);
					}
					localWinningBids.push(bid);
					break;
				}
			}
		}
		return localWinningBids;
	}

	function getLatestPendingBidTargeting(unitCode) {
		const latestPendingBid = getLatestPendingBid(unitCode);
		if (latestPendingBid) {
			return latestPendingBid.adserverTargeting;
		}
		return {};
	}

	function getBidByAdId(adId) {
		return allBids[adId];
	}

	function getAllBids() {
		return allBids;
	}
	/**
	 * Sets up listeners for various bid processing events
	 *
	 * @memberof bidCache
	 * @private
	 */
	function setupListeners() {
		eventEmitter.on(BAD_BID, (adId) => registerBadBid(adId));
		eventEmitter.on(AD_VIEWABLE, (unit) => removePending(unit.code));
		$$PREBID_GLOBAL$$.que.push(() => {
			$$PREBID_GLOBAL$$.onEvent(BID_WON, (bid) => markWinner(bid.adId));
		});
	}
	exposureApi.expose({
		getCachedBids,
		getWinningBids,
		bidFilter,
	});

	exposureApi.rootScope({
		getCachedBids,
	});

	return {
		bidFilter,
		filterAdUnits,
		getCachedBids,
		getAllBids,
		makePending,
		pushBids,
		evaluateWinningBids,
		applyPriorityOrder,
		getBidByAdId,
		getLatestPendingBids,
		getLatestPendingBid,
		getLatestPendingBidTargeting,
		setupListeners,
	};
})();

/**
 * A method to get all internally tracked bids within BidBarrel
 *
 *
 * @name BidBarrel.getCachedBids
 * @method
 * @type {Function}
 * @returns {BidBarrel~Bid[]}
 */

// eslint-disable-next-line func-names
const bidcachingModuleBase = (function () {
	/**
	 * The register function is executed immediately when the moduleManager.register function below occurs unless this module has dependencies in which this function would execute after all dependencies have registered
	 *
	 * The register function gets the core BidBarrel library passed into it such that function hooks can be registered and core api usage can occur.
	 *
	 * @private
	 * @memberof BidCaching
	 */
	function register() {
		bcLogger.logInfo('Register fired.');

		bidCache.getBidByAdId = bidCache2.getBidByAdId;
		bidCache.getLatestPendingBid = bidCache2.getLatestPendingBid;
		bidCache.getAllBids = bidCache2.getAllBids;
		bidCache.evaluateWinningBids = bidCache2.evaluateWinningBids;

		bidCache.setupListeners = bidCache2.setupListeners;
		bidCache.applyPriorityOrder = bidCache2.applyPriorityOrder;
		bidCache.bidFilter = bidCache2.bidFilter;
		bidCache.filterAdUnits = bidCache2.filterAdUnits;
		bidCache.getCachedBids = bidCache2.getCachedBids;
		bidCache.makePending = bidCache2.makePending;
		bidCache.pushBids = bidCache2.pushBids;
		bidCache.getLatestPendingBidTargeting = bidCache2.getLatestPendingBidTargeting;

		bidCache.setupListeners();
	}

	/**
	 * Initializes module which loads BidCaching
	 *
	 * @private
	 * @memberof BidCaching
	 */
	function initialize() {
		bcLogger.logInfo('Initialize fired.');
	}

	return {
		name: BIDCACHING,
		register,
		initialize,
	};
})();

export const bidcachingModule = moduleManager.register(bidcachingModuleBase, null);
