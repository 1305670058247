import omit from './helpers/omit';
import { hookedFn } from './hookedFunction';
import { bbLogger } from './logger';
import { dom } from '../global';

const { logInfo } = bbLogger.atVerbosity(3);
/**
 * Script renderer
 *
 * @export
 * @param {object} passedConfig - object config for rendering
 * @returns {void}
 */
export const renderScript = hookedFn('sync', passedConfig => {
	if (passedConfig.constructor === Array) {
		for (let index = 0; index < passedConfig.length; index++) {
			const singlePassedConfig = passedConfig[index];
			renderScript(singlePassedConfig);
		}
		return;
	}
	if (dom().window.document.getElementById(passedConfig.id) || (passedConfig.shouldRender && !passedConfig.shouldRender())) return;
	var renderConfig = {
		attributes: omit(passedConfig, ['content', 'parent', 'shouldRender', 'filter']),
		parent: passedConfig.parent,
		content: passedConfig.content
	};
	let fileName = '';
	if (passedConfig.src) {
		fileName = passedConfig.src.substring(passedConfig.src.lastIndexOf('/') + 1);
	}
	if (
		passedConfig.src &&
		!dom().window.document.querySelector("script[src*='" + passedConfig.src + "']") &&
		!dom().window.document.querySelector("script[src*='" + fileName + "']")
	) {
		logInfo('Rendering script via BidBarrel', passedConfig);
		(function(d, s, attr, content, appendTo) {
			var js;
				var fjs = d.getElementsByTagName(s)[0];
				var parent = typeof appendTo !== 'undefined' ? d.querySelector(appendTo) : false;
			if (attr.id && d.getElementById(attr.id)) {
				return;
			}

			js = d.createElement(s);
			js.setAttribute('type', 'text/javascript');
			for (var attrKey in attr) {
				if (attr.hasOwnProperty(attrKey)) {
					js.setAttribute(attrKey, attr[attrKey] === true ? '' : attr[attrKey]);
				}
			}
			if (content) {
				js.innerHTML = content;
			}
			if (parent) {
				parent.append(js);
			} else if (parent !== null) {
				fjs.parentNode.insertBefore(js, fjs);
			}
		})(dom().window.document, 'script', renderConfig.attributes, renderConfig.content, renderConfig.parent);
	}
});
