import defaultsDeep from '../../utilities/helpers/defaultsDeep.js';
import { moduleManager } from '../../moduleManager.js';
import { makeSafe } from '../../utilities/safeFunction.js';
import { exposureApi } from '../../exposureApi.js';
import { getConfig } from '../../config.js';
import { processAdRequest } from '../../bidbarrel.js';
import { setBidTargeting, setTargeting } from '../../targeting.js';
import { bbLogger } from '../../utilities/logger.js';
import { storage } from '../../services/storage.js';
import CONSTANTS from '../../constants.json';
import { pageTargetingObj } from '../../pageTargeting.js';

// constants
const { DYNAMIC_TARGETING } = CONSTANTS.MODULES;

const TARGETING_LEVEL = {
	PAGE: 'page',
	UNIT: 'unit',
};

/**
 * Dynamic Targeting Module
 *
 * Handles dynamically exclusion/inclusion/modifying slot targeting based on configured rulesets
 *
 * @module DynamicTargeting
 * @private
 */
const dynamicTargetingModule = (() => {
	/**
	 * General config for module
	 *
	 * @memberof DynamicTargeting
	 * @private
	 */
	let config = {};
	/**
	 * Tracks registered hooks for deregistration
	 *
	 * @memberof DynamicTargeting
	 * @private
	 */
	const hooks = {};
	/**
	 * Cached targeting results
	 *
	 * @memberof DynamicTargeting
	 * @private
	 */
	const cachedResults = {};
	/**
	 * Units to set targeting on once consent is given
	 *
	 * @memberof DynamicTargeting
	 * @private
	 */
	const onConsentUnitsRun = {};

	/**
	 * Applies the post processing of the unit hook
	 *
	 * @memberof DynamicTargeting
	 * @private
	 */
	function getIdentityContext() {
		return {
      property: getConfig('dfpPath.property'),
			bidderTimeout: getConfig('highFrequencyAdRequests') ? getConfig('timeouts.hfar') : getConfig('timeouts.bidder'),
			seamlessIndicator: pageTargetingObj.getPageTargeting('seamlessIndicator'),
			getCookie: storage.raw.getCookie,
		};
	}
	/**
	 * Applies page level dynamic targeting
	 *
	 * @param {string} [hook='processAdRequest'] The hook at which the rules should be filtered on
	 * @private
	 * @memberof DynamicTargeting
	 */
	function applyPageLevelTargeting(hook = 'processAdRequest') {
		let results = {};
		for (let index = 0; index < config.rules.length; index += 1) {
			const rule = config.rules[index];
			if (rule.level === TARGETING_LEVEL.PAGE && rule.hook === hook) {
				if (rule.getValue) {
					// eslint-disable-next-line no-loop-func
					makeSafe(() => {
						if (rule.matcher(getIdentityContext())) {
							let result = cachedResults[`_page${rule.key}`];
							if (!rule.cacheResult || typeof result === 'undefined') {
								result = rule.getValue(getIdentityContext());
								cachedResults[`_page${rule.key}`] = result;
							}
							if (result !== null) {
								results[rule.key] = result;
							}
						}
					});
				} else if (rule.modify) {
					// eslint-disable-next-line no-loop-func
					makeSafe(() => {
						if (rule.matcher(getIdentityContext())) {
							results = rule.modify(results, getIdentityContext());
						}
					});
				}
			}
		}
		bbLogger.logInfo('Setting page level dynamic targeting', results);
		setTargeting(results);
	}
	/**
	 * Hook for applying page level targeting
	 *
	 * @param {string} [hook='processAdRequest'] The hook at which the rules should be filtered on
	 * @returns {function} hook function
	 * @private
	 * @memberof DynamicTargeting
	 */
	function applyPageLevelTargetingAtHook(hook = 'processAdRequest') {
		return (next, unitCollection) => {
			applyPageLevelTargeting(hook);
			next(unitCollection);
		};
	}
	/**
	 * Reusable function to handle configured targeting modifiers
	 *
	 * @memberof DynamicTargeting
	 * @param {object|function|function[]} modifier multi-type modifier configuration
	 * @param {BidBarrel~AdUnit} unit
	 * @private
	 */
	function evaluateTargetingModifier(modifier, unit) {
		let modifiedUnit = unit;
		if (modifier.constructor === Array) {
			for (let index = 0; index < modifier.length; index += 1) {
				const currentModifier = modifier[index];
				modifiedUnit = evaluateTargetingModifier(currentModifier, modifiedUnit);
			}
			return modifiedUnit;
		}
		if (typeof modifier === 'function') {
			makeSafe(() => {
				modifiedUnit.targeting = modifier(unit, getIdentityContext());
			});
		} else if (typeof modifier === 'object') {
			modifiedUnit.targeting = defaultsDeep({}, modifier, modifiedUnit.targeting);
		}
		return modifiedUnit;
	}
	/**
	 * Handles applying dynamic targeting to a specific BidBarrel ad unit
	 *
	 * Requires module: `dynamicTargeting`
	 *
	 * @param {BidBarrel~AdUnit|BidBarrel~AdUnit[]} unit
	 * @param {string} [hook='processAdRequest'] The hook at which the rules should be filtered on
	 * @memberof DynamicTargeting
	 * @returns {BidBarrel~AdUnit} modified unit
	 * @private
	 * @memberof DynamicTargeting
	 * @exposed
	 */
	function applyUnitLevelTargeting(unit, hook = 'processAdRequest') {
		if (!unit || !config.rules || !config.rules.length) return unit;
		if (Array.isArray(unit)) {
			const result = [];
			for (let index = 0; index < unit.length; index += 1) {
				const currentUnit = unit[index];
				result.push(applyUnitLevelTargeting(currentUnit, hook));
			}
			return result;
		}
		let modifiedUnit = unit;
		bbLogger.logInfo(`Applying Dynamic Targeting Rules level=unit(${unit.code}), hook=${hook}`, config.rules);
		for (let index = 0; index < config.rules.length; index += 1) {
			const rule = config.rules[index];
			if (rule.level === TARGETING_LEVEL.UNIT && rule.hook === hook) {
				if (rule.getValue) {
					// eslint-disable-next-line no-loop-func
					makeSafe(() => {
						if (rule.matcher(modifiedUnit, getIdentityContext())) {
							let result = cachedResults[modifiedUnit.code + rule.key];
							if (!rule.cacheResult || typeof result === 'undefined') {
								result = rule.getValue(modifiedUnit, getIdentityContext());
								cachedResults[modifiedUnit.code + rule.key] = result;
							}
							if (!modifiedUnit.dynamicTargeting) {
								modifiedUnit.dynamicTargeting = {};
							}
							if (result !== null) {
								modifiedUnit.dynamicTargeting[rule.key] = result;
							}
						}
					});
				} else if (rule.modify) {
					// eslint-disable-next-line no-loop-func
					makeSafe(() => {
						if (rule.matcher(modifiedUnit)) {
							modifiedUnit.dynamicTargeting = rule.modify(modifiedUnit, getIdentityContext());
						}
					});
				}
			}
		}
		if (config.units[modifiedUnit.code]) {
			modifiedUnit = evaluateTargetingModifier(config.units[modifiedUnit.code], modifiedUnit);
		}
		if (config.units[modifiedUnit.originalCode]) {
			modifiedUnit = evaluateTargetingModifier(config.units[modifiedUnit.originalCode], modifiedUnit);
		}
		if (typeof moduleManager.gateways.getGates().consentGiven !== 'undefined' && !typeof moduleManager.gateways.getGates().consentGiven) {
			onConsentUnitsRun[modifiedUnit.code] = modifiedUnit;
		}
		bbLogger.logInfo('Setting unit level dynamic targeting', modifiedUnit.code, modifiedUnit.dynamicTargeting);
		setTargeting(modifiedUnit.dynamicTargeting, [modifiedUnit.code]);
		return modifiedUnit;
	}
	/**
	 * Hook for processing a unit specific targeting
	 *
	 * @memberof DynamicTargeting
	 * @param {string} [hook='processAdRequest'] The hook at which the rules should be filtered on
	 * @returns {function} hook function
	 * @private
	 */
	function applyUnitLevelTargetingAtHook(hook = 'processAdRequest') {
		return (next, unit) => {
			next(applyUnitLevelTargeting(unit, hook));
		};
	}
	/**
	 * Sets config for dynamic bidder adapter
	 *
	 * @memberof DynamicTargeting
	 * @private
	 */
	function register() {
		getConfig('dynamicTargeting', (value) => {
			config = value;
		});
		getConfig('consent', (consentGiven) => {
			if (consentGiven && config.rules) {
				bbLogger.logInfo('Setting Dynamic Targeting after consent given');
				applyPageLevelTargeting();
				applyUnitLevelTargeting(Object.values(onConsentUnitsRun));
			}
		});
		hooks.page = {
			processAdRequest: applyPageLevelTargetingAtHook('processAdRequest'),
		};
		hooks.unit = {
			processAdRequest: applyUnitLevelTargetingAtHook('processAdRequest'),
			setBidTargeting: applyUnitLevelTargetingAtHook('setBidTargeting'),
		};
		processAdRequest.before(hooks.page.processAdRequest);
		processAdRequest.before(hooks.unit.processAdRequest);
		setBidTargeting.after(hooks.unit.setBidTargeting);
	}
	/**
	 * Method to deregister module
	 *
	 * @memberof DynamicTargeting
	 * @private
	 */
	function deregister() {
		processAdRequest.getHooks({ hook: hooks.page.processAdRequest }).remove();
		processAdRequest.getHooks({ hook: hooks.unit.processAdRequest }).remove();
		setBidTargeting.getHooks({ hook: hooks.unit.setBidTargeting }).remove();
	}
	exposureApi.expose({
		applyUnitLevelTargeting,
		applyPageLevelTargeting,
	});
	return {
		name: DYNAMIC_TARGETING,
		protected: true,
		register,
		deregister,
	};
})();

// Register module
export const dynamicTargeting = moduleManager.register(dynamicTargetingModule);
export default dynamicTargeting;
