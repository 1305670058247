import { bbLogger } from './logger';

/**
 * Callback Queueing system that mimics an array's .push function. Can also take a pre-existing array of callbacks and log execution
 *
 * @export
 * @param {string} identifier - Identifier string for queue
 * @param {function[]} [initialCallbackSet=[]] - Pre-exisiting callback set
 * @param {boolean} [logExecution=false] - whether or not to log execution
 * @returns {object} public facing api
 * @example
  window.existingCallbackArr = window.existingCallbackArr || [];

  window.exisitingCallbackArr.push(() => console.log("queue item"));

  const myQueue = runQueue("test run queue", window.existingCallbackArr);

  // when ready
  myQueue.run();
  // () => console.log("queue item") is ran

  // after
  myQueue.push(() => console.log("runs immediately"))
  // () => console.log("runs immediately") is ran
 */
export function runQueue(identifier, initialCallbackSet = [], logExecution = false) {
	const id = identifier;
	const loggingEnabled = logExecution;
	let queuedCallbacks = initialCallbackSet;
	let hasRan = false;
	let runArgs = [];
	/**
	 * Mimics an array.push functionalty
	 * @param {Function} callback - callback to push onto the queue array
	 */
	function push(callback) {
		bbLogger.atVerbosity(4).logInfo("Run Queue Item Added", callback);
		if (hasRan) {
			log('Executing callback', callback);
			try {
				callback.apply(null, runArgs);
			} catch (err) {
				if (err) {
					bbLogger.logError(id, err);
				}
			}
			return function noOp(){};
		} else {
			const id = queuedCallbacks.length;
			queuedCallbacks.push(callback);
			return function cancelQueuedItem() { queuedCallbacks = queuedCallbacks.filter((v, i) => i !== id) }
		}
	}
	/**
	 * Executes the queue stack
	 */
	function run() {
		if (!hasRan) {
			hasRan = true;
			runArgs = arguments;
			queuedCallbacks.forEach(cb => {
				log('Executing callback', cb);
				try {
					cb.apply(null, arguments);
				} catch (err) {
					if (err) {
						bbLogger.logError(id, err);
					}
				}
			});
		}
	}
	/**
	 * Logs info out to the console
	 */
	function log() {
		if (loggingEnabled) {
			bbLogger.atVerbosity(5).logInfo(id, ...arguments);
		}
	}
	/**
	 * Function getter for hasRan status
	 */
	function hasRanStatus(){
		return hasRan;
	}
	/**
	 * Expose public methods and hasRan flag
	 */
	return { hasRan, hasRanStatus, run, push };
}
/**
 * Promise based queue
 *
 * @export
 * @param {string} name - name of queue
 * @param {function[]} [existingStackArr=[]] - existing array stack
 * @returns {PromiseQueue}
 */
export function promiseQueue(name, existingStackArr = []) {
	const que = existingStackArr;
	let currentIndex = 0;
	let isRunning = false;
	let hasStarted = false;
	// eslint-disable-next-line no-unused-vars
	let promise = Promise.resolve();
	const push = callback => {
		bbLogger.atVerbosity(4).logInfo("Promise Queue Item Added", callback);
		que.push(callback);
		if (!isRunning && hasStarted) {
			run();
		}
	};
	function isCurrentlyRunning() {
		return !!isRunning;
	}
	function setRunning(value) {
		isRunning = value;
	}
	const run = () => {
		if (!hasStarted) {
			hasStarted = true;
		}
		if (!isCurrentlyRunning() && que[currentIndex]) {
			promise = new Promise((resolve, reject) => {
				bbLogger.atVerbosity(5).logMessage('Running', name, 'item', currentIndex);
				setRunning(true);
				const onResolve = err => {
					if (err) {
						bbLogger.atVerbosity(5).logError('Error', name, 'item', currentIndex, err, 'Callback:', que[currentIndex]);
					} else {
						bbLogger.atVerbosity(5).logMessage('Finished', name, 'item', currentIndex);
					}
					currentIndex++;
					setRunning(false);
					resolve();
				};
				try {
					que[currentIndex](onResolve);
				} catch (err) {
					onResolve(err);
				}
			}).then(run);
		}
	};
	return {
		get currentIndex() {
			return currentIndex;
		},
		get isRunning() {
			return isRunning;
		},
		get remainingItems() {
			return que.slice(currentIndex + 1);
		},
		get stack() {
			return que;
		},
		push,
		run
	};
}
