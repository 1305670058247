import { urlQueryAsObject } from './queryParams.js';
import defaultsDeep from './helpers/defaultsDeep.js';
import { getStackTrace } from './getStackTrace.js';
import { dom } from '../global.js';
// Constants
import CONSTANTS from '../constants.json';

const { LOG_VERBOSITY, PBJS_DEBUG } = CONSTANTS.QUERY_PARAMS;
const { BIDBARREL } = CONSTANTS.LOGS;

// Log util registry
const coreRegisteredLoggers = {};

const logStack = [];

// Debug query param handling
const query = urlQueryAsObject();
const pbjsQp = query[PBJS_DEBUG];
let debugTurnedOn = pbjsQp ? ['true', 'trace', 'time'].indexOf(pbjsQp.toLowerCase()) >= 0 : false;

// Default theme values
let defaultLogConfig = {
	name: BIDBARREL.LABEL,
	bgColor: BIDBARREL.COLOR,
	textColor: '#FFF',
};

// Used to configuration methods when setting up a log util
const logMethodConfig = {
	logMessage: {
		consoleMethod: 'log',
	},
	logInfo: {
		consoleMethod: 'info',
	},
	logWarn: {
		consoleMethod: 'warn',
	},
	logError: {
		consoleMethod: 'error',
	},
};
// Handles styled prefixes for log lines
const consolePrefix = (logKey, options) => {
	if (!dom().window.document.documentMode && !/Edge/.test(dom().window.navigator.userAgent)) {
		return {
			label: `%c${options.name}`,
			descriptor: `${logKey.toUpperCase()}:`,
			style: `display: inline-block; color: ${options.textColor}; background: ${options.bgColor}; padding: 1px 4px; border-radius: 3px;`,
		};
	}
	return {
		label: `${options.name}`,
		descriptor: `${logKey.toUpperCase()}:`,
		style: '',
	};
};
/**
 * Calls reset on all logging utilities
 *
 * @private
 */
function resetAllLoggers() {
	// eslint-disable-next-line no-restricted-syntax
	for (const key in coreRegisteredLoggers) {
		// eslint-disable-next-line no-prototype-builtins
		if (coreRegisteredLoggers.hasOwnProperty(key)) {
			coreRegisteredLoggers[key].reset();
		}
	}
}

export function getLogStack() {
	return logStack;
}
let lastLogTime = 0;
/**
 * Gets the current value of debug
 *
 * @export
 * @param {String} value to check debug param for
 * @returns {Boolean} whether or not debug is turned on
 */
export function debugOn(value = false) {
	if (value && debugTurnedOn) {
		return pbjsQp.toLowerCase() === value.toLowerCase();
	}
	return debugTurnedOn;
}
/**
 * Logging Utility
 *
 * @param {LogUtilConfig} loggerConfig Generally contains the name and theme values for the log util
 * @module Logger
 */
export const logger = function lgr({ name, textColor, bgColor }) {
	/**
	 * Internal var tracking exposed methods
	 *
	 * @memberof Logger
	 * @private
	 */
	const pipeApi = {};
	/**
	 * Internal overrides method which allows config based changes to the logger
	 *
	 * @memberof Logger
	 * @private
	 */
	const coreOverrides = {};

	/**
	 * Method called to determine if a specific console method should be setup or just stubbed
	 *
	 * @param {Object} {consoleMethod, level} utilized
	 * @returns {Boolean} whether or not it is ok to provide a non-stubbed log utility function
	 * @memberof Logger
	 * @private
	 */
	function isMethodEnabled({ consoleMethod, level }) {
		const allowed = coreOverrides.allow || {};
		if ((allowed.consoleMethods && allowed.consoleMethods[consoleMethod]) || (allowed.verbosity && allowed.verbosity[`@${level}`])) {
			return true;
		}
		if (!debugOn()) return false;
		if (parseInt(query[LOG_VERBOSITY] || 3, 10) >= parseInt(level, 10)) return true;
		return false;
	}

	/**
	 * Verbosity method which allows exposing a set of loggers at a specific verbosity level
	 *
	 * @param {Number} level The verbosity level to expose these values at
	 * @returns {LoggerApi}
	 * @memberof Logger
	 * @private
	 */
	function atVerbosity(level) {
		if (!pipeApi[`@${level}`]) {
			pipeApi[`@${level}`] = Object.keys(logMethodConfig).reduce((resultArg, val) => {
				const result = resultArg;
				const { consoleMethod } = logMethodConfig[val];
				const methodEnabled = isMethodEnabled({ level, consoleMethod });
				const qp = pbjsQp ? pbjsQp.toLowerCase() : '';
				if ((qp === 'trace' || qp === 'time') && methodEnabled) {
					const { label, style, descriptor } = consolePrefix(consoleMethod, { name, textColor, bgColor });
					result[val] = (...args) => {
						const now = dom().window.performance.now();
						const nowInt = parseInt(now, 10);
						// eslint-disable-next-line no-console
						const coreLogMethod =
							// eslint-disable-next-line no-console
							typeof console[consoleMethod].bind === 'undefined'
								? // eslint-disable-next-line no-console
								  Function.prototype.bind.call(console[consoleMethod], console, label, style, `( ${nowInt} | +${nowInt - lastLogTime} )`, descriptor)
								: // eslint-disable-next-line no-console
								  console[consoleMethod].bind(console, label, style, `( ${nowInt} | +${nowInt - lastLogTime} )`, descriptor);
						lastLogTime = nowInt;
						logStack.push({ type: val, input: args, stack: qp === 'trace' ? getStackTrace() : 'omitted', verbosity: level, time: now });
						coreLogMethod(...args);
					};
					// result[val].wrapped = true;
				} else if (methodEnabled) {
					const { label, style, descriptor } = consolePrefix(consoleMethod, { name, textColor, bgColor });
					result[val] =
						// eslint-disable-next-line no-console
						typeof console[consoleMethod].bind === 'undefined'
							? // eslint-disable-next-line no-console
							  Function.prototype.bind.call(console[consoleMethod], console, label, style, descriptor)
							: // eslint-disable-next-line no-console
							  console[consoleMethod].bind(console, label, style, descriptor);
				} else {
					// eslint-disable-next-line no-unused-vars
					result[val] = (...args) => false;
				}
				return result;
				// eslint-disable-next-line no-use-before-define
			}, theCoreMethods());
		}
		return pipeApi[`@${level}`];
	}

	/**
	 * Sets up all logger utilities again with any new settings applied
	 *
	 * @memberof Logger
	 * @private
	 */
	function reset() {
		// eslint-disable-next-line no-restricted-syntax
		for (const key in pipeApi) {
			// eslint-disable-next-line no-prototype-builtins
			if (pipeApi.hasOwnProperty(key)) {
				pipeApi[key] = atVerbosity(key.replace('@', ''));
			}
		}
	}
	/**
	 * Provides additional methods to the exposed API of the logger
	 *
	 * @returns {Object} partial logger api
	 * @private
	 * @memberof Logger
	 */
	function theCoreMethods() {
		return {
			atVerbosity,
			reset,
			setLoggerConfig: (overrides) => {
				coreOverrides.allow = overrides;
				reset();
			},
		};
	}

	// Initial setup of instantiated log utility
	coreRegisteredLoggers[name] = coreRegisteredLoggers[name] || atVerbosity(3);

	// Return instantiated log utility
	return coreRegisteredLoggers[name];
};
/**
 * Generic logger for general use
 *
 * @type {LoggerApi}
 */
export const bbLogger = logger({ name: BIDBARREL.LABEL, bgColor: BIDBARREL.COLOR, textColor: '#FFF' });

/**
 * Gets the query param verbosity level (or 3 if it's not defined)
 *
 * @export
 * @returns {Number} verbosity level
 * @private
 */
export function getVerbosityLevel() {
	return parseInt(query[LOG_VERBOSITY] || 3, 10);
}
/**
 * Updates default logger config
 *
 * @param {Object} newConfig new config to update to
 * @private
 * @export
 */
export function setDefaultConfig(newConfig) {
	defaultLogConfig = defaultsDeep({}, newConfig, defaultLogConfig);
}
/**
 * Method to turn on debug automatically
 *
 * @export
 * @private
 */
export function turnOnDebug() {
	debugTurnedOn = true;
	resetAllLoggers();
}

/**
 * Logging Utility API
 *
 * @typedef LoggerApi
 * @type {Object}
 * @private
 *
 * @param {Function} logInfo info level logging
 * @param {Function} logMessage log level logging
 * @param {Function} logWarn warn level logging
 * @param {Function} logError error level logging
 * @param {Function} atVerbosity Returns another LoggerApi with the given verbosity level considered(stubs if the debug on and verbosity level constraints are not met)
 * @param {Function} reset sets all log utilities again overwriting the previous
 * @param {Function} setLoggerConfig allows overrides to be applied to specific logging utilities
 */
/**
 * Logging Utility Config
 *
 * @typedef LogUtilConfig
 * @type {Object}
 * @private
 *
 * @param {String} name the name of the logger
 * @param {String} bgColor  color css value to apply as bg to the label for the logger(Incompatible with IE)
 * @param {String} textColor color css value to apply as the text color of the label for the logger (incompatible with IE)
 */
