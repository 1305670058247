import uniqBy from './helpers/uniqBy';
import get from './helpers/get';
import isEmpty from './helpers/isEmpty';
import { createReduceMerger } from './reduceMerger';

export function concatConfigArrays(newConfig, existingConfig) {
	if (isEmpty(existingConfig) && isEmpty(newConfig)) return {};
	if (isEmpty(existingConfig)) return newConfig;
	if (isEmpty(newConfig)) return existingConfig;
	if (get(newConfig, 'bootstrap.renderScripts') && get(existingConfig, 'bootstrap.renderScripts')) {
		newConfig.bootstrap.renderScripts = uniqBy(newConfig.bootstrap.renderScripts.concat(existingConfig.bootstrap.renderScripts), 'id');
	}
	if (get(newConfig, 'analytics.bidbarrel') && get(existingConfig, 'analytics.bidbarrel')) {
		newConfig.analytics.bidbarrel = uniqBy(newConfig.analytics.bidbarrel.concat(existingConfig.analytics.bidbarrel), 'id');
	}
	if (get(newConfig, 'analytics.prebid') && get(existingConfig, 'analytics.prebid') && Array.isArray(get(newConfig, 'analytics.prebid')) && Array.isArray(get(existingConfig, 'analytics.prebid'))) {
		newConfig.analytics.prebid = uniqBy(newConfig.analytics.prebid.concat(existingConfig.analytics.prebid), 'provider');
	} else if (get(newConfig, 'analytics.prebid') && get(existingConfig, 'analytics.prebid') && Array.isArray(get(existingConfig, 'analytics.prebid'))) {
		newConfig.analytics.prebid = uniqBy([...get(existingConfig, 'analytics.prebid'), get(newConfig, 'analytics.prebid')], 'provider');
	} else if (get(newConfig, 'analytics.prebid') && get(existingConfig, 'analytics.prebid') && Array.isArray(get(newConfig, 'analytics.prebid'))) {
		newConfig.analytics.prebid = uniqBy([...get(newConfig, 'analytics.prebid'), get(existingConfig, 'analytics.prebid')], 'provider');
	}
	if (get(newConfig, 'dynamicTargeting.rules') && get(existingConfig, 'dynamicTargeting.rules')) {
		newConfig.dynamicTargeting.rules = uniqBy(newConfig.dynamicTargeting.rules.concat(existingConfig.dynamicTargeting.rules), 'name');
	}

	if(get(newConfig, "video.getUnitSpec") && get(existingConfig, "video.getUnitSpec")){
		const result = createReduceMerger();
		result.addConfig(existingConfig.video.getUnitSpec);
		result.addConfig(newConfig.video.getUnitSpec);
		newConfig.video.getUnitSpec = result.getMergeSet();
	}
	if(get(newConfig, "video.getSpec") && get(existingConfig, "video.getSpec")){
		const result = createReduceMerger();
		result.addConfig(existingConfig.video.getSpec);
		result.addConfig(newConfig.video.getSpec);
		newConfig.video.getSpec = result.getMergeSet();
	}
	if(get(newConfig, "video.translateSpec") && get(existingConfig, "video.translateSpec")){
		const result = createReduceMerger();
		result.addConfig(existingConfig.video.translateSpec);
		result.addConfig(newConfig.video.translateSpec);
		newConfig.video.translateSpec = result.getMergeSet();
	}
	if(get(newConfig, "video.getDefaultParamsOptions") && get(existingConfig, "video.getDefaultParamsOptions")){
		const result = createReduceMerger();
		result.addConfig(existingConfig.video.getDefaultParamsOptions);
		result.addConfig(newConfig.video.getDefaultParamsOptions);
		newConfig.video.getDefaultParamsOptions = result.getMergeSet();
	}
	
	if (newConfig && newConfig.dynamicBidders) {
		for (const bidderCode in newConfig.dynamicBidders) {
			if (Object.prototype.hasOwnProperty.call(newConfig.dynamicBidders, bidderCode)) {
				if(bidderCode !== "autoApplyBidders"){
					const result = createReduceMerger();
					result.addConfig(get(existingConfig,`dynamicBidders.${bidderCode}`) || null );
					result.addConfig(newConfig.dynamicBidders[bidderCode]);
					newConfig.dynamicBidders[bidderCode] = result.getMergeSet();
				} else {
					for (const aaBidderCode in newConfig.dynamicBidders.autoApplyBidders) {
						if (Object.prototype.hasOwnProperty.call(newConfig.dynamicBidders.autoApplyBidders, aaBidderCode)) {
							const result = createReduceMerger();
							result.addConfig(get(existingConfig, `dynamicBidders.autoApplyBidders.${aaBidderCode}`) || null);
							result.addConfig(newConfig.dynamicBidders.autoApplyBidders[aaBidderCode]);
							newConfig.dynamicBidders.autoApplyBidders[aaBidderCode] = result.getMergeSet();
						}
					}
				}
			}
		}
	}
	return newConfig;
}
