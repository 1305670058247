import { makeSafe } from './safeFunction';

export function percentageShouldRun(percentOrCallback, defaultValue = false) {
	if (typeof percentOrCallback === 'boolean' && percentOrCallback) {
		return percentOrCallback;
	} else if (typeof percentOrCallback === 'number') {
		return Math.random() * 100 <= percentOrCallback;
	} else if (typeof percentOrCallback === 'function') {
		return makeSafe(
			() => percentOrCallback(),
			null,
			() => defaultValue
		);
	} else {
		return defaultValue;
	}
}

export function percentageRunner(percentOrCallback, callback, defaultValue = false) {
	if (typeof percentOrCallback === 'boolean' && percentOrCallback) {
		callback();
	} else if (typeof percentOrCallback === 'number') {
		if (Math.random() * 100 <= percentOrCallback) {
			callback();
		}
	} else if (typeof percentOrCallback === 'function') {
		if (
			makeSafe(
				() => percentOrCallback(),
				null,
				() => defaultValue
			)
		) {
			callback();
		}
	} else if (defaultValue) {
		callback();
	}
}

module.exports = { percentageRunner, percentageShouldRun };
