import CONSTANTS from '../../../constants.json';
import { eventEmitter } from '../../../events.js';
import { dom } from '../../../global.js';
import { logger } from '../../../utilities/logger.js';

const tLogger = logger({ name: 'Timings', bgColor: '#660000', textColor: '#FFF' });

const { AD_DENSITY } = CONSTANTS.ANALYTICS_RECORD_DEFAULTS;
const { AD_DENSITY_AVAILABLE } = CONSTANTS.EVENTS;
let adDensityObj = null;

/**
 * Computes the Ad Density Object
 *
 * @returns {Object} Ad Density Object
 */
export function calculateAdDensity() {
	const { document } = dom().window;

	const { body } = document;
	const html = document.documentElement;
	const pageHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
	const pageWidth = Math.max(body.scrollWidth, body.offsetWidth, html.clientWidth, html.scrollWidth, html.offsetWidth);

	let adsTotalSurface = 0;
	let adsCount = 0;
	let adsHeight = 0;
	let adsWidth = 0;

	Object.values($$BB_VAR$$.slots).forEach((slot) => {
		const elementId = slot.getSlotElementId();
		const element = document.getElementById(elementId);
		if (element) {
			const height = element.offsetHeight;
			const width = element.offsetWidth;
			// bbLogger.logInfo("MLS found ad", { elementId, height, width });
			adsHeight += height;
			adsWidth += width;
			adsTotalSurface += height * width;
			adsCount += 1;
		}
	});

	adDensityObj = {
		type: 'timing',
		name: AD_DENSITY.NAME,
		slug: AD_DENSITY.SLUG,
		pageHeight,
		pageWidth,
		adsCount,
		adsHeight,
		adsWidth,
		adsTotalSurface,
		verticalAdDensity: (pageHeight > 0 ? adsHeight / pageHeight : 0).toFixed(3),
		adDensity: (pageHeight * pageWidth > 0 ? adsTotalSurface / (pageHeight * pageWidth) : 0).toFixed(3),
		amount: dom().window.performance.now(),
	};
	tLogger.logInfo('Measured Ad Density', adDensityObj);
  eventEmitter.emit(AD_DENSITY_AVAILABLE, adDensityObj);
	return adDensityObj;
}
/**
 * Returns the ad density object, if it is not already calculated, it will calculate it
 * @returns ad density object

 */
/**
	 * Function getter for the Ad Density Object
	 *
	 * @returns {Object} Returns the Ad Density Object
	 * @param {*} forceRecalculate force the recalculation of the ad density object default is false
   *
	 * @memberof config
	 * @exposed
	 * @example
   *
   BidBarrel.getAdDensityObj();
   // returns:
    // {
    // "type": "state",
    // "name": "Ad Density",
    // "slug": "ad-density",
    // "pageHeight": 7543,
    // "pageWidth": 1590,
    // "adsCount": 7,  // how many ads were found on the page when ad density was calculated
    // "adsHeight": 1180,
    // "adsWidth": 3567,
    // "adsTotalSurface": 520870, // sum of all ads height * width
    // "verticalAdDensity": "0.156", // adsHeight / pageHeight  or 0 if pageHeight is 0
    // "adDensity": "0.043", // adsTotalSurface / (pageHeight * pageWidth) or 0 if pageHeight * pageWidth is 0
    // "amount": 54850.09999990463 // how long after page load before AdDensity was calculated
    // }
   *
	 */

export function getAdDensityObj(forceRecalculate = false) {
	if (adDensityObj === null || forceRecalculate===true ) {
		calculateAdDensity();
	}
	return adDensityObj;
}
export default getAdDensityObj;
