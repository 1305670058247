import { adServerRequest, isMobile } from '../../bidbarrel.js';
import { getConfig } from '../../config.js';
import CONSTANTS from '../../constants.json';
import { exposureApi } from '../../exposureApi.js';
import { moduleManager } from '../../moduleManager.js';
import { getPageTargeting } from '../../targeting.js';
import { getUnits, getUnitCollection, defineUnit, getSizes } from '../../unitManager.js';
import { cloneDeep } from '../../utilities/cloneDeep.js';
import defaultsDeep from '../../utilities/helpers/defaultsDeep.js';
import get from '../../utilities/helpers/get.js';
import omit from '../../utilities/helpers/omit.js';
import pick from '../../utilities/helpers/pick.js';
import { hookedFn } from '../../utilities/hookedFunction.js';
import { logger } from '../../utilities/logger.js';
import { createReduceMerger } from '../../utilities/reduceMerger.js';
// import { isAndroid, isChrome, isIOS, isSafari, systemInfo, getOsVersion } from '../../utilities/system';
// import { dom } from '../../global';


const { VIDEO, GOOGLE_PUBLISHER_TAG } = CONSTANTS.MODULES;

const vLogger = logger({ name: 'AdLibVideo', bgColor: '#EDE7B1', textColor: '#685E47' }); // .atVerbosity(2)

/**
 * Ad Library Video Module
 *
 * This is the native video handling module for the ad library additional third party modules are intended to hook into this module
 *
 * @module Video
 * @private
 */
const videoModuleBase = (function vmb() {
	/**
	 * Module configuration
	 *
	 * @memberof Video
	 * @private
	 */
	let config = {};
	/**
	 * Object for tracking passed in video configurations
	 *
	 * @memberof Video
	 * @private
	 */
	const indexedSpecs = {};
	/**
	 * Default video parameters cached value
	 *
	 * @memberof Video
	 * @private
	 */
	let generalSpec;
	/**
	 * Internal tracking object of supported behaviors
	 *
	 * @memberof Video
	 * @private
	 */
	const behaviors = {
		canAutoplayMuted: false,
		canAutoplayUnmuted: false,
	};
	/**
	 * Gets the current runtime context object
	 *
	 * @memberof Video
	 * @private
	 * @returns {BidBarrel~VideoContext}
	 */
	function getContext() {
		return {
			dfpPathObj: getConfig('dfpPathObj'),
			canAutoplayMuted: behaviors.canAutoplayMuted,
			canAutoplayUnmuted: behaviors.canAutoplayUnmuted,
			isMobile,
		};
	}
	/**
	 * Method for getting the video configuration spec
	 *
	 * @memberof Video
	 * @private
	 * @returns {Object}
	 */
	function getSpec() {
		if (!generalSpec) {
			generalSpec = config.spec;
			if (config.getSpec) {
				const merger = createReduceMerger(config.getSpec);
				generalSpec = merger.processObject(generalSpec, { arguments: [CONSTANTS.VIDEO, getContext()] });
			}
			vLogger.logInfo('Spec: Default', generalSpec);
		}
		return generalSpec;
	}
	/**
	 * Handles registration of the module
	 *
	 * @memberof Video
	 * @private
	 */
	function register() {
		getConfig('video', (videoConfig) => {
			config = videoConfig;
			if (config.getUnitSpec) {
				config.getUnitSpecMerger = createReduceMerger(config.getUnitSpec);
			}
			if (config.translateSpec) {
				config.translateSpecMerger = createReduceMerger(config.translateSpec);
			}
			if (config.getDefaultParamOptions) {
				config.getDefaultParamOptionsMerger = createReduceMerger(config.getDefaultParamOptions);
			}
			generalSpec = undefined;
		});
	}
	/**
	 * Hook to remove video ads from define and display(DND, not dungeons & dragons, unfortunately)
	 *
	 * @param {Function} next Next function
	 * @param {BidBarrel~AdUnit[]} unitCollection Unit collection currently being processed
	 * @memberof Video
	 * @private
	 */
	function removeVideoUnitsDND(next, unitCollection) {
		next(unitCollection.filter((u) => !u.isVideo));
	}
	/**
	 * Hook to remove video ads from ad server request
	 *
	 * @param {Function} next Next function
	 * @param {BidBarrel~AdUnit[]} unitCollection Unit collection being processed
	 * @param {Function} resolve resolve function
	 * @memberof Video
	 * @private
	 */
	function removeVideoUnitsASQ(next, unitCollection, resolve) {
		next(
			unitCollection.filter((u) => !u.isVideo),
			resolve
		);
	}
	/**
	 * Hook to remove video ads from standard set targeting process
	 *
	 * @param {Function} next Next function
	 * @param {string} code unit code or element id
	 * @param {string} key targeting key
	 * @param {string} value targeting value
	 * @memberof Video
	 * @private
	 */
	function cancelTargetingHook(next, code, key, value) {
		if (!getUnits()[code].isVideo) {
			next(code, key, value);
		}
	}
	/**
	 * Performs additional translations to meet business needs
	 *
	 * @param {BidBarrel~VideoSpec} spec A full or partial video spec
	 * @param {BidBarrel~AdUnit} unitConfig The corrsponding ad unit
	 * @memberof Video
	 * @private
	 */
	function translateSpec(spec, unitConfig) {
		let result = spec;
		if (result && config.translateSpecMerger) {
			result = config.translateSpecMerger.processObject(result, { arguments: [unitConfig, CONSTANTS.VIDEO, getContext()] });
		}
		result = omit(result, ['_index']);
		return result;
	}
	/**
	 * Handles applying the video configuration to the ad unit config
	 *
	 * @param {Function} next
	 * @param {BidBarrel~AdUnit} unit unit config
	 * @param {string|BidBarrel~AdUnit|Array} designation
	 * @memberof Video
	 * @private
	 */
	function addVideoConfigHook(next, unit, designation) {
		let updatedUnit = unit;
    let updatedDesignation = designation;
    if (updatedUnit.isVideo) {
	    updatedUnit = cloneDeep(unit);
      // if designation is a string, set requestNumber to 1 eg "video-rectangle"
      // if designation is an array, and the second element is an array, set requestNumber to the second element of the second element eg ["video-rectangle", 1]
      // otherwise set requestNumber to the second element of the designation array eg ["video-rectangle", [1, 1]],
      let requestNumber = 1;

      if (Array.isArray(updatedDesignation)) {
        const [, secondElement] = updatedDesignation;
        if (Array.isArray(secondElement)) {
          [, requestNumber] = secondElement;
        } else {
          requestNumber = secondElement;
        }
      } else {
        updatedDesignation = [updatedDesignation, 1];
      }
			const objKey = `${updatedUnit.code}|${requestNumber}`;
			indexedSpecs[objKey] = defaultsDeep({}, getSpec(), {
				playerSize: getSizes(unit)[0],
				_index: requestNumber,
			});
			if (config.getUnitSpecMerger) {
				indexedSpecs[objKey] = config.getUnitSpecMerger.processObject(indexedSpecs[objKey], { arguments: [updatedUnit, CONSTANTS.VIDEO, getContext()] });
			}
			indexedSpecs[objKey] = defaultsDeep(get(updatedDesignation, '[3].video') || {}, updatedUnit.video, indexedSpecs[objKey]);
			// eslint-disable-next-line no-underscore-dangle
			updatedUnit.getVideoSpecIndex = () => (typeof indexedSpecs[objKey]._index!== 'undefined' ? indexedSpecs[objKey]._index : 1);
			updatedUnit.getLatestVideoSpec = () => translateSpec(indexedSpecs[objKey], updatedUnit);
			updatedUnit.getVideoSpec = (index = 1) => translateSpec(indexedSpecs[`${updatedUnit.code}|${index}`], updatedUnit);
			updatedUnit.videoSpecs = Array.isArray(updatedUnit.videoSpecs)
				? [...updatedUnit.videoSpecs, indexedSpecs[`${updatedUnit.code}|${requestNumber}`]]
				: [indexedSpecs[`${updatedUnit.code}|${updatedDesignation[1]}`]];
		}
		next(updatedUnit, updatedDesignation);
	}
	/**
	 * This method primarily filters video ads from display-only ad processes
	 *
	 * @memberof Video
	 * @private
	 */
	function setupHooks() {
		moduleManager.viaModule(GOOGLE_PUBLISHER_TAG, ({ setUnitTargeting, defineAndDisplay }) => {
			defineAndDisplay.before(removeVideoUnitsDND);
			setUnitTargeting.before(cancelTargetingHook);
		});
		adServerRequest.before(removeVideoUnitsASQ);
		defineUnit.before(addVideoConfigHook);
	}
	/**
	 * Initialize hook to setup module
	 *
	 * @memberof Video
	 * @private
	 */
	function initialize() {
		setupHooks();
		// if(getConfig("video.detectCapabilities")){
		// getAutoplayCapabilities().then(() => {
		//     vLogger.logInfo("Autoplay Capabilities", behaviors);
		// })
		// }
	}

	/**
	 * Promise based evaluation of whether or not the client system supports autoplay muted
	 *
	 * @memberof Video
	 * @private
	 */
	// function canAutoplayMuted(){
	//     behaviors.canAutoplayUnmuted = false;
	//     return new Promise((resolve, reject) => {
	//         if(!isMobile() && (isSafari() && !isAutoplayEligibleSafari())){
	//             behaviors.canAutoplayMuted = false;
	//         }
	//         const video = createVideo(true);
	//         const resolveWithDelete = (result) => {
	//             deleteVideo(video);
	//             behaviors.canAutoplayMuted = result;
	//             resolve();
	//         }
	//         video.load();
	//         const promise = video.play();
	//         promise
	//             .then(() => resolveWithDelete(true))
	//             .catch(() => resolveWithDelete(false))
	//     })
	// }
	/**
	 * Promise based evaluation of autoplay capabilities
	 *
	 * @memberof Video
	 * @private
	 */
	// function getAutoplayCapabilities(){
	//     return new Promise((resolve, reject) => {
	//         const video = createVideo(false);
	//         const resolveWithDelete = (result) => {
	//             deleteVideo(video);
	//             if(result){
	//                 behaviors = {
	//                     canAutoplayMuted: result.muted,
	//                     canAutoplayUnmuted: result.unmuted
	//                 }
	//             }
	//             resolve()
	//         }
	//         video.load();
	//         const promise = video.play();
	//         if(promise === undefined){
	//             if(isMobile()){
	//                 resolveWithDelete({
	//                     muted: isMobileAutoplayEligible(),
	//                     unmuted: false
	//                 })
	//             } else {
	//                 resolveWithDelete({
	//                     muted: true,
	//                     unmuted: true
	//                 })
	//             }
	//         } else {
	//             promise
	//                 .then(() => resolveWithDelete({muted: true, unmuted: true}))
	//                 .catch(() => {
	//                     return canAutoplayMuted()
	//                         .then(() => resolveWithDelete())
	//                 })
	//         }
	//     })
	// }
	/**
	 * Detects if mobile client system is autoplay eligible
	 *
	 * @memberof Video
	 * @private
	 */
	// function isMobileAutoplayEligible(){
	//     return isAutoplayEligibleAndroid() || isAutoplayEligibleIOS()
	// }
	/**
	 * Detects if android client system is autoplay eligible
	 *
	 * @memberof Video
	 * @private
	 */
	// function isAutoplayEligibleAndroid(){
	//     return isAndroid() && isChrome() && isAutoplayEligibleChrome();
	// }
	/**
	 * Detects if iOS client system is autoplay eligible
	 *
	 * @memberof Video
	 * @private
	 */
	// function isAutoplayEligibleIOS(){
	//     return isIOS()
	//         && getOsVersion().major >= 10
	//         && (
	//             isSafari()
	//             || (isChrome() && isAutoplayEligibleChrome())
	//         )
	// }
	/**
	 * Detects if iOS client system is early iOS 11
	 *
	 * @memberof Video
	 * @private
	 */
	// function isAutoplayEligibleSafari(){
	//     return systemInfo().satisfies({ safari: ">11.0.3" })
	// }
	/**
	 * Detects if client browser Chrome is autoplay eligible
	 *
	 * @memberof Video
	 * @private
	 */
	// function isAutoplayEligibleChrome(){
	//     return systemInfo().satisfies({ chrome: ">=54" });
	// }
	/**
	 * Deletes a video element from the page
	 *
	 * @param {HTMLVideoElement} el
	 * @memberof Video
	 * @private
	 */
	// function deleteVideo(el){
	//     el.pause();
	//     el.removeAttribute('src');
	//     dom().window.document.body.removeChild(el);
	// }
	/**
	 * Creates a video element on the page
	 *
	 * @param {Boolean} muted whether or not to initiate the video as muted
	 * @memberof Video
	 * @private
	 * @returns {HTMLVideoElement}
	 */
	// function createVideo(muted){
	//     const v = dom().window.document.createElement('video');

	//     if (muted) {
	//         v.muted = true;
	//         v.setAttribute('muted', '');
	//         v.setAttribute('playsinline', '');
	//     }

	//     v.volume = 0.005;
	//     v.style.width = '400px';
	//     v.style.height = '225px';
	//     v.style.position = 'absolute';
	//     v.style.left = '-1000px';
	//     v.style.top = '-1000px';

	//     v.src = 'https://vidtech.cbsinteractive.com/h5/blanks/uvp_blank.mp4';

	//     dom().window.document.body.appendChild(v);

	//     return v;

	// }

	/**
	 * Constructs the video ad call url
	 *
	 * @param {Object} providedParams object with query parameters to apply to the ad call
	 * @returns {String} URL for video ad call
	 * @memberof Video
	 * @private
	 */
	// function constructUrl(providedParams){
	//     const baseAdServerString = config.adServerUrl;
	//     return `${baseAdServerString}?${serializeObjToQp(providedParams)}`;
	// }
	/**
	 * Serializes object to two level query parameter string while encoding the nested level
	 *
	 * @param {Object} obj Object of key/value query parameters
	 * @param {boolean} [nested=false] Flag for determining if processing on a nested level
	 * @returns {String} string query parameters without the ?
	 */
	// function serializeObjToQp(obj, nested = false){
	//     let result = "";

	//     for (const key in obj) {
	//         if (Object.prototype.hasOwnProperty.call(obj, key)) {
	//             const value = obj[key];
	//             if(Array.isArray(value)){
	//                 result += `&${key}=${value.join(",")}`
	//             } else if(typeof value === "object" && !nested){
	//                 vLogger.logInfo("SERIALIZING", value);
	//                 const modifiedValue = dom().window.encodeURIComponent(serializeObjToQp(value, true))
	//                 result += `&${key}=${modifiedValue}`
	//             } else if(typeof value === "object" && nested) {
	//                 vLogger.logWarn("Cannot translate value that is nested more than 1 layer deep for video targeting.")
	//             } else if(typeof value === "string" || typeof value === "number"){
	//                 result += `&${key}=${value}`
	//             }
	//         }
	//     }
	//     return result
	// }

	/**
	 * Gets a single unit's query params for the video ad call excluding targeting values set in the allowlist
	 *
	 * @param {BidBarrel~AdUnit} unit Ad unit config
	 * @param {Number} index The ads index
	 * @param {BidBarrel~VideoUrlOptions} options Video ad url options
	 *
	 * @returns {Object} Video ad targeting object
	 */
	function getSingleVideoUnitParams(unit, index, options) {
		let videoAdTargeting = defaultsDeep(options.getParams(unit, index), options.params);

		if (options.targeting) {
			let pageTargeting = {};
			let unitTargeting = {};

			const targetingKeysToInclude = options.targeting.allowlist || [];
			const pickValues = targetingKeysToInclude && targetingKeysToInclude.length > 0;

			if (options.targeting.page) {
				const basePageTargeting = getPageTargeting();
				pageTargeting = pickValues ? pick(basePageTargeting, targetingKeysToInclude) : basePageTargeting;
			}

			if (options.targeting.unit) {
				unitTargeting = pickValues ? pick(unit.targeting, targetingKeysToInclude) : unit.targeting;
			}

			videoAdTargeting = defaultsDeep(
				{ cust_params: defaultsDeep(pageTargeting, unitTargeting) },
				videoAdTargeting
			);
		}

		vLogger.logInfo(`Retrieved Video Ad Targeting ${unit.code} ${index}`, videoAdTargeting, options);

		return videoAdTargeting;
	}
	/**
	 * Hookable function for getting a single unit's query params for the video ad call
	 *
	 * @param {BidBarrel~AdUnit} unit unit config
	 * @param {Number} index the ad index
	 * @param {BidBarrel~VideoUrlOptions} options video ad url options
	 * @method
	 * @memberof Video
	 * @private
	 */
	const getVideoUnitParams = hookedFn('sync', getSingleVideoUnitParams);

	/**
	 * Handles processing provided options and gives a defaulted object
	 *
	 * @param {BidBarrel~VideoUrlOptions} providedOptions Partial / empty VideoUrlOptions object
	 * @memberof Video
	 * @private
	 */
	function defaultedOptions(providedOptions, unitConfig) {
		let opts = defaultsDeep({}, providedOptions, config.defaultParamOptions || {});
		const configOptions = config.getDefaultParamOptionsMerger.processObject(opts, { arguments: [unitConfig, getContext()] });
		opts = defaultsDeep(opts, configOptions);
		vLogger.logInfo(`Options: Defaults Applied`, opts);
		return opts;
	}
	/**
	 * Evaluates and returns a set of video params and urls for a set of video ad unit designations
	 *
	 * @param {string[]|BidBarrel~AdUnit[]|Array[]} units set of unit designations
	 * @param {BidBarrel~VideoParameterOptions} options Partial / empty video parameter options object
	 * @return {Array<BidBarrel~VideoAd>} An array of results with contextual information along with resulting parameters.
	 * @memberof Video
	 * @exposed
	 */
	function getVideoAds(units, options) {
		const items = Array.isArray(units) ? units : [units];
		const unitCollection = getUnitCollection(items);
		vLogger.logInfo('Getting video ads for collection', unitCollection);
		const results = [];
		for (let index = 0; index < unitCollection.length; index += 1) {
			const unit = unitCollection[index];
			const opts = defaultedOptions(options, unit);
			if (unit.isVideo) {
				const parameters = getVideoUnitParams(unit, unit.getVideoSpecIndex(), opts);
				// const url = constructUrl(targeting);
				results.push({
					unitCode: unit.code,
					unit,
					index: unit.index,
					parameters,
					// url
				});
			}
		}
		vLogger.logInfo('Video Ads Generated:', results);
		return results;
	}

	/**
	 * Cleans up remnants of module
	 *
	 * @private
	 * @memberof Video
	 */
	function deregister() {
		moduleManager.viaModule(GOOGLE_PUBLISHER_TAG, ({ setUnitTargeting, defineAndDisplay }) => {
			defineAndDisplay.getHook({ hook: removeVideoUnitsDND }).remove();
			setUnitTargeting.getHook({ hook: cancelTargetingHook }).remove();
		});
		adServerRequest.getHook({ hook: removeVideoUnitsASQ }).remove();
		defineUnit.getHook({ hook: addVideoConfigHook }).remove();
	}

	exposureApi.expose({
		getVideoAds,
		VIDEO: CONSTANTS.VIDEO,
	});

	return {
		name: VIDEO,
		protected: true,
		register,
		initialize,
		getVideoUnitParams,
		// bidRequest,
		deregister,
	};
})();

const templateModule = moduleManager.register(videoModuleBase);
export default templateModule;
// OR if your module depends on another module
// export const templateModule = moduleManager.register(videoModuleBase, [ MY_DEPENDENCY_MODULE_NAME ]);

//  * @param {Boolean} [detectCapabilities=true] Flag for enabling the video module to automatically detect supported playback methods.
/**
 * General configuration for video ad handling in the ad library. This value should be declared under a top level `video` key in the [BidBarrel~Configuration](api/#BidBarrel~Configuration)
 *
 * @typedef BidBarrel~VideoConfig
 * @type Object
 *
 * @param {BidBarrel~VideoSpec} [spec] Full or partial static spec that apply to all videos on the site
 * @param {Function} [getSpec] Function ran only once per auction that returns a full or partial [BidBarrel~VideoSpec](api/#BidBarrel~VideoSpec)
 *
 * **Signature:** (existingSpec: [BidBarrel~VideoSpec](api/#BidBarrel~VideoSpec), VIDEO_CONSTANTS: Object, context: [BidBarrel~VideoContext](api/#BidBarrel~VideoContext)) => existingOrModifiedExistingSpec: [BidBarrel~VideoSpec](api/#BidBarrel~VideoSpec)
 * @param {Function} [getUnitSpec] Function ran for each unit once per auction that returns a full or partial [BidBarrel~VideoSpec](api/#BidBarrel~VideoSpec). Values are overridden by the unit designation.
 *
 * **Signature:** (existingSpec: [BidBarrel~VideoSpec](api/#BidBarrel~VideoSpec), VIDEO_CONSTANTS: Object, unitConfig: [BidBarrel~AdUnit](api/#BidBarrel~AdUnit), context: [BidBarrel~VideoContext](api/#BidBarrel~VideoContext)) => existingOrModifiedExistingSpec: [BidBarrel~VideoSpec](api/#BidBarrel~VideoSpec)
 * @param {Function} [translateSpec] Similar to `getUnitSpec` except the values are not overridden by the unit designation.
 *
 * **Signature:** (existingSpec: [BidBarrel~VideoSpec](api/#BidBarrel~VideoSpec), VIDEO_CONSTANTS: Object, unitConfig: [BidBarrel~AdUnit](api/#BidBarrel~AdUnit), context: [BidBarrel~VideoContext](api/#BidBarrel~VideoContext)) => existingOrModifiedExistingSpec: [BidBarrel~VideoSpec](api/#BidBarrel~VideoSpec)
 */

/**
 * Video Config Context object to allow translation of internal BidBarrel variables to the configuration methods themselves.
 *
 * @typedef BidBarrel~VideoContext
 * @type Object
 *
 * @param {Function} isMobile A function that will return `true` or `false` if the detected runtime is mobile
 * @param {Object} dfpPathObj An object representation of the dfp path.
 * @param {Boolean} canAutplayMuted value with the detected autoplay muted capabilities.
 * **Note:** `BidBarrel.config.video.detectCapabilities` must be `true` for this value to be accurate. It is `false` by default.
 * @param {Boolean} canAutplayUnmuted value with the detected autoplay unmuted capabilities.
 * **Note:** `BidBarrel.config.video.detectCapabilities` must be `true` for this value to be accurate. It is `false` by default.
 */
/**
 * OpenRTB ( Open Real-time Bidding ) Video Spec for translating video ad expectations to bidders.
 *
 * You can read more about OpenRTB {@link https://www.iab.com/wp-content/uploads/2016/03/OpenRTB-API-Specification-Version-2-5-FINAL.pdf|here}
 *
 * For more readable implementations the ad library exposes config values as constants via `BidBarrel.VIDEO`
 *
 * **Note:** Not all bidders adhere to all spec parameters but control of the video ultimately rests on the video player implementation. The ad library is already globally configured for expected technology usage based on network wide standards but allows the site to override these configuration values for their specific use case.
 *
 * @typedef BidBarrel~VideoSpec
 * @type Object
 *
 * @param {String[]} [mimes=['video/mp4', 'video/H264', 'video/webm']] An array of supported mime types. See list {@link https://www.iana.org/assignments/media-types/media-types.xhtml#video|here(Value is in "Template" column)}
 * @param {Number} [minduration=5] the minimum acceptable ad duration in seconds
 * @param {Number} [maxduration=30] the maximum acceptable ad duration in seconds
 * @param {Number[]} [protocols=[1, 2, 3, 4, 5, 6, 7, 8]] Array of supported protocols
 *
 * **Possible Values:**
 *
 * Constant variables are accessible via `BidBarrel.VIDEO.<Constant Path>`
 *
 * |Value|Constant Path|Description|
 * |---|---|---|
 * |`1`|`PROTOCOL.VAST1`|VAST 1.0|
 * |`2`|`PROTOCOL.VAST2`|VAST 2.0|
 * |`3`|`PROTOCOL.VAST3`|VAST 3.0|
 * |`4`|`PROTOCOL.VAST1_WRAPPER`|VAST 1.0 Wrapper|
 * |`5`|`PROTOCOL.VAST2_WRAPPER`|VAST 2.0 Wrapper|
 * |`6`|`PROTOCOL.VAST3_WRAPPER`|VAST 3.0 Wrapper|
 * |`7`|`PROTOCOL.VAST4`|VAST 4.0|
 * |`8`|`PROTOCOL.VAST4_WRAPPER`|VAST 4.0 Wrapper|
 * |`9`|`PROTOCOL.DAAST1`|DAAST 1.0|
 * |`10`|`PROTOCOL.DAAST1_WRAPPER`|DAAST 1.0 Wrapper|
 *
 * @param {Number[]} [api=[1, 2]] List of supported API frameworks for this impression. If an API is not explicitly listed, it is assumed not to be supported
 *
 * **Possible Values:**
 *
 * Constant variables are accessible via `BidBarrel.VIDEO.<Constant Path>`
 *
 * |Value|Constant Path|Description|
 * |---|---|---|
 * |`1`|`API.VPAID1`|VPAID 1.0|
 * |`2`|`API.VPAID2`|VPAID 2.0|
 * |`3`|`API.MRAID1`|MRAID-1|
 * |`4`|`API.ORMMA`|ORMMA|
 * |`5`|`API.MRAID2`|MRAID-2|
 * |`6`|`API.MRAID3`|MRAID-3|
 *
 * @param {Number} [w=640] the width of the video player in device independent pixels (DIPS)
 * @param {Number} [h=480] the height of the video player in device independent pixels (DIPS)
 * @param {Number} [startdelay=0] Indicates the start delay in seconds for pre-roll, mid-roll, or post-roll ad placements.
 *
 * **Possible Values:**
 *
 * Constant variables are accessible via `BidBarrel.VIDEO.<Constant Path>`
 *
 * |Value|Constant Variable/Path|Description|
 * |---|---|---|
 * |>0|N/A|Mid roll(value indicates start delay in seconds)|
 * |`0`|`START_DELAY.PREROLL`|Pre-roll|
 * |`-1`|`START_DELAY.MIDROLL`|Mid-roll|
 * |`-2`|`START_DELAY.POSTROLL`|Post-roll|
 *
 * @param {Number} [placement] Placement type for the ad impression
 *
 * **Possible Values:**
 *
 * Constant variables are accessible via `BidBarrel.VIDEO.<Constant Path>`
 *
 * |Value|Constant Path|Description|
 * |---|---|---|
 * |`1`|`PLACEMENT.STREAM`|In-stream, Played before, during or after the streaming video content that the consumer has requested (e.g., Pre-roll, Mid-roll, Post-roll).|
 * |`2`|`PLACEMENT.BANNER`|In-banner, Exists within a web banner that leverages the banner space to deliver a video experience as opposed to another static or rich media format. The format relies on the existence of display ad inventory on the page for its delivery|
 * |`3`|`PLACEMENT.ARTICLE`|In-article, Loads and plays dynamically between paragraphs of editorial content; existing as a standalone branded message.|
 * |`4`|`PLACEMENT.FEED`|In-feed, Found in content, social, or product feeds.|
 * |`5`|`PLACEMENT.INTERSTITIAL`|Interstitial/Slider/Floating, Covers the entire or a portion of screen area, but is always on screen while displayed (i.e. cannot be scrolled out of view). Note that a full-screen interstitial (e.g., in mobile) can be distinguished from a floating/slider unit by the imp.instl field.|
 *
 * @param {Number} [linearity=1] Indicates if the impression must be linear, nonlinear, etc. If none specified, assume all are allowed.
 *
 * **Possible Values:**
 *
 * Constant variables are accessible via `BidBarrel.VIDEO.<Constant Path>`
 *
 * |Value|Constant Path|Description|
 * |---|---|---|
 * |`1`|`LINEARITY.LINEAR_INSTREAM`|Linear / Instream|
 * |`2`|`LINEARITY.NONLINEAR_OVERLAY`|Nnon-linear / Overlay|
 *
 * @param {Number} [skip=0] Indicates if the player will allow the video to be skipped where `0` = No and `1` = Yes.
 * @param {Number} [skipmin] Videos of total duration greater than this number of seconds can be skippable; only applicable if the ad is skippable
 * @param {Number} [skipafter] Number of seconds a video must play before skipping is enabled; only applicable if the ad is skippable
 * @param {Number} [sequence='<unit.index>'] If multiple ad impressions are offered in the same bid request, the sequence number will allow for the coordinated delivery of multiple creatives. Ad Index is dynamically applied to this as the default.
 * @param {Number[]} [battr] Block creative attributes
 *
 * **Possible Values:**
 *
 * Constant variables are accessible via `BidBarrel.VIDEO.<Constant Path>`
 *
 * |Value|Constant Path|Description|
 * |---|---|---|
 * |`1`|`BLOCK_ATTRIBUTE.AUTOPLAY_AUDIO`|Audio ad(autoplay)|
 * |`2`|`BLOCK_ATTRIBUTE.MANUAL_AUDIO`|Audio ad(user initiated)|
 * |`3`|`BLOCK_ATTRIBUTE.AUTO_EXPAND`|Expandable(auto)|
 * |`4`|`BLOCK_ATTRIBUTE.CLICK_EXPAND`|Expandable(User initiated via click)|
 * |`5`|`BLOCK_ATTRIBUTE.ROLLOVER_EXPAND`|Expandable(User initiated via rollover)|
 * |`6`|`BLOCK_ATTRIBUTE.AUTOPLAY_INBANNER_VIDEO`|In-Banner Video Ad (Auto-Play)|
 * |`7`|`BLOCK_ATTRIBUTE.MANUAL_INBANNER_VIDEO`|In-Banner Video Ad (User initiated)|
 * |`8`|`BLOCK_ATTRIBUTE.POP`|Pop (e.g., Over, Under, or Upon Exit)|
 * |`9`|`BLOCK_ATTRIBUTE.SUGGESTIVE`|Provocative or Suggestive Imagery|
 * |`10`|`BLOCK_ATTRIBUTE.FLICKERING`|Shaky, Flashing, Flickering, Extreme Animation, Smileys|
 * |`11`|`BLOCK_ATTRIBUTE.SURVEYS`|Surveys|
 * |`12`|`BLOCK_ATTRIBUTE.TEXT_ONLY`|Text only|
 * |`13`|`BLOCK_ATTRIBUTE.USER_INTERACTIVE`|User interactive (e.g., Embedded Games)|
 * |`14`|`BLOCK_ATTRIBUTE.ALERT`|Windows Dialog or Alert Style|
 * |`15`|`BLOCK_ATTRIBUTE.AUDIO_ONOFF_UI`|Has Audio On/Off button|
 * |`16`|`BLOCK_ATTRIBUTE.PROVIDE_SKIP`|Ad Provides Skip Button (e.g. VPAID-rendered skip button on pre-roll video)|
 * |`17`|`BLOCK_ATTRIBUTE.FLASH`|Adobe Flash|
 *
 *
 * @param {Number} [minbitrate] Minimum bitrate in Kbps
 * @param {Number} [maxbitrate] Maximum bitrate in Kbps
 * @param {Number} [boxingallowed] Indicates if letter-boxing of 4:3 content into a 16:9 window is allowed, where `0` = No and `1` = Yes
 * @param {Number[]} [playbackmethod=[1,2]] Playback methods that may be in use. If none specified any method may be used.
 *
 * **NOTE:** In the future this may be converted to a single number rather than an array of numbers
 *
 * **Possible Values:**
 *
 * Constant variables are accessible via `BidBarrel.VIDEO.<Constant Path>`
 *
 * |Value|Constant Path|Description|
 * |---|---|---|
 * |`1`|`PLAYBACK_METHOD.PAGELOAD_SOUND_ON`|Initiates on Page Load with Sound On|
 * |`2`|`PLAYBACK_METHOD.PAGELOAD_SOUND_OFF`|Initiates on Page Load with Sound Off by default|
 * |`3`|`PLAYBACK_METHOD.CLICK_SOUND_ON`|Initiates on Click with Sound On|
 * |`4`|`PLAYBACK_METHOD.MOUSEOVER_SOUND_ON`|Initiates on Mouse-Over with Sound On|
 * |`5`|`PLAYBACK_METHOD.SCROLLIN_SOUND_ON`|Initiates on Entering Viewport with Sound On|
 * |`5`|`PLAYBACK_METHOD.SCROLLIN_SOUND_OFF`|Initiates on Entering Viewport with Sound Off by default|
 *
 *
 * @param {Number} [playbackend=1] The event that causes playback to end
 *
 * **Possible Values:**
 *
 * Constant variables are accessible via `BidBarrel.VIDEO.<Constant Path>`
 *
 * |Value|Constant Path|Description|
 * |---|---|---|
 * |`1`|`PLAYBACK_END.ON_COMPLETE`|On Video Completion or when Terminated by User|
 * |`2`|`PLAYBACK_END.ON_VP_LEAVE`|On Leaving Viewport or when Terminated by User|
 * |`3`|`PLAYBACK_END.ON_COMPLETE_WITH_FLOAT`|On Leaving Viewport Continues as a Floating/Slider Unit until Video Completion or when Terminated by User|
 *
 * @param {Number[]} [delivery] Supported delivery methods. If none specified all are assumed supported.
 *
 * **Possible Values:**
 *
 * Constant variables are accessible via `BidBarrel.VIDEO.<Constant Path>`
 *
 * |Value|Constant Path|Description|
 * |---|---|---|
 * |`1`|`DELIVERY.STREAMING`|Streaming|
 * |`2`|`DELIVERY.PROGRESSIVE`|Progressive|
 * |`3`|`DELIVERY.DOWNLOAD`|Download|
 *
 * @param {Number} [pos] Ad position on screen
 *
 * **Possible Values:**
 *
 * Constant variables are accessible via `BidBarrel.VIDEO.<Constant Path>`
 *
 * |Value|Constant Path|Description|
 * |---|---|---|
 * |`1`|`POS.UNKNOWN`|Unknown|
 * |`2`|`POS.ATF` or `POS.ABOVE_THE_FOLD`|Above the fold|
 * |`3`|`POS.BTF` or `POS.BELOW_THE_FOLD`|Below the fold|
 *
 *
 * Additional values are documented in the OpenRTB 2.5 spec but apply only to apps and at this time(April 2020) we do not support apps.
 *
 * @param {Object[]} [companionad] Array of banner objects if companion ads are available. Please refer to the OpenRTB 2.5 spec documentation if you would like to utilize this configuration option.
 * @param {Number[]} [companiontype] Supported VAST companion ad types. Recommended if companion Banner objects are included via the companionad array. If one of these banners will be rendered as an end-card, this can be specified using the vcm attribute with the particular banner. Please refer to the OpenRTB 2.5 spec documentation if you would like to utilize this configuration option.
 *
 * @param {Object} [ext] Placeholder for exchange-specific extensions to OpenRTB

 */

/**
 * These options assist with generating the BidBarrel~VideoAd results
 *
 * @typedef BidBarrel~VideoParameterOptions
 * @type Object
 *
 * @param {Object} [params={}] Object of parameters you would like reflected in the output of the video ad results. This will be applied to all designations you're requesting video ads for.
 * @param {Function} [getParams=(unitConfig, index) => ({})] A method that will be called for each unit that accepts the unit configuration and index that will override values in params.
 * @param {Object} [targeting={unit: false, page: false}] Config to determine whether or not you would like to apply the unit and/or page targeting to the returned parameters.
 * @param {Boolean} [targeting.unit=false] Flag to determine if unit targeting will be applied to the resulting parameters
 * @param {Boolean} [targeting.page=false] Flag to determine if page targeting will be applied to the resulting parameters
 * @param {Boolean} [targeting.allowlist=['session', 'subses', 'pv', 'cid', 'collection', 'tag', 'topic', 'ptopic', 'ptype', 'ctopic', 'entity', 'subcollection', 'useg', 'bsc', 'tvp', 'vlp', 'subsession', 'vid', 'mfr', 'ftag', 'contentType', 'vguid', 'device', 'abtest']] List of targeting keys to extract for the returned parameters. This list applies to both page and unit targeting keys.
 */

/**
 * The video ad object that provides all the needed values for constructing a video ad url along with providing some contextual information.
 *
 * @typedef BidBarrel~VideoAd
 * @type Object
 *
 * @param {BidBarrel~AdUnit} unit the unit configuration for this specific unit
 * @param {Number} index the index of the unit you're requesting video ads for
 * @param {Object} parameters an object representation of what parameters need to be applied to the ad url.
 */
