import isEmpty from './utilities/helpers/isEmpty.js';
import get from './utilities/helpers/get.js';

/**
 * underlying dom object accessed everywhere
 * @private
 */
// eslint-disable-next-line no-underscore-dangle, object-shorthand, no-undef
let _dom = typeof window === 'undefined' ? { window: global } : { window: window };

/**
 * Dom object to access for global variables
 *
 * @param {Object} overrideDom Overrides object to allow easy overrides to be applied when necessary(testing)
 * @returns global object
 * @private
 */
export function dom(overrideDom) {
	if (!isEmpty(overrideDom)) {
		_dom = { ..._dom, ...overrideDom };
	}
	return _dom;
}
/**
 * Gets the global BidBarrel object
 *
 * @returns BidBarrel object
 * @private
 */
export function getGlobal() {
	if (!get(dom(), 'window.BidBarrel')) {
		dom().window.BidBarrel = {};
		dom().window.BidBarrel.queue = [];
	}
	return dom().window.BidBarrel;
}
