import { getConfig } from '../../config.js';
import CONSTANTS from '../../constants.json';
import { eventEmitter } from '../../events.js';
import { dom } from '../../global.js';
import { moduleManager } from '../../moduleManager.js';
import { requestManager } from '../../requestManager.js';
import { cloneDeep } from '../../utilities/cloneDeep.js';
import { logger } from '../../utilities/logger.js';
import { mergeDeep } from '../../utilities/mergeDeep.js';
import { urlQueryAsObject } from '../../utilities/queryParams.js';
import { renderScript } from '../../utilities/renderScript.js';

const pubhubLogger = logger({ name: 'pubhub', textColor: '#FFF', bgColor: '#555500' });
const { PUBHUB } = CONSTANTS.MODULES;
const { PUBHUB_READY, PUBHUB_LOADED } = CONSTANTS.EVENTS;
/**
 * Pubmatic Identity Hub
 *
 * This module adds the Pubmatic Identity Hub script to the page
 * and registers the module with the requestManager module to ensure that the Pubmatic Identity Hub
 * targeting parameters are available before the ad server request is made.
 *
 * @memberof PUBHUB
 * @private
 */
const pubhubModuleBase = (function pubhubMod () {
	/**
	 * Configuration object
	 *
	 * @memberof PUBHUB
	 * @private
	 */
	let config = {};
  let initFired = false;

  /**
   * Set the Pubmatic Identity Hub Ids in the adunits
   * @param {*} adUnits
   * @returns adUnits with Pubmatic Identity Hub Ids
   */
  function setPubHubId(adUnits) {
      if(dom().window.IHPWT  && typeof dom().window.IHPWT.getUserIds === "function"){
        const userIds = dom().window.IHPWT.getUserIds();
        const userIdsAsEids = dom().window.ihowpbjs.getUserIdsAsEids();
        pubhubLogger.atVerbosity(3).logInfo('Pubmatic Identity Hub UserIds: ', userIds);
        if(typeof adUnits !== "undefined") {
          adUnits.forEach((adUnit)=> {
            if(typeof adUnit.bids !== "undefined" ) {
              adUnit.bids.forEach((bid)=> {
                // eslint-disable-next-line no-param-reassign
                bid.userId = mergeDeep(bid.userId, userIds);
                // eslint-disable-next-line no-param-reassign
                bid.userIdAsEids = mergeDeep(userIdsAsEids, bid.userIdAsEids || {});
                // pubhubLogger.logInfo(`bid for ${adUnit.code} after adding userid: `, bid);
              });
            }
          });
          eventEmitter.emit(PUBHUB_READY);
        }
        pubhubLogger.logInfo("Updated AdUnits: ", adUnits)
      } else {
        pubhubLogger.logInfo("Pubmatic Identity Hub values not yet available.");
      }
      return adUnits;
  }
  /**
     * Function called when Pubmatic Identity Hub script is loaded
     *
     */
  function pubhubOnload() {
    pubhubLogger.logInfo('Pubmatic Identity Hub (.jsLoaded) fired');
    requestManager.done('pubmaticHubId');
    eventEmitter.emit(PUBHUB_LOADED);
  }
	/**
	 * Registers the module
	 *
	 * @private
	 * @memberof PUBHUB
	 */
	function register() {
   getConfig('pubhub', (newConfig) => {
	 	config = newConfig;
	 });
	}
	/**
	 * Initializes module which loads the Pubmatic Identity Hub script
	 *
	 * @private
	 * @memberof PUBHUB
	 */
	function initialize() {
    if(config.enabled === true && initFired===false) {
      initFired = true;
      dom().window.IHPWT = dom().window.IHPWT || {};
      dom().window.IHPWT.jsLoaded = function ol (){
        pubhubOnload();
      };
      const query =  urlQueryAsObject(dom().window.location.href);

      const regexp = /(.*?)(&|$)/g;
      const matches = regexp.exec(query.pwtv);
      let profileVersionId = '';
      if(typeof query.pwtv !== "undefined" && matches.length >= 2 && matches[1].length > 0){
        profileVersionId = `/${matches[1]}`;
      }

      const pubhubsrc = `${config.scriptLoc}${profileVersionId}${config.scriptFile}`;

      renderScript({
        src: pubhubsrc,
        async: true,
        id: 'bb-pubhub',
      });
     requestManager.register('pubmaticHubId');
    } else if(config.enabled === false ) {
      pubhubLogger.logInfo('Pubmatic Identity Hub Module is disabled via config setting.');
    }
	}

  /**
     * Hook method for pbjs.requestBids
     *
     * @param {object} adUnits - the current auction ad units
     * @returns {function} next function with modified values
     * @memberof PUBHUB
     * @private
     */
  function bidRequest(adUnits) {
    let updatedAdUnits = cloneDeep(adUnits);
    if(config.enabled === true ) {
      updatedAdUnits = setPubHubId(updatedAdUnits);
    }
    return updatedAdUnits;
  }

	return {
		name: PUBHUB,
		register,
		initialize,
    bidRequest,
	};
})();

const pubhubModule = moduleManager.register(pubhubModuleBase, null, { gate: 'consentGiven' });
export default pubhubModule;
