module.exports = {
	blockthrough: {
		enabled: false,
	},
	dfpPath: {
		property: 'cnet',
	},
	dynamicBidders: {
    // eslint-disable-next-line no-unused-vars
    nobid: (existingBidderParams, unitConfig) => {
      if (!existingBidderParams.included) return null;
      return ({ siteId: '68362', ...existingBidderParams });
    },
		rubicon: (value, unitConfig, context) => {
			if (!value.included) return null;
			return {
				siteId: context.isMobile() ? '378836' : '378834',
				accountId: 23386,
			};
		},
	},
	geo: {
		enabled: true,
	},
  pubhub: {
    enabled: true,
  },
  intentIQ: {
    enabled: true,
    params: {
      domainName: "cnet.com",
    },
  },
	prebid: {
		enabledRegions: ['us', 'emea', 'apac'],
		ids: {
			// identityLink: {
			//   enabled: true,
			//   template: true,
			//   userSyncConfig: {
			//       params: {
			//           pid: "13332"
			//       }
			//   }
			// },
      liveIntent: {
        userSyncConfig: {
          params: {
            publisherId: '895',
          },
        },
      },
      unifiedId: {
        enabled: true,
      },
		},
		pbjsConfig: {
      floors: {
        enabled: true,
        data : {
          schema: {
            delimiter: '|',
            fields: [ 'topAdUnit', 'fold', "mediaType" ]
          },
          values: {
            '/22309610186/aw-cnet|atf|*': 2.00,
            '/22309610186/aw-cnet|*|*': 0.50,
            '/22309610186/maw-cnet|*|*': 1.00,
            '/22309610186/aw-cnet|*|video': 1.00,
            '/22309610186/maw-cnet|*|video': 1.00,
            '/22309610186/vaw-cnet|*|*': 1.00,
            '*|*|video': 1.00,
          },
        },
      },
			ortb2: {
				site: {
					cat: ['IAB19', 'IAB22', 'IAB13'],
          cattax: 1,
				},
				user: {},
			},
		},
	},
	targeting: {
		seats: {
			session: 4,
			subsession: 6,
		},
	},
	timeouts: {
		bidder: 1000,
	},

	// Note that CNET uses a function which is setup on site-side to generate the incremenatal names
  // ,
	// generateIncrementalUnitCode: (templateCode, increment, template) => {
	//     if(template && template.isVideo){
	//         return templateCode
	//     }
	//     return templateCode + (1 < increment ? "-" + increment : '');
	// }
};
