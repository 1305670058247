/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import CONSTANTS from './constants.json';
import { eventEmitter } from './events.js';
import { exposureApi } from './exposureApi.js';

import { concatConfigArrays } from './utilities/concatConfigArrays.js';
import intersection from './utilities/helpers/intersection.js';
import isEmpty from './utilities/helpers/isEmpty.js';
import omit from './utilities/helpers/omit.js';
import { hookedFn } from './utilities/hookedFunction.js';
import { bbLogger, turnOnDebug, setDefaultConfig } from './utilities/logger.js';
import { richObject } from './utilities/richObject.js';

// import { getGlobal } from './global';

// const $$BB_VAR$$ = getGlobal();

const DEFAULT_SETUP_OPTIONS = { versionControl: false };

const { ERROR_REPORT } = CONSTANTS.EVENTS;

/**
 * Rich object wrapper for the global configuration.
 *
 * @param {Object} instanceConfig initial value
 * @param {Object} setupOptions richObject options
 * @module config
 * @private
 */
export const config = (function cfg(instanceConfig = {}, setupOptions = DEFAULT_SETUP_OPTIONS) {
	/**
	 * A rich object construct holding the configuration
	 *
	 * @type {BidBarrel~Configuration}
	 * @private
	 * @memberof config
	 * @exposed
	 * @exposedAs config
	 */
	const _config = richObject(instanceConfig, setupOptions);
	/**
	 * Function to allow setting the WHOLE value of the configuration to another object
	 *
	 * @param {Object} value Partial object value to update with
	 * @returns {Object} the configurations value after updating
	 * @private
	 * @memberof config
	 */
	function setObjConfig(value) {
		value = concatConfigArrays(value, _config.getValue());
		_config.setValue(value);
		return _config.getValue();
	}
	/**
	 * Validation method to restrict config updates for certain keys
	 *
	 * @param {String[]} keys Array of keys attempting to be updated
	 * @param {Object} value full or partial config object
	 * @param {Boolean} apply flag to indicate whether or not to filter out the restricted keys
	 * @private
	 * @memberof config
	 */
	function validateKeys(keys, value, apply = true) {
		const restrictedConfigProperties = intersection(CONSTANTS.CONFIG_RESTRICTED_KEYS, keys);
		let result = true;
		if (restrictedConfigProperties.length !== 0) {
			bbLogger.atVerbosity(2).logWarn('DEPRECATED: The following keys you provided are not available via implementation in BidBarrel.', restrictedConfigProperties);
			bbLogger.atVerbosity(2).logWarn('Please make the appropriate changes to your site config in the BidBarrel repo(via PR). The full list of config restrictions:', CONSTANTS.CONFIG_RESTRICTED_KEYS);
			result = false;
		}
		if (apply) {
			value = omit(value, CONSTANTS.CONFIG_RESTRICTED_KEYS);
			return value;
		}
		return result;
	}
	/**
	 * Hookable set config function. Unlike the setConfigPublic method this method does not get validated prior to setting the config value
	 *
	 * @param {String|Object} keyPath The key path or object for the configuration update
	 * @param {*} value If a string key path is specified this would contain the value for the value at the end of the key path
	 * @returns {BidBarrel~Configration} Updated config object
	 * @memberof config
	 * @private
	 */
	const setConfig = hookedFn('sync', (keyPath, value) => {
		if (typeof keyPath === 'object') {
			return setObjConfig(keyPath);
		}
		if (keyPath === '*') {
			return setObjConfig(value);
		}

		const revision = _config.setValue(keyPath, value);
		const returnValue = _config.getValue();

		if (_config.options.versionControl) {
			returnValue._revision = revision;
		}

		return returnValue;
	});
	/**
	 * Public method for setting the configuration.
	 *
	 *
	 * @param {String|Object} keyPath The key path or object for the configuration update
	 * @param {*} value If a string key path is specified this would contain the value for the value at the end of the key path
	 * @returns {BidBarrel~Configration} Updated config object
	 * @memberof config
	 * @private
	 * @method
	 * @exposed
	 * @exposedAs setConfig
	 */
	function setConfigPublic(keyPath, value) {
		if (typeof keyPath === 'object') {
			if (isEmpty(keyPath)) {
				bbLogger.logError('Attempted to set config with empty object');
				const errorObj = new Error(`Attempted to set config with empty object.`);
				eventEmitter.emit(ERROR_REPORT, errorObj);
				return;
			}
			keyPath = validateKeys(Object.keys(keyPath), keyPath);
		} else if (keyPath === '*') {
			if (isEmpty(value)) {
				bbLogger.logError('Attempted to set config with empty object');
				const errorObj = new Error(`Attempted to set config with empty object.`);
				eventEmitter.emit(ERROR_REPORT, errorObj);
				return;
			}
			value = validateKeys(Object.keys(value), value);
		} else if (!validateKeys([keyPath.split('.')[0]], value, false)) {
			return;
		}
		// eslint-disable-next-line consistent-return
		return setConfig(keyPath, value);
	}

	/**
	 * Function getter for the config object
	 *
	 * @param {String|null} [keyPath=null] Similar to lodash's _.get(obj, keyPath) the key path is a path to the key in the config object. Leave empty for the full config object
	 * @param {Function} [subscriptionCallback=null] Subscription callback. When specified this callback takes a single argument; the value of the specified key path. This changes the value returned to an unsubscribe callback
	 * @returns {*} If a subscription callback was specified the return value will be an unsubscribe callback otherwise the return value will be the value in the configuration for the key path or the full config object
	 *
	 * @memberof config
	 * @exposed
	 * @example
	 *

	BidBarrel.setConfig({testValue: {nested: "my test value"}});
	console.log(BidBarrel.getConfig());
	// { testValue: { nested: "my test value" } }

	BidBarrel.getConfig("testValue.nested") // returns "my test value"
	BidBarrel.getConfig("testValue.doubleNested") // returns undefined
	const unsubscribe = BidBarrel.getConfig("testValue.nested", (nestedValue) => {
		myLocalVar = nestedValue;
		console.log("Set value", nestedValue);
	});
	// The subscriber is immediately invoked if the value is defined
	// "Set value my test value"

	// Subsequent value updates are relayed to the subscriber
	BidBarrel.setConfig("testValue.nested", "some other value");
	// "Set value some other value"

	// Calling unsubscribe will cancel the subscription
	unsubscribe();
	BidBarrel.setConfig("testValue.nested", "a different value");

	// Subscribing to a value that is not defined at first will result in the subscriber not being called immediately
	BidBarrel.getConfig("testValue.doubleNested", (doubleNestedValue) => {
		console.log("Set value", doubleNestedValue)
	})
	 */
	function getConfig(keyPath = null, subscriptionCallback = null) {
		return _config.getValue(keyPath, subscriptionCallback);
	}

	if (_config.options.versionControl) {
		exposureApi.expose({
			configVersioning: {
				undo: _config.undo,
				revertTo: _config.revertTo,
				getVersion: _config.getVersion,
				getValueAtVersion: _config.getValueAtVersion,
			},
		});
	}

	return {
		setConfig,
		setConfigPublic,
		getConfig,
		..._config,
	};
})();

export const { getConfig } = config;
export const { setConfig } = config;
export const { setConfigPublic } = config;

exposureApi.rootScope({
	getConfig,
	setConfig,
});

exposureApi.rootScopeGetters({
	config: () => getConfig(),
});

getConfig('debug', (debugConfig) => {
	if (typeof debugConfig === 'boolean' && debugConfig) {
		turnOnDebug();
	} else {
		setDefaultConfig(debugConfig);
		bbLogger.setLoggerConfig(debugConfig);
	}
});

/**
 * Bid Barrel Runtime configuration object that defines how ad
 * optimizations operate on runtime. Full example below.
 *
 * Bid Barrel uses a cascade of configs each of which override the previous
 * <ul>
 * <li>The `pubConfig` passed into {@link #BidBarrel#initialize|BidBarrel.initialize} overrides {@link https://github.com/cbsi-cmg/ads-adlibrary/tree/develop/configs|Site Configs}</li>
 * <li>{@link https://github.com/cbsi-cmg/ads-adlibrary/tree/develop/configs|Site Configs} override the {@linkhttps://github.com/cbsi-cmg/ads-adlibrary/blob/develop/configs/global-config.js|Global Config}</li>
 * </ul>
 *
 * <b>NOTE:</b> Site `config.js` is located under your specific site directory {@link https://github.com/cbsi-cmg/ads-adlibrary/tree/develop/configs|here}.
 * and Publisher Devs can submit PRs to change their specific site's config, core index bundle file, and/or build config.
 *
 * For changes to to your units please contact your AdPM.
 *
 * This is very configurable but, you're only required to define very few
 * options such as the dfpPath and pageTargeting to get up and running
 *
 * @typedef {Object} BidBarrel~Configuration
 *
 * @param {String|Object} dfpPath This is the adserver path used in
 * googletag.defineSlot as well as when defining amazon ad
 * slots. {@link guides/getting-started/#the-dfp-path-property|Read more}
 *
 * Object version described below.
 * @param {String|Number} [dfpPath.network=22309610186] This value is the ad
 * network for google tag to register slots for and make requests for.
 * The default value of this will change to 7336 is a pageTargeting
 * value for  env is passed in with a value of "stage", "staging", or
 * "dev"
 * @param {String} [dfpPath.device=""] This value identifies the device
 * type leave empty for desktop web, for mobile web "m" should be
 * specified, for applications "app" should be specified. Also accepted:
 * desktop, application, mobile, web("desktop"), mweb("mobile")
 * @param {String} [dfpPath.region="aw"] The region for the current client
 * side user.
 * @param {String} [dfpPath.property] The string designation for the
 * site that is currently running BidBarrel. The BidBarrel team
 * will generally set this value in your site's BidBarrel config
 * so you probably wont have to specify this value
 * @param {String} [dfpPath.pagePath] The rest of the value that
 * comes after the property value in your dfp path designation.
 * For a dfp path of "/22309610186/aw-cnet/entity/news" it would
 * be "entity/news"
 * @param {Boolean} [dfpPath.isMobile] Flag alternative to describe
 * a mobile device type. results in a dfpPath.device value of "m"
 * @param {Boolean} [dfpPath.isApp] Flag alternative to describe an
 * application device type results in dfpPath.device value of "app".
 * @param {Object} [dfpPathDevices] Object for device definitions and their mapped alias.
 *
 * **Default Value:**
 *
 * ```javascript
 * {
		desktop: '',
		web: '',
		mweb: 'm',
		mobile: 'm',
		app: 'app',
		m: 'm',
		application: 'app',
	}
 * ```
 *
 * This object is used to identify what string to use for the device portion of the dfpPath
 *
 * In `/22309610186/maw-cnet/home` the device portion of the dfpPath is `m` in `maw`
 *
 * When specifying `mobile` for `config.dfpPath.device` BidBarrel will automatically translate that to `m` due to the definitions in `config.dfpPathDevices`
 *
 * @param {Object} [bootstrap] Configuration for bootstraping the page. The bootstrapping of a page occurs in {@link api/#BidBarrel#initialize|BidBarrel.initialize}.
 * @param {BidBarrel~RenderScriptConfig[]} [bootstrap.renderScripts] A collection of script render configurations to allow for third party script dependencies at BidBarrel.initialize
 * @param {Object} [cookie] Object for configurations for cookies used by the AdLibrary
 * * ```javascript
 *  cookie: {
 *        defaultOptions: {
 *            path: "/"
 *        },
 *        dailySession: {
 *            cookieName: "_BB.d",
 *            defaultOptions: {
 *                expires: 'midnightEst'
 *            },
 *            deserialize: {
 *                match: val => val && val.indexOf("|") >= 0,
 *                process: val => {
 *                    if(!val) return {firstpg: undefined, ftag: undefined, ttag: undefined, pv: undefined};
 *                    const [firstpg, ftag, ttag, pv] = val.split("|");
 *                    // eslint-disable-next-line no-self-compare
 *                    const adjustedPv = parseInt(pv) === parseInt(pv) ? parseInt(pv) : pv;
 *                    return {firstpg, ftag, ttag, pv: adjustedPv};
 *                }
 *            },
 *            serialize: {
 *                match: val => !!val,
 *                process: ({firstpg, ftag, ttag, pv}) => [firstpg, ftag, ttag, pv].join("|").replace(/undefined/gm, "")
 *            }
 *        },
 *        browserSession: {
 *            cookieName: "_BB.bs",
 *            deserialize: {
 *                match: val => val && val.indexOf("|") >= 0,
 *                process: val => {
 *                    if(!val) return {session: undefined, subses: undefined};
 *                    const [session, subses] = val.split("|");
 *                    return {session, subses};
 *                }
 *            },
 *            serialize: {
 *                match: val => !!val,
 *                process: ({session, subses}) => [session, subses].join("|").replace(/undefined/gm, "")
 *            }
 *        },
 *        enrichment: {
 *            cookieName: "_BB.enr",
 *            defaultOptions: {
 *                expires: 'midnightEst'
 *            },
 *        },
 *        gaClientId: {
 *            cookieName: "_ga",
 *            readOnly: true
 *        },
 *        aamUuid: {
 *            cookieName: "aam_uuid",
 *            readOnly: true
 *        },
 *        seg: {
 *            cookieName: "aamgam",
 *            readOnly: true,
 *            deserialize: {
 *                match: val => !!val,
 *                process: val => decodeURI(val).replace("segid=", "").split(",")
 *            },
 *            serialize: {
 *                match: val => !!val,
 *                process: val => encodeURI(`segid=${val.join(",")}`)
 *            }
 *        }
 *    },
 * ```
 * @param {Object} [debug] Object for configurations for debug
 * @param {Object} [debug.consoleMethods] Object used to define how
 * @param {Boolean} [debug.consoleMethods.error=true] Boolean flag to turn on error debugs
 * @param {Boolean} [debug.consoleMethhds.warn=true] Boolean flag to turn on warning debugs
 * @param {Object} [adjustSlotDefinition] Allows for adjustment of slot
 * definitions such that you can pass different sizes to prebid and gpt.
 * The keys for this object will be the unit code they are inteded to
 * modify.
 *
 * This is a {@link faq/#what-is-a-static-config-property-|Static Config Property}
 * @param {Object} [autoPriority] Configuration for auto priority module which automatically sorts units in order of closest to the active viewport. The sort order applies to bid evaluations and refresh orders. These config options are only available it the `autoPriority` module is enabled for your bundle.
 * **Important:** Prioritization and Lazy Loading can only work correctly if the ad div is on the page and does **NOT** have a style of `display:none;`
 * @param {Boolean} [autoPriority.enabled=true] Flag for enabling/disabling auto prioritization of ad units
 * @param {Boolean} [autoPriority.refresh=true] Flag for enabling/disabling auto prioritization for refresh orders
 * @param {Boolean} [autoPriority.bids=true] Flag for enabling/disabling auto prioritization for bid evaluation orders
 * @param {Boolean} [collapseEmptyDivs] config option to pass to googletag.pubads().collapseEmptyDivs. By default this is undefined and doesn't invoke the functionality at all.
 * @param {Object} [dynamicBidding] Object for configuration of dynamicBidding.
 * Dynamic bidding is used to programmatically adds targeting parameters to calls to header bidders.
 * @param {Object} [dynamicTargeting] Object for configuration of dynamicTargeting.
 *  Dynamic targeting is used to programmatically add targeting parameters to the ad call.  Examples: Adobie Segment Id, Impression ID, Sl value
 * @param {Function} [generateIncrementalUnitCode=(templateCode,increment) => "" + templateCode + increment] function to generate incremental unit codes
 * @param {Boolean} [highFrequencyAdRequests=false] Set this flag to `true` when your implementation has a high frequency of ad requests
 * @param {Object} [lazyLoading] This object allows the configuring of ad lazyloading contolled by AdLib
 * **Important:** Prioritization and Lazy Loading can only work correctly if the ad div is on the page and does **NOT** have a style of `display:none;`
 * @param {Object} [lazyLoading.offset] (Deprecated in favor of `lazyLoading.units.offset`) Defines lazy loading pixel offsets
 * @param {Number} [lazyLoading.offset.yAxis=400] (Deprecated in favor of `lazyLoading.units.offset.yAxis`) Defines the Y-Axis pixel
 * offset from the ad div to start evaluating percentage visible
 * @param {Number} [lazyLoading.offset.xAxis=800] (Deprecated in favor of `lazyLoading.units.offset.xAxis`) Defines the X-Axis pixel
 * offset from the ad div to start evaluating percentage visible
 * @param {Number} [lazyLoading.percentageVisible=1] (Deprecated in favor of `lazyLoading.units.percentageVisible`) Percentage of ad unit
 * visible within bounding box that once reached will trigger an ad refresh
 *
 * @param {Object} [lazyLoading.auction] Configuration that applies to lazy loading/chunking auctions
 * @param {Boolean} [lazyLoading.auction.enabled=false] Enables lazy loading and chunking for the auction process. If `false` all other properties of `lazyLoading.auction` are disregarded.
 * @param {Boolean} [lazyLoading.auction.chunk=true] Enables chunking of units for each auction
 * @param {Number} [lazyLoading.auction.chunkSize=15] Sets the chunk size for auction chunking
 * @param {Boolean} [lazyLoading.auction.lazy=true] Enables lazy loading for the auction chunks ( requires `lazyLoading.auction.chunk` to be `true` to have any effect )
 * @param {Object} [lazyLoading.auction.offset] Defines lazy loading pixel offsets for the first ad unit in a chunk of auctionable units
 * @param {Number} [lazyLoading.auction.offset.yAxis=1000] Defines the Y-Axis pixel offset from the first ad unit in a chunk of auctionable units to start evaluating percentage visible
 * @param {Number} [lazyLoading.auction.offset.xAxis=1600] Defines the X-Axis pixel offset from the first ad unit in a chunk of auctionable units to start evaluating percentage visible
 * @param {Number} [lazyLoading.auction.percentageVisible=1] Percentage of the first ad unit in a chunk of auctionable units visible within bounding box that once reached will trigger an auction on that chunk of units

 * @param {Object} [lazyLoading.units] This object controls various options around lazy loading ad units
 * lazy loading <b>NOTE:</b> This only applies to ad units with the
 * `cache` or `lazyLoad` property set to true.
 * **Important:** Prioritization and Lazy Loading can only work correctly if the ad div is on the page and does **NOT** have a style of `display:none;`
 * @param {Object} [lazyLoading.units.offset] Defines lazy loading pixel offsets
 * @param {Number} [lazyLoading.units.offset.yAxis=400] Defines the Y-Axis pixel offset from the ad div to start evaluating percentage visible
 * @param {Number} [lazyLoading.units.offset.xAxis=800] Defines the X-Axis pixel offset from the ad div to start evaluating percentage visible
 * @param {Number} [lazyLoading.units.percentageVisible=1] Percentage of ad unit visible within bounding box that once reached will trigger an ad refresh
 *
 * @param {Object} [oncreate] Configuration for once BidBarrel is on the page
 *
 * This is a {@link faq/#what-is-a-static-config-property-|Static Config Property}
 * @param {BidBarrel~RenderScriptConfig[]} [oncreate.renderScripts] A collection of script render configurations to allow for third party script dependencies once BidBarrel is on the page
 * @param {Array} [priceBuckets] Defines the CPM Targeting value strategy
 * to send to DFP. Note the `buckets` property {@link http://prebid.org/dev-docs/publisher-api-reference.html#custom-cpm-bucket-sizing|here}
 *
 * This is a {@link faq/#what-is-a-static-config-property-|Static Config Property}
 * Default value is in full example below.
 * @param {String} [priorityAxis='y'] Allows the setting of the priority axis for ad divs. The default 'y' should be used for all vertical layouts and 'x' when on a page that is a horizontal layout. If it's a mix please use the option that matches the most prominent content on the page.
 * **Important:** Prioritization and Lazy Loading can only work correctly if the ad div is on the page and does **NOT** have a style of `display:none;`
 * @param {String[]|Boolean} [refreshOrder=false] Refresh orders can be specified and set on bundle build if you provide an array of unit codes in the
 * order you would like them called. Otherwise this setting is turned off and refresh order occurs in the order the codes are provided to the auction or
 * refresh functions
 *
 * This is a {@link faq/#what-is-a-static-config-property-|Static Config Property}
 * @param {Object} [timeouts] Timeout configuration for failsafe and
 * bidder request duration.
 *
 * This is a {@link faq/#what-is-a-static-config-property-|Static Config Property}
 * @param {Number} [timeouts.failsafe=3000] Timeout to bypass the
 * header bidding process whether due to and error or otherwise and
 * ask DFP to refresh the current ad set
 * @param {Number} [timeouts.hfar=700] Timeout used when the high frequency ad requests(HFAR) flag is set to true
 * @param {Number} [timeouts.bidder=1000] Timeout placed on individual
 * bidders during the header bidding process. <b>NOTE:</b>This timeout is
 * also applied to the amazon adapter
 * @param {Object} [targeting] Handles configurations around targeting in BidBarrel
 * @param {Object} [targeting.auto=false] This flag enables autotargeting where BidBarrel
 * handles extraction of vguid, ttag, ftag, fistpg, session, and subsession. All values
 * are overriden by pageTargeting and targeting pulled from the query string
 * The logic is as follows:
 *
 * - firstpg: extracted from cookie(key configurable)
 *
 * - session/subsession/surround: extracted from cookie(keys configurable)
 *
 * - ftag/ttag: extracted from cookie(key configurable)
 *
 * Additionally autotargeting handles writing cookies if you did not pass the targeting
 * in via pageTargeting. In regards to writing cookies there are a number of configuration
 * options described in the documentation following.
 * @param {Object} [targeting.cookie] Configuration for how BidBarrel handles cookie read
 * and write operations
 * @param {Object} [targeting.cookie.samesite="None"] Cookie value for the SameSite attribute. When set to "None" the string "Secure" will be automatically appended. Additionally when the SameSite value is 'None' the ad library will perform a {@link https://www.chromium.org/updates/same-site/incompatible-clients|compatibility check}. If the browser does not support 'SameSite=None' then the value will be changed and applied as 'SameSite=Lax'.
 *
 * Read more about SameSite {@link https://web.dev/samesite-cookies-explained/|here}.
 * @param {Boolean} [targeting.cookie.consolidate=false] Flag to tell BidBarrel to consolidate
 * cookie values into two general BidBarrel cookies <code>_BB.d</code> for daily expired cookies
 * ((firstpg no longer used), ttag, ftag) and <code>_BB.bs</code> for browser session expired cookies(session, subsession)
 * @param {String} [targeting.cookie.domain=null] The domain used when setting cookies.
 * defaults to null which results in the domain not being set by BidBarrel which in turn
 * invokes the default functionality described {@link https://developer.mozilla.org/en-US/docs/Web/API/Document/cookie|here}
 * for the domain portion of the cookie.
 * @param {Function|any} [targeting.cookie.expiration=true] This option will determine how
 * BidBarrel handles setting the daily expirations. If set to a function this function will be
 * called when BidBarrel needs to generate an expiration. The return value of this function
 * can be a GMT String or a JavaScript Date class. If set to any other value except a function
 * BidBarrel will generate a date time object that expires at 11:45 PM EDT/EST.
 *
 * Note: If a function is defined it will recieve arguments in this order:
 * cookieKey, cookieValue, cookieExpiration, cookiePath, cookieDomain
 * @param {String} [targeting.cookie.path="/"] The default path used when setting cookies
 * @param {Object} [targeting.cookie.setters={}] Object where cookie keys are used as the keys
 * and the values are functions for setting those cookies. This method will be used instead
 * of BidBarrel's method for setting cookies
 *
 * Note: Functions will recieve arguments in this order:
 * cookieKey, cookieValue, cookieExpiration, cookiePath, cookieDomain
 *
 * @param {Object} [targeting.cookie.getters={}] Object where cookie keys are used as the keys
 * and the values are functions for getting those cookies. This method will be used instead
 * of BidBarrel's method for getting cookies
 *
 * Note: Functions will recieve arguments in this order:
 * cookieKey, cookieValue, cookieExpiration, cookiePath, cookieDomain
 * @param {Object} [targeting.cookie.deleters={}] Object where cookie keys are used as the keys
 * and the values are functions for deleting those cookies. This method will be used instead
 * of BidBarrel's method for deleting cookies
 *
 * Note: Functions will recieve arguments in this order:
 * cookieKey, cookieValue, cookieExpiration, cookiePath, cookieDomain
 * @param {Object} [targeting.cookie.keyMap] A key mapping between targeting values and cookies.
 * where the key is the targeting key and the value is the cookie key
 * @param {Object} [targeting.query] Config object to define targeting via query params.
 * @param {Object} [targeting.query.keyMap] key/value mapping where the
 * key is the key Bid Barrel looks for in the query and the value is the
 * targeting key Bid Barrel uses when applying the query params value to
 * to targeting on the ad slots. Default value below.
 * @param {Object} [targeting.seats] Session and subsession seat configuration
 * @param {Number} [targeting.seats.session=6] The amount of seats for session values.
 * This is subset of values to pull from this string `abcdefghijklmnopqrstuvwxyz` then pick a random value from those values.
 *
 * example: Using the value 6, the session value will be randomly picked from `abcdef`
 * @param {Number} [targeting.seats.subsession=4] The max range amount to pull from for subsession targeting
 *
 * @param {Array} [video.defaultParamOptions.targeting.allowlist=['session', 'subses', 'pv', 'cid', 'collection', 'tag', 'topic', 'ptopic', 'ptype', 'ctopic', 'entity', 'subcollection', 'useg', 'bsc', 'tvp', 'vlp', 'subsession', 'vid', 'mfr', 'ftag', 'contentType', 'vguid', 'device', 'abtest']]
 * An array of targeting keys to include from the page and unit targeting that are passed to the video player for ad targeting
 *
 * example: Using the value 4, the subsession value will be randomly picked from `1234`
 * @param {Object} [pageTargeting] Defines the page level targeting
 *
 * NOTE: Certain properties, if specified in this object, will be
 * picked up by BidBarrel. If the vguid is
 * specified it will be used instead. BidBarrel.vguid will have
 * the vguid that was used for targeting. Additionally the "env"
 * targeting value will adjust the network id for the dfp path
 * but will only be used for new slots defined moving forward.
 * @param {Object} [siteTargeting] Alias for pageTargeting
 * @param {Object} [queryTargeting] DEPRECATED: Please use <code>targeting.query</code>. Config object to define targeting via query params.
 * @param {Object} [queryTargeting.keyMap] DEPRECATED: Please use
 * <code>targeting.query.keyMap</code> instead. key/value mapping where the
 * key is the key Bid Barrel looks for in the query and the value is the
 * targeting key Bid Barrel uses when applying the query params value to
 * to targeting on the ad slots. Default value below.
 * @param {String} [queryTargeting.dynamicPrefix="adTargeting_"] DEPRECATED:
 * Please use targeting.query.dynamicPrefix instead. Query
 * param prefix that Bid Barrel looks for when applying dynamic query
 * targeting values.
 * @example
 * // Query targeting:
 * // https://comicbook.com/?adSession=b&adTargeting_someTestKey=some-test-value
 * // will result in(given the default config given after this example)
 * googletag.pubads().setTargeting("session","b");
 * googletag.pubads().setTargeting("someTestKey", "some-test-value");
 *
 * @param {Object} [adrefresh] Top level config object for intelligent ad refresh
 * @param {Boolean} [adrefresh.enabled=true] Whether or not the enable ad refresh feature
 * @param {Number} [adrefresh.minDispTimeDFP=20000] Number of ms minimum time to wait for refresh since the ad was loaded in the page (viewed or not)
 * @param {Number} [adrefresh.minDispTimeADX=30000] Number of ms minimum time to wait for refresh since the ad was loaded in the page (viewed or not) for ads from AdX
 * @param {Number} [adrefresh.minSinceActive=4000] Number of ms minimum time to wait for refresh after the user has interactive with an ad
 * @param {Number} [adrefresh.maxInactive=90000] Number of ms amount of time to detect if there has been no user activity (scroll) to stop adrefresh
 * @param {Number} [adrefresh.minViewArea=50] Minimum percentage of an ad that must be inview in order for the ad to be considered for refresh
 * @param {Boolean} [adrefresh.respectRoadblocks=true] If true, and roadblocks are in use you can refresh all the ads in a roadblock or none of them.
 * @param {Number} [adrefresh.minTimeHouse=15000] Number of ms minimum time for house ads to be inview before refreshed
 * @param {Number} [adrefresh.minTimeADX=30000] Number of ms minimum time for AdX ads to be inview before refreshed
 * @param {Number} [adrefresh.minTimeOX=15000] Number of ms minimum time for OX ads to be inview before refreshed
 * @param {Number} [adrefresh.minTimeDefault=30000] Number of ms minimum default time for ads to be inview before refreshed
 * @param {Array} [adrefresh.houseAdvertiserIds=[1234, 2345]] List of advertiserIds which are considered "house" advertisers.
 * @param {Array} [adrefresh.oxAdvertiserIds=[2234, 3333]] List of advertiserIds which are considered "OX" (open bidding) advertisers.
 * @param {Array} [adrefresh.adxAdvertiserIds=[4234, 4567]] List of advertiserIds which are considered "Adx" advertisers.
 * @param {Boolean} [adrefresh.refreshSponshorhips=false] Whether sponsorships should be refreshed
 * @param {Array} [adrefresh.sponsorshipLineIds=[12345, 56789]] List of lineIds from GAM which are considered "sponsorships"
 * @param {Array} [adrefresh.noRefreshLineIds=[12345, 56789]] List of lineIds from GAM which should not refresh
 * @param {Array} [adrefresh.noRefreshAdvertiserIds=[12345, 56789]] List of AdvertiserIds which should not refresh
 *
 * @param {Object} [analytics] Top level config object for analytics reporting
 * @param {BidBarrel~PrebidAnalyticsProvider|BidBarrel~PrebidAnalyticsProvider[]} [analytics.prebid]
 * Internal {@link http://prebid.org/overview/analytics.html|analytics adapter configuration(s)} for prebid.
 *
 * <b>IMPORTANT:</b> Usage of this feature of BidBarrel is highly cautioned due to the fragile nature of analytics providers' event limitations. With an adapter enabled(via the enabled flag) it will be very easy for a site to surpass the event limitations of an analytics provider. Expect events to increase as much as 10-20x per page view.
 *
 * This is a {@link faq/#what-is-a-static-config-property-|Static Config Property}. However it can be disabled via site-side implementation as needed(the bundle will still have the adapter).
 * @param {BidBarrel~AnalyticsConfig[]} [analytics.bidbarrel] Custom BidBarrel analytics module used to record prebid bids and various client side data
 * @param {Object} [analyticsTimings] Configuration for the timings recorded by the ad library
 *
 * This is a {@link faq/#what-is-a-static-config-property-|Static Config Property}. However it can be disabled via site-side implementation as needed(the bundle will still have the adapter).
 * @param {Boolean} [analyticsTimings.utag=true] boolean flag to enable utag script recording
 * @param {Object} [analyticsTimings.allowDuplicates={}] Object for tracking where duplicate records are allowed to be reported. The key should be the record slug and the value should be a boolean value.
 * @param {Number} [analyticsTimings.timeout=60000] Number of ms for timeout
 * @param {Array<Array<String>>} [analyticsTimings.sequences] String names of events to track together in sampling
 * @param {Object} [analyticsTimings.scripts] Object for tracking various script load times. Keys should be record slugs and values should be objects with a match or matchPart value indicating a regex value as passed into new RegExp(value, 'gm') and a record property indicating what values should be in the record.
 * @param {Object} [analyticsTimings.events] Object for tracking various bidbarrel events. Keys should reflect the bidbarrel event name and the value should include a record property to pass into the recording process. Additionally you can specify a boolean once value to indicate to only listen for the first occurence of this event.
 * @param {Object} [api] Object for configuration of AdLib api endpoint
 * ```javascript
 *  api: {
 *       baseUrl: "https://at.adtech.redventures.io/lib/api",
 *       apiBase: {
 *          prod: "https://at.adtech.redventures.io/lib/api",
 *          stage: "https://at.development.adtech.redventures.io/lib/api",
 *          vcbs_prod: "https://at.cbsi.com/lib/api",
 *          vcbs_stage: "https://dev.at.cbsi.com/lib/api"
 *      },
 *      version: 1,
 *      fetchOptions: {
 *        useVersion: true,
 *          headers: {
 *              "Content-Type":"application/json",
 *              "Accept": "application/json"
 *          }
 *      }
 *  }
 * ```
 * @param {Object} [confiant] Object for configuration of confiant module
 * @param {Boolean} [confiant.badAdRefresh=true] Flag to control whether a refresh of an ad is triggered on the `badAd` event
 * @param {Object} [contentAnnotation] configuration for the AMLG Content Annotation module which sets various targeting for content
 *
 * This is a {@link faq/#what-is-a-static-config-property-|Static Config Property}. However it can be disabled via site-side implementation as needed(the bundle will still have the adapter).
 * @param {String} [contentAnnotation.siteCode] Strin name for the site.  eg. "cnet"
 * @param {String[]} [contentAnnotation.validPtypes] Array of valid page types to make content annotation requests on
 * @param {Number} [contentAnnotation.requestPercentage] percentage value of times the contentAnnotation requests should be made client side(at the page session level)
 * @param {Object} [contentAnnotation.endpoints] The API endpoint configurations for fetch requests
 * @param {String} [contentAnnotation.endpoints.dev] The dev api endpoint for AMLG
 * @param {String} [contentAnnotation.endpoints.prod] The prod api endpoint for AMLG
 * @param {Object} [contentAnnotation.key] The API key for fetch requests
 * @param {String} [contentAnnotation.key.dev] The dev key for AMLG
 * @param {String} [contentAnnotation.key.prod] The prod key for AMLG
 * @param {Object} [enrichment] Object for configurations for the enrichment service
 * @param {String} [enrichment.token] Token value to be used in request to enrichment service
 * @param {Object} [enrichment.endpoint] Object to hold endpoint urls
 * @param {String} [enrichment.endpoint.prod] URL to use for prod requests
 * @param {Object} [errorReporting]  Object for configurations for errorReporting
 * ```javascript
 * errorReporting: {
 *        chunkSize: 20,
 *        reportInterval: 15000,
 *        reportingPercentage: 1
 *    },
 * ```
 * @param {Object} [geo] Configuration for geolocation detection
 * @param {Boolean} [geo.enabled=false] Whether or not the enable geolocation
 * feature
 * @param {Number} [geo.failsafeTimeout=2000] Max amount of time until
 * geo.failsafeCountryCode is applied
 * @param {String} [geo.failsafeCountryCode='us'] Country code to fallback to
 * @param {String} [geo.failsafeRegionCode='us'] region region to use in case of
 * unconfigured country code
 * @param {String} [geo.overrideCountryCode] Country code to override with
 * @param {String} [geo.overrideRegionCode] Region code to override with
 * @param {Boolean} [geo.persistCountryLookup] Tells BidBarrel to keep
 * looking up the country code after the failsafe scenario has been hit
 * @param {Object} [ias] Configuration for IAS tools
 * @param {Object} [ias.publisherOptimization] Configuration for IAS
 * @param {String} [ias.publisherOptimization.scriptUrl] Url which will be used to load the IAS Script
 * @param {Object} [moat] Configuration for Moat tools
 * @param {Object} [moat.yieldIntelligence] Configuration for Moat Yield Intelligence (MYI)
 * @param {Boolean} [moat.yieldIntelligence.enabled=true] Flag to enable/disable the MYI module
 * @param {String} [moat.yieldIntelligence.scriptUrl] Url which will be used to load the Moat Yield Intelligence Script eg. https://z.moatads.com/redventuresgamheader644747280705/moatheader.js
 * @param {Object} [dv] Configuration for DoubleVerify tools
 * @param {Boolean} [dv.enabled=true] Flag to enable/disable the DoubleVerify module
 * @param {String} [dv.scriptUrl] Url which will be used to load the DoubleVerify Script
 * @param {Object} [rvconsent] Configuration for consent setup
 * @param {String} [rvconsent.type="onetrust"] Type of consent setup.  Valid values "onetrust", "cbsioptanon"
 * @param {Boolean} [rvconsent.enableServices=false] Boolean flag that tells BidBarrel whether it should run enableServices
 * @param {Object} [rvconsent.scriptUrls] The API endpoint configurations for cbsiOptAnon
 * @param {String} [rvconsent.scriptUrls.dev=https://production-cmp.isgprivacy.cbsi.com/dist/optanon-v1.1.0.js] The dev api endpoint for cbsiOptAnon
 * @param {String} [rvconsent.scriptUrls.prod=https://production-cmp.isgprivacy.cbsi.com/dist/optanon-v1.1.0.js] The prod api endpoint for cbsiOptAnon
 * @param {Boolean} [rvconsent.setNpaOnConsentChange=true] Boolean flag that tells cbsiOptAnon that is should setNPA when the consent is changed
 * @param {Object} [optanon] (Deprecated in favor of `rvconsent`)Configuration to pass to cbsoptanon {@link http://production-docs.isgprivacy.cbsi.com/cbsoptanon-lib/docs/index.html|Docs} | {@link http://production-docs.isgprivacy.cbsi.com/cbsoptanon-manual/#installation|Manual}
 * @param {Boolean} [optanon.enableServices=false] Boolean flag that tells BidBarrel whether it should run enableServices
 * @param {Boolean} [optanon.renderScript=true] Boolean flag that tells BidBarrel to render the optanon script if it's not already on the page. BidBarrel will check for the optanon.js script file name and for matching script tags with "cmp.isgprivacy.cbsi.com" in the src attribute before attempting to render
 * @param {Object} [optanon.scriptUrls] The API endpoint configurations for cbsiOptAnon
 * @param {String} [optanon.scriptUrls.dev=https://production-cmp.isgprivacy.cbsi.com/dist/optanon-v1.1.0.js] The dev api endpoint for cbsiOptAnon
 * @param {String} [optanon.scriptUrls.prod=https://production-cmp.isgprivacy.cbsi.com/dist/optanon-v1.1.0.js] The prod api endpoint for cbsiOptAnon
 * @param {Boolean} [optanon.setNpaOnConsentChange=true] Boolean flag that tells cbsiOptAnon that is should setNPA when the consent is changed
 * @param {Object} [pubhub] Configuration for Pubmatic Identity Hub module
 * @param {Boolean} [pubhub.enabled=true] Flag to enable/disable the Pubmatic Identity Hub module
 * @param {String} [pub.scriptLoc] First part of the Url which will be used to load the Pubmatic Identity Hub Script
 * @param {String} [pub.scriptFile] File name of the Url which will be used to load the Pubmatic Identity Hub Script
 *
 *
 * @param {BidBarrel~VideoConfig} [video] The configuration for header bidding for video

 * @param {Object} [amazon] Amazon module configuration
 *
 * This is a {@link faq/#what-is-a-static-config-property-|Static Config Property}
 * @param {Number} [amazon.pubID=5062] Amazon publisher id
 * @param {Array<Array<Number>>} [amazon.allowedSizes] Array of sizes allowed for the bidder
 * @param {Boolean} [amazon.enabled=true] Flag to enable/disable the amazon module
 * @param {String} [amazon.adServer] Ad server to determine the method
 * Amazon uses to apply targeting to ad inventory
 * property on {@link api/#BidBarrel~AdUnit|BidBarrel~AdUnit}. Leaving this
 * property and the <code>unit.collapseEmptyDiv</code> undefined results in
 * shortcircuiting this method all together. For more info
 * checkout the {@link https://support.google.com/admanager/answer/3072674?hl=en|Ad Manager docs}
 * or {@link https://developers.google.com/doubleclick-gpt/reference#googletag.Slot_setCollapseEmptyDiv|Google Publisher Tag docs}
 * @param {Object} [amazon.regionConfigs] Region based configuration overrides for amazon. Keys represent a region code and the values represent a partial amazon configuration to override with.

 * @param {Boolean} [identity] Object for configuration for identity
 * @param {Object} [prebid] Configuration for prebid
 * @param {Object} [pbjsConfig] Defines the default config for the prebid
 * library. The priceBuckets property the BidBarrel's config overrides
 * the priceBuckets property in pbjsConfig. {@link http://prebid.org/dev-docs/publisher-api-reference.html#pbjssetconfigoptions|read more}
 *
 * This is a {@link faq/#what-is-a-static-config-property-|Static Config Property}
 * @example
 * // Full Config
 * var BidBarrelConfig = {
  dfpPath: "/22309610186/aw-cnet/home",
  lazyLoading: {
    offset: {
      yAxis: 400,
      xAxis: 0
    },
    percentageVisible: 1
  },
  priceBuckets: [{
    precision: 2,
    min: 0,
    max: 22.5,
    increment: 0.01
  }],
  targeting: {
    query: {
      keyMap: {
        adSession: "session",
        adSubses: "subsession",
        adRegion: "adRegion",
        session: "session",
        subsession: "subsession",
        env: "env",
        ftag: "ftag",
        ttag: "ttag"
      },
      dynamicPrefix: "adTargeting_"
    }
  },
  bootstrap: {
    renderScripts: [
      {
        id: "ix-wrapper",
        src: "//js-sec.indexww.com/ht/p/184679-82866056767243.js",
        async: true
      }
    ]
  }
}

 */
