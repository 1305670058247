import { eventEmitter } from '../events.js';
import { cloneDeep } from '../utilities/cloneDeep.js';
import { exposureApi } from '../exposureApi.js';
import { features } from '../features.js';
import { percentageRunner, percentageShouldRun } from '../utilities/percentageRunner.js';
import { getConfig } from '../config.js';
import { api } from './api.js';
import { chunk } from '../utilities/helpers/chunk.js';
import CONSTANTS from '../constants.json';
import errorReplacer from '../utilities/helpers/errorReplacer.js';
import { logger } from '../utilities/logger.js';
import context from '../context.js';
import { isStagingEnv } from '../utilities/environment.js';

const erLogger = logger({ name: 'errorReporting', bgColor: '#8F8389' });

const { ERROR_REPORT } = CONSTANTS.EVENTS;

/**
 * BidBarrel Error Reporting for reporting all errors
 *
 * @module ErrorReporting
 * @private
 */
export const errorReporting = ( () => {
	/**
	 * Flag for tracking if reporting has been setup yet
	 *
	 * @memberof ErrorReporting
	 * @private
	 */
	let reportingSetup = false;
	/**
	 * A registry of errors
	 *
	 * @memberof ErrorReporting
	 * @private
	 */
	let errors = [];
	/**
	 * All collected errors
	 *
	 * @memberof ErrorReporting
	 * @private
	 */
	const allErrors = [];
	/**
	 * A collection of errors
	 *
	 * @memberof ErrorReporting
	 * @private
	 */
	const queuedErrors = [];
	/**
	 * A registry of intervals
	 *
	 * @memberof ErrorReporting
	 * @private
	 */
  // eslint-disable-next-line no-unused-vars
	let reportingIntervals = {};
	/**
	 *  A registry of configurations
	 * @memberof ErrorReporting
	 * @private
	 */
	let config = {};

/**
	 * Method to get the error context
	 * @memberof ErrorReporting
	 * @private
	 */
 function getErrorContext(){
  return {
    bidbarrelVersion: context.getValue("bidbarrelVersion") || null,
    connectionType: context.getValue("client.connectionType") || null,
    connectionSpeed: context.getValue("client.connectionSpeed") || null,
    regionCode: context.getValue("client.region") || null,
    countryCode: context.getValue("client.country") || null,
    subCountryCode: context.getValue("client.subregion") || null,
    vpWidth: context.getValue("client.viewportWidth") || null,
    vpHeight: context.getValue("client.viewportHeight") || null,
    configVersion: context.getValue("config._remoteContext.version") || null,
    authenticated: context.getValue("authenticated") || false,
    session: context.getValue("targeting.session") || null,
    subses: context.getValue("targeting.subses") || null,
    ptype: context.getValue("targeting.ptype") || null,
    abStr: context.getValue("abStr") || null,
    gaClientId: context.getValue("cookie.gaClientId") || null,
    aamUuid: context.getValue("cookie.aamUuid") || null,
    env: context.getValue("targeting.env") || context.getValue("config.pageTargeting.env") || (isStagingEnv() ? "stage" : "prod") || null,
    dfpPath: context.getValue("config.dfpPathObj.string") || null,
    site: context.getValue('site') || null,
    bidbarrelVariant: context.getValue('variant') || null
  }
}
 	/**
	 * Adds a error to all configured queues
	 *
	 * @param {BidBarrel~ErrorReporting} error
	 * @memberof ErrorReporting
	 * @private
	 */
    function report(errorArg) {
      let error = errorArg;

      try {

        if(!(error instanceof Error)){
          error = typeof error === "string" ? new Error(error) : new Error(JSON.stringify(error));
        }

        const errContext = getErrorContext();
        Object.keys(errContext).forEach((key) => {
					if (Object.hasOwnProperty.call(errContext, key)) {
						const contextValue = errContext[key];
						error[key] = contextValue;
					}
				});

        if(!reportingSetup){
          queuedErrors.push(error);
          return;
        }

        error = JSON.parse(JSON.stringify(error,errorReplacer));

        if(!error.level) {
          error.level = "error";
        }

        allErrors.push(error);
        errors.push(error);
      } catch (err) {
        erLogger.logError('Failure to report error.');
      }
    }
  /**
	 * Sets the configurations for this module
	 *
	 * @memberof ErrorReporting
	 * @private
	 */
	function register() {
		getConfig("errorReporting", value => {
			config = value;
		});

		// Add the listener
		eventEmitter.on(ERROR_REPORT, error => report(error) );
	}
  	/**
	 * Handles processing queued errors
	 *
	 * @memberof ErrorReporting
	 * @private
	 */
	function addQueuedErrors(){
    if(typeof queuedErrors !== "undefined") {
        for (let index = 0; index < queuedErrors.length; index+=1) {
            const error = queuedErrors[index];
            report(error);
        }
    }
}
	/**
	 * Sets up reporting interval
	 *
	 *
	 * @memberof ErrorReporting
	 * @private
	 */
   function setupReporting() {
		if(reportingSetup){
			return;
		}
		if ( features.get([`forceRun.errorReporting`, 'forceRun.all']) ||
    			percentageShouldRun(config.reportingPercentage)
		) {
			// eslint-disable-next-line no-unused-vars
			reportingIntervals = setInterval(() => {
				percentageRunner(
					features.get([`forceRun.errorReporting`, 'forceRun.all']) || config.reportingPercentage,
					() => {
						const errorSets = chunk(errors, config.chunkSize);
						if (typeof errorSets !== "undefined" && errorSets.length !== 0){
              Object.keys(errorSets).forEach((oneErr) => {
								const errorPayload = { cat: '$$COMMIT_ACCESS_TOKEN$$', records: errorSets[oneErr] };
								erLogger.logInfo('(Beacon) Reporting Error events:', errorSets[oneErr]);
								api.sendBeacon('log/client', errorPayload);
							});
						}

						// clear errors
						errors = [];
					},
					true
				);
			}, config.reportInterval);
			reportingSetup = true;
			addQueuedErrors();
		}
	}
  /**
	 * Setup method for Module
	 *
	 * @memberof ErrorReporting
	 * @private
	 */
	function setup() {
		setupReporting();
	}

	/**
	 * Gets all errors available
	 *
	 * Requires module: `ErrorReporting`
	 *
	 * @returns {BidBarrel~Error[]}
	 * @memberof ErrorReporting
	 * @private
	 * @exposed
	 */
	function getAllErrors() {
		return cloneDeep(allErrors);
	}
	/**
	 * Gets queued errors
	 *
	 * Requires module: `ErrorReporting`
	 *
	 * @returns {BidBarrel~Error[]}
	 * @memberof ErrorReporting
	 * @private
	 * @exposed
	 */
	function getQueuedErrors() {
		return cloneDeep(queuedErrors);
	}

	exposureApi.expose({
		getAllErrors,
		getQueuedErrors
	});

	// Go ahead and register this service
	register();

	return {
		setup,
		report
	};
})();
export default errorReporting;
// -----------------------------------
// ADDITIONAL DOCUMENTATION
// -----------------------------------
