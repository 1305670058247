import { dom } from "../global";
import { bbLogger } from "./logger";

export function elementSort(elementCollection, priorityAxis = 'y', logger = bbLogger){
    const shouldNotSort = Array.from(elementCollection).some(item => {
        const el = item.element || item;
        if(!el || !el.style) return true;
        return el.style.display === 'none';
    })
    if(shouldNotSort){
        logger.logMessage("Element collection has undefined or elements with display: none; unable to apply element sort.", elementCollection)
        return elementCollection;
    }
    const currentScrollOffset = dom().window.pageYOffset || dom().window.document.documentElement.scrollTop;
    return elementCollection.sort((itemA, itemB) => {
        const elA = itemA.element || itemA;
        const elB = itemB.element || itemB;

		const elATopBound = priorityAxis === "y" ? elA.getBoundingClientRect().top : elA.getBoundingClientRect().left;
		const elBTopBound = priorityAxis === "y" ? elB.getBoundingClientRect().top : elB.getBoundingClientRect().left;

		const elAScrollPosDiff = elATopBound - currentScrollOffset;
		const elBScrollPosDiff = elBTopBound - currentScrollOffset;

		// bbLogger.logInfo('sorting', unitACode, unitBCode, elAScrollPosDiff, elBScrollPosDiff, elATopBound, elBTopBound);
		return elAScrollPosDiff - elBScrollPosDiff > 0 ? 1 : -1;
    })
}