import { logger } from '../../utilities/logger.js';
import { getConfig, setConfig } from '../../config.js';
import { storage } from '../../services/storage.js';
import { dom } from '../../global.js';
import defaultsDeep from '../../utilities/helpers/defaultsDeep.js';

const otLogger = logger({ name: 'rvconsent', textColor: '#FFF', bgColor: '#550000' });

/**
	 * Configuration for CBS Optanon
	 *
	 * @memberof optanon
	 * @type {Object}
	 * @private
	 */
let consentConfig = {};
export function getConsentConfig() {
    return consentConfig;
}
export function setConsentConfig(consentConfigValue) {
   consentConfig=consentConfigValue;
   otLogger.logInfo(`Config set`, consentConfig);
   return consentConfig;
}

/**
	 * Variable tracking compliance. Need for usage in a synchronous manner.
	 *
	 *
	 * @type {Boolean}
	 *
	 */
let consentGiven = false;
let consentFired = false;
export function getConsentGiven() {
    return consentGiven;
}
export function setConsentGiven(consentValue) {
   if(consentValue===true || consentValue===false ) {
       consentGiven=consentValue;
   }
   return consentGiven;
}
export function getConsentFired() {
    return consentFired;
}
export function setConsentFired() {
   consentFired=true;
   return consentFired;
}

/**************************************
 * START FUNCTIONS USED ONLY BY CBSIOPTANON
 * *****************************************/
/**
 * CBS Optanon's bootstrap script.
 *
 * This scripts sets up the cbsoptanon object on the page if it is now already set.
 *
 * @memberof optanon
 * @private
 */
export function applyLibBootstrapCode() {
    otLogger.logInfo("Fired applyLibBootstrapCode");
    /* eslint-disable */
    (function(a) {
        var w = dom().window;
        var b = 'cbsoptanon';
        var q = 'cmd';
        var r = 'config';
        w[b] = w[b] ? w[b] : {};
        w[b][q] = w[b][q] ? w[b][q] : [];
        w[b][r] = w[b][r] ? w[b][r] : [];

        a.forEach(function(z) {
            w[b][z] =
                w[b][z] ||
                function() {
                    var c = arguments;
                    w[b][q].push(function() {
                        w[b][z].apply(w[b], c);
                    });
                };
        });
    })(['onIframesReady', 'onFormsReady', 'onScriptsReady', 'onAdsReady']);
    /* eslint-enable */
}

/**
 * Method for parsing client info out of Optanon cookie, used by CBSIOptanon.
 *
 * @memberof optanon
 * @private
 */
export function parseOptanonCookie(){
    if(getConfig("clientInfo.data.country")) return;
    try {
        const cookieValue = storage.getCookie("OptanonConsent", false);
        if(cookieValue){
            const obj = cookieValue.split("&").reduce((r,v) => {
                const [key,val] = v.split("=");
                // eslint-disable-next-line
                r[key] = val;
                return r;
            },{})
            let countryCode = obj.geolocation.toLowerCase();
            let region = null;
            if(obj.geolocation.indexOf(";") >= 0){
                const [cc,rc] = obj.geolocation.toLowerCase().split(";");
                countryCode = cc;
                region = rc;
            }
            let gmtOffset = obj.datestamp.split("+").find(v => v.indexOf("GMT") >= 0)
            if(gmtOffset){
                gmtOffset = gmtOffset.replace("GMT", "");
                const symbol = gmtOffset.charAt(0);
                // eslint-disable-next-line
                const num = parseInt(gmtOffset.replace(symbol, ""));
                gmtOffset = symbol + num;
            }
            const existingClientInfo = getConfig("clientInfo.data") || {};
            const parsedData = {
                country: countryCode,
                gmtOffset
            }
            if(region){
                parsedData.region = region;
            }
            const clientInfoData = defaultsDeep(parsedData, existingClientInfo);
            otLogger.logInfo("Extracted ClientInfo from OptanonConsent cookie", parsedData);
            setConfig("clientInfo.data", clientInfoData);
        }
    } catch (err) {
        otLogger.logInfo("Unable to parse OptanonConsent cookie", err);
    }
}

/**************************************
 * END FUNCTIONS USED ONLY BY CBSIOPTANON
 * *****************************************/

module.exports = { parseOptanonCookie, applyLibBootstrapCode, getConsentGiven, setConsentGiven, getConsentFired, setConsentFired, getConsentConfig, setConsentConfig };
