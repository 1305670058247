import { getConfig } from '../../config.js';
import CONSTANTS from '../../constants.json';
import { eventEmitter } from '../../events.js';
import { exposureApi } from '../../exposureApi.js';
import { dom } from '../../global.js';
import { moduleManager } from '../../moduleManager.js';
import { requestManager } from '../../requestManager.js';
import { setTargeting } from '../../targeting.js';
import { cloneDeep } from '../../utilities/cloneDeep.js';
import { logger } from '../../utilities/logger.js';
import { renderScript } from '../../utilities/renderScript.js';


const dvLogger = logger({ name: 'dvqt', textColor: '#FFF', bgColor: '#005500' });
const { DV, GOOGLE_PUBLISHER_TAG } = CONSTANTS.MODULES;

/**
 * DoubleVerify Quality Targeting Page Tag
 *
 * This module adds the DoubleVerify Quality Targeting Page Tag script to the page, adds the callback
 * function called by DV when it is done loading and adds the DV ad targeting parameters
 * to the GPT page level and slot level ad call parameters once the ad slots are defined.
 * This module also registers the DV module with the requestManager module to ensure that the DV
 * targeting parameters are available before the ad server request is made.
 *
 * @module DV
 * @private
 */
const dvModuleBase = (function dvMod () {
	/**
	 * Configuration object
	 *
	 * @memberof DV
	 * @private
	 */
	let config = {};

  /**
	 * Function which is called by DoubleVerify as onDvtagReady() when DV is done loading
	 *
	 * Requires Module: `dv`
	 *
	 * @memberof DV
   * @param {*} callback Function which should be called when DV is ready
   * @param {*} timeout How long to wait before the function is called if DV is not ready Default: 750ms
	 * @private
	 * @exposed
	 */
  function dvReady (callback, timeout = 750) {
    dvLogger.atVerbosity(3).logInfo('onDvtagReady() (dvReady) fired');
    dom().window.dvtag = dom().window.dvtag || {}
    dom().window.dvtag.cmd = dom().window.dvtag.cmd || []

    const opt = { callback, timeout, timestamp: new Date().getTime() }

    // eslint-disable-next-line no-undef
    dom().window.dvtag.cmd.push(() => { dvtag.queueAdRequest(opt) });

    setTimeout(() => {
     const cb = opt.callback
     opt.callback = null
     if (cb) cb()
    }, timeout)
  }
/**
     * Hook method for pbjs.requestBids
     *
     * @param {object} adUnits - the current auction ad units
     * @returns {function} next function with modified values
     * @memberof DynamicBidders
     * @private
     */
function bidRequest(adUnits) {
  const updatedAdUnits = cloneDeep(adUnits);
  if(config.enabled === true ) {
    dom().window.PQ = dom().window.PQ || {}
    dom().window.PQ.cmd = dom().window.PQ.cmd || []

    updatedAdUnits.forEach((adUnit)=> {
      if(adUnit.isVideo === true ) {
        // if it's a video unit, push the request for targeting to the PQ (DV) queue
        // when that fires add the targeting to the adUnit
        // dvLogger.logInfo("DV targeting for video unit", adUnit);
        dom().window.PQ.cmd.push(() => {
          const vidau = typeof adUnit.targeting.vidau === "string" ? adUnit.targeting.vidau : null;
          // only add DV keys if vidau is set
          if(vidau === null) {
            dvLogger.logError("DV video targeting error", "vidau is not set for video unit.  DV keys will not be added.");
            return;
          }

          const targObj ={
            signals: ['ids','bsc','vlp','tvp','abs'],
            adUnits: [
              {
              adUnitPath: vidau,
              sizes: adUnit.sizes.map(size => size.join('x')).join(', ')
              }
            ]
          };
          dvLogger.logInfo("Obj sent to DV .getTargeting", targObj);
          dom().window.PQ.getTargeting(
            targObj,
            (error, targetingData) => {
              if(error) {
                dvLogger.logError("DV targeting error", error);
              } else {
                // fix format of TVP and VLP
                const updatedTargetingData = cloneDeep(targetingData)
                if(typeof updatedTargetingData.TVP === "object") {
                  delete updatedTargetingData.TVP;
                  updatedTargetingData.TVP = targetingData.TVP[vidau][adUnit.sizes.map(size => size.join('x')).join(', ')];
                }
                if(typeof targetingData.VLP === "object") {
                  delete updatedTargetingData.VLP;
                  updatedTargetingData.VLP = targetingData.VLP[vidau][adUnit.sizes.map(size => size.join('x')).join(', ')];
                }
                // *** WARNING *** BIG GIANT HACK (which I hate but it is what it is. MLS 2024-05-15)
                // Adding DV params to the page level because video won't accept them at the slot level anywhere except in the call to .auction
                // it's hacky.. but atm DV sets the variables themselves on all the other slots so
                // the slot level keys should override the page level keys in everything except for the video slots
                setTargeting(updatedTargetingData);
                dvLogger.logInfo("DV targeting data added to page targeting", updatedTargetingData);
              }
            }
          )
        })
      }
    });
  }
  return updatedAdUnits;
}

	/**
	 * Registers the module and sets up hook around
   * `batchSlotsDefined` to call dvReady when slots are defined
	 *
	 * @private
	 * @memberof DV
	 */
	function register() {
    getConfig('dv', (newConfig) => {
			config = newConfig;
      if(config.enabled === true ) {
        // eslint-disable-next-line no-unused-vars
        eventEmitter.on('batchSlotsDefined', (unitConfig, dfpEvent) => {
          dvLogger.atVerbosity(3).logInfo('Attempting to get targeting from DV QT');
          dvReady( () => requestManager.done('dvqt') );
        });
      }
		});
	}

	/**
	 * Initializes module which loads the DV script and expose the dvReady function
   * which will be called by DV when DV is loaded
	 *
	 * @private
	 * @memberof DV
	 */
	function initialize() {
    if(config.enabled === true) {
      renderScript({
        src: config.scriptUrl,
        async: true,
        id: 'bb-dvqt',
      });
      dom().window.onDvtagReady = dvReady;
      requestManager.register('dvqt');
    } else {
      dvLogger.logInfo('DV Module is disabled via config setting.');
    }
	}

	exposureApi.expose({
	  dvReady,
	});
	return {
		name: DV,
		register,
		initialize,
    bidRequest,
    dvReady,
	};
})();

const dvModule = moduleManager.register(dvModuleBase,  [ GOOGLE_PUBLISHER_TAG ]);
export default dvModule;
