// eslint-disable-next-line import/no-relative-packages
import { getGlobal } from '../../../../prebid/src/prebidGlobal.js';
import CONSTANTS from '../../../constants.json';
import { eventEmitter } from '../../../events.js';
import { moduleManager } from '../../../moduleManager.js';
import { getUnits } from '../../../unitManager.js';
import pick from '../../../utilities/helpers/pick.js';

const $$PREBID_GLOBAL$$ = getGlobal();
const BID_RECORD_PROPERTIES = [
	'bidder',
	'adUnitCode',
	'dealId',
	'pbCg',
	'bidderCode',
	'adId',
	'requestTimestamp',
	'responseTimestamp',
	'statusMessage',
	'height',
	'width',
	'timeToRespond',
	'mediaType',
];

const {
	MODULES: { PREBID_HEADER_BIDDING, BIDBARREL_ANALYTICS, ANALYTICS_PREBID },
	ANALYTICS_RECORD_TYPES: { NO_BID, BID, BID_USED },
	EVENTS: { REFRESH },
} = CONSTANTS;

/**
 * Module for handling Prebid bids and translating them to BidBarrel Analytics
 *
 * @module prebidAnalyticsHandler
 * @private
 */
const prebidHandler = (function pbh() {
	/**
	 * A collection of bid records to later apply all general params to
	 *
	 * @memberof prebidAnalyticsHandler
	 * @private
	 */
	let queuedRecords = [];
	/**
	 *  A flag to keep track of event registration
	 *
	 * @memberof prebidAnalyticsHandler
	 * @private
	 */
	let eventsRegistered = false;

	/**
	 * Records a no bid analytics data structure
	 *
	 * @param {PrebidBid} bidData
	 * @memberof prebidAnalyticsHandler
	 * @private
	 */
	function recordNoBid(bidData) {
		queuedRecords.push({
			type: NO_BID,
			wrapperSource: 'prebid',
			bidder: bidData.bidder,
			adUnitCode: bidData.adUnitCode,
			createTimestamp: new Date().getTime(),
		});
	}

	function addPrebidRecord(record) {
		moduleManager.viaModule(BIDBARREL_ANALYTICS, ({ addRecord }) => {
			if ([BID, BID_USED].indexOf(record.type) === -1) {
				addRecord(record);
			} else if (record.type === BID) {
				if (record.mediaType === 'video') {
					addRecord(record);
				} else {
					eventEmitter.on(
						`${record.adUnitCode}.${REFRESH}`,
						(unit) => {
							addRecord({ ...record, impId: unit.targeting.iid });
						},
						true
					);
				}
			} else {
				addRecord({ ...record, impId: getUnits()[record.adUnitCode].targeting.iid });
			}
		});
	}
	/**
	 * Records a Prebid bid object
	 *
	 * @param {PrebidBid} bidData
	 * @param {boolean} [used=true]
	 * @memberof prebidAnalyticsHandler
	 * @private
	 */
	function recordPrebidBid(bidData, used = true) {
		if (typeof used !== 'boolean') {
			// eslint-disable-next-line no-param-reassign
			used = true;
		}
		if (used) {
			addPrebidRecord({
				type: BID_USED,
				wrapperSource: 'prebid',
				...pick(bidData, BID_RECORD_PROPERTIES),
				cpm: bidData.cpm.toFixed(5).toString(),
				createTimestamp: new Date().getTime(),
			});
		} else {
			queuedRecords.push({
				type: BID,
				wrapperSource: 'prebid',
				...pick(bidData, BID_RECORD_PROPERTIES),
				cpm: bidData.cpm.toFixed(5).toString(),
				createTimestamp: bidData.responseTimestamp,
			});
		}
	}

	/**
	 * Applies all additional properties needed for each bid and noBid record
	 *
	 * @memberof prebidAnalyticsHandler
	 * @private
	 */
	function applyAdditionalProperties() {
		for (let index = 0; index < queuedRecords.length; index += 1) {
			const record = queuedRecords[index];
			addPrebidRecord(record);
		}
		queuedRecords = [];
	}

	/**
	 * Generates empty bid record properties and sets all keys to null (for noBid event)
	 *
	 * @param {PrebidBid} bidData
	 * @memberof BidbarrelAnalytics
	 * @private
	 */
	// function emptyBidRecordProperties(bidData) {
	// 	return BID_RECORD_PROPERTIES.reduce((result, key) => ({ ...result, [key]: bidData[key] || null }), { cpm: null });
	// }
	/**
	 * Records all auction bids
	 *
	 * @param {Object} auction
	 * @memberof prebidAnalyticsHandler
	 * @private
	 */
	function recordAuctionBids(auction) {
		const bids = auction.bidsReceived;
		for (let index = 0; index < bids.length; index += 1) {
			const bid = bids[index];
			recordPrebidBid(bid, false);
		}
		applyAdditionalProperties();
	}
	/**
	 * Event listeners for prebid bids
	 *
	 * @memberof prebidAnalyticsHandler
	 * @private
	 */
	function addListeners() {
		if (!eventsRegistered) {
			$$PREBID_GLOBAL$$.que.push(() => {
				$$PREBID_GLOBAL$$.onEvent('noBid', recordNoBid);
				$$PREBID_GLOBAL$$.onEvent('auctionEnd', recordAuctionBids);
				$$PREBID_GLOBAL$$.onEvent('bidWon', recordPrebidBid);
			});
			eventsRegistered = true;
		}
	}
	/**
	 * Registration function for module
	 *
	 * @memberof prebidAnalyticsHandler
	 * @private
	 */
	function register() {
		moduleManager.viaModule(PREBID_HEADER_BIDDING, () => {
			addListeners();
		});
	}

	/**
	 * Deregistration method for Module
	 *
	 * @memberof prebidAnalyticsHandler
	 * @private
	 */
	function deregister() {
		if (eventsRegistered) {
			$$PREBID_GLOBAL$$.que.push(() => {
				$$PREBID_GLOBAL$$.offEvent('noBid', recordNoBid);
				$$PREBID_GLOBAL$$.offEvent('auctionEnd', recordAuctionBids);
				$$PREBID_GLOBAL$$.offEvent('bidWon', recordPrebidBid);
			});
			eventsRegistered = false;
		}
	}

	return {
		register,
		deregister,
		name: ANALYTICS_PREBID,
	};
})();

export const prebidAnalyticsHandler = moduleManager.register(prebidHandler, [BIDBARREL_ANALYTICS, PREBID_HEADER_BIDDING]);
export default prebidAnalyticsHandler;
