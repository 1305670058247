import { urlQueryAsObject } from './utilities/queryParams.js';
import { logger } from './utilities/logger.js';
import { stringFlags } from './utilities/stringFlags.js';
import get from './utilities/helpers/get.js';
import set from './utilities/helpers/set.js';
import { cloneDeep } from './utilities/cloneDeep.js';
import { exposureApi } from './exposureApi.js';
import CONSTANTS from './constants.json';

const {
	QUERY_PARAMS: { AD_SUPPRESS, BID_SUPPRESS, MOD_SUPPRESS, PERF_MEASURE, SIZE_SUPPRESS, FORCE_RUN, AD_REGION, AD_COUNTRY },
} = CONSTANTS;
const featuresLogger = logger({ name: 'Features', bgColor: '#eee', textColor: '#DA4167' });
const featQpMap = {
	adSuppress: AD_SUPPRESS,
	bidSuppress: BID_SUPPRESS,
	modSuppress: MOD_SUPPRESS,
	sizeSuppress: SIZE_SUPPRESS,
	perfMeasure: PERF_MEASURE,
	forceRun: FORCE_RUN,
};
const featQpValueMap = {
	regionCode: AD_REGION,
	countryCode: AD_COUNTRY,
};
/**
 * Features module
 *
 * This is an initial implementation for feature flags in BidBarrel
 *
 * @module features
 * @private
 */
// eslint-disable-next-line func-names, import/prefer-default-export
export const features = (function () {
	/**
	 * Registry of all feature flags
	 *
	 * @memberof features
	 * @private
	 */
	const registry = {};
	/**
	 * Takes a string flags results object and sets the appropriate flags
	 *
	 * @param {String} key
	 * @param {Object} queryResults
	 * @param {Object} [obj=registry]
	 * @memberof features
	 * @private
	 */
	function setQueryResults(key, queryResults, obj = registry) {
		Object.keys(queryResults).forEach((key2) => {
			const ruleName = queryResults[key2];
			if (Object.prototype.hasOwnProperty.call(queryResults, ruleName)) {
				const ruleResultValue = queryResults[ruleName];
				set(obj, `${key}.${ruleName}`, ruleResultValue);
			}
		});
	}
	/**
	 *  URL Query Object
	 *
	 * @memberof features
	 * @private
	 */
	const query = urlQueryAsObject();
	/**
	 * Sets up additional rules via Query Params
	 *
	 * @memberof features
	 * @private
	 */
	function setupQueryRules() {
		Object.keys(featQpMap).forEach((key) => {
			const featureName = featQpMap[key];
			if (Object.prototype.hasOwnProperty.call(featQpMap, featureName)) {
				const qp = featQpMap[featureName];
				if (query[qp]) {
					setQueryResults(featureName, stringFlags(query[qp]).results);
				}
			}
		});
		Object.keys(featQpValueMap).forEach((key) => {
			const featureName = featQpValueMap[key];
			if (Object.prototype.hasOwnProperty.call(featQpValueMap, featureName)) {
				const qp = featQpValueMap[featureName];
				if (query[qp]) {
					set(registry, featureName, query[qp]);
				}
			}
		});
	}
	/**
	 * Function ran on load
	 *
	 * @memberof features
	 * @private
	 */
	function load() {
		setupQueryRules();
		featuresLogger.atVerbosity(2).logInfo('Features on load', registry);
	}

	/**
	 * Gets a feature flag
	 *
	 * @param {String} featurePath
	 * @param {boolean} [matchAll=false]
	 * @returns {boolean}
	 * @memberof features
	 * @exposed
	 * @public
	 */
	function getFeature(featurePath, matchAll = false) {
		if (featurePath.constructor === Array) {
			const checkResults = featurePath.map((fPath) => getFeature(fPath, matchAll));
			return matchAll ? checkResults.indexOf(false) === -1 : checkResults.indexOf(true) >= 0;
		}
		const result = get(registry, featurePath) || false;
		return typeof result === 'boolean' ? result : false;
	}
	function getFeatureValue(featurePath) {
		return get(registry, featurePath) || null;
	}
	/**
	 * Gets all configured feature flags
	 *
	 * @returns {Object}
	 * @memberof features
	 * @public
	 * @exposed
	 */
	function getAllFeatures() {
		return cloneDeep(registry);
	}

	exposureApi.expose({
		getAllFeatures,
		getFeature,
	});

	load();

	return {
		get: getFeature,
		getValue: getFeatureValue,
	};
})();
