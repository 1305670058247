import { getExpiration } from "./time.js";

const existingTimeouts = {};

export function expireAction(expirationOptions, actionCallback){
    const expirationEvaluation = getExpiration(expirationOptions);
    if(expirationEvaluation.isExpired){
        actionCallback();
    } else if(expirationOptions.forceUpdate || !existingTimeouts[expirationOptions.id]) {
        existingTimeouts[expirationOptions.id] = setTimeout(() => {
            actionCallback();
        }, expirationEvaluation.fromNowMs)
    }

    return expirationEvaluation;
}
export default expireAction;
