import { getAdDensityObj } from "./adDensity.js";
// eslint-disable-next-line import/no-relative-packages
import { getGlobal} from '../../../../prebid/src/prebidGlobal.js';
import { getConfig } from '../../../config.js';
import CONSTANTS from '../../../constants.json';
import { eventEmitter } from '../../../events.js';
import { exposureApi } from '../../../exposureApi.js';
import { dom } from '../../../global.js';
import { moduleManager } from '../../../moduleManager.js';
import { gateway } from '../../../utilities/gateway.js';
import { logger } from '../../../utilities/logger.js';
import { PerformanceObserver } from '../../../utilities/performance/performanceObserver.js';
import { runQueue } from '../../../utilities/queue.js';

// Module constants
const { BIDBARREL_ANALYTICS, ANALYTICS_TIMINGS, GOOGLE_PUBLISHER_TAG } = CONSTANTS.MODULES;
const { AD_DENSITY } = CONSTANTS.ANALYTICS_RECORD_DEFAULTS;
const $$PREBID_GLOBAL$$ = getGlobal();

const tLogger = logger({ name: 'Timings', bgColor: '#660000', textColor: '#FFF' });
/**
 * General Timings Handling Module
 *
 * This module facilitates recording of various timing events while also handling script and event timings for the ad library
 *
 * @module timings
 * @private
 */
const timingsHandler = (function th() {
	/**
	 * Module configuration
	 *
	 * @memberof timings
	 * @private
	 */
	let config = {
		allowDuplicates: {},
	};
	/**
	 * Script config object to facilitate various options for script handling
	 *
	 * @memberof timings
	 * @private
	 */
	const scriptCfg = {
		matchPartRe: null,
		matchManual: [],
		items: [],
	};
	/**
	 * Array of event listeners that allow easy unsubscribe capability for teardown
	 *
	 * @memberof timings
	 * @private
	 */
	const listeners = [];
	/**
	 * Performance observer variable to allow for resource recordings
	 *
	 * @memberof timings
	 * @private
	 */
	let resourceObserver;
	/**
	 * Boolean flag object that is used to prevent timings from being recorded multiple times
	 *
	 * @memberof timings
	 * @private
	 */
	const slugReported = {};
	/**
	 * Object for tracking each timing that is exposed off of the global object
	 *
	 * @memberof timings
	 * @private
	 */
	const timings = {};
	/**
	 * Special utag handling object
	 *
	 * @memberof timings
	 * @private
	 */
	const utagHandling = {
		lastEntry: null,
		timer: null,
		reported: false,
	};
	/**
	 * Object to track recording analytics events once
	 *
	 * @memberof timings
	 * @private
	 */
	const eventTracking = {};
	/**
	 * Object used to track sequences
	 *
	 * @memberof timings
	 * @private
	 */
	const seqTrack = {};
	/**
	 * Queue for ensuring sequences are executed as expected
	 *
	 * @memberof timings
	 * @private
	 */
	const postInitQueue = runQueue('timingsPostInitializationQueue');
	/**
	 * Registration function for module
	 *
	 * @memberof timings
	 * @private
	 */
	function register() {
		getConfig('analyticsTimings', (cfg) => {
			config = cfg;
		});
	}

	/**
	 * Adds a timing record to the analytics queue'd records stack
	 *
	 * @param {Object} record the record to record
	 * @memberof timings
	 * @private
	 */
	function addTimingRecord(record, seqForce = false) {
		postInitQueue.push(() => {
			if ((!seqTrack.slugMap[record.slug] || seqForce) && (!slugReported[record.slug] || config.allowDuplicates[record.slug])) {
				slugReported[record.slug] = true;
				timings[record.slug] = typeof record.amount === 'number' ? Math.round(record.amount) : record.amount;
				moduleManager.viaModule(BIDBARREL_ANALYTICS, ({ addRecord }) => {
					// eslint-disable-next-line no-param-reassign
					record.amount = record.amount.toFixed(3);
					addRecord(record);
				});
			} else if (seqTrack.slugMap[record.slug] && !seqForce) {
				const seq = seqTrack.slugMap[record.slug];
				seqTrack.slugRecord[record.slug] = record;
				seqTrack.gateways[seq].open(record.slug);
			}
		});
	}

	/**
	 * Sets up sequence record handling in the ad library
	 *
	 * @memberof timings
	 * @private
	 */
	function setupSequences() {
		if (config.sequences) {
			seqTrack.gateways = {};
			seqTrack.slugMap = {};
			seqTrack.slugRecord = {};
			for (let index = 0; index < config.sequences.length; index += 1) {
				const sequenceSet = config.sequences[index];
				const key = `analyticsTimingSeq${index}`;
				seqTrack.gateways[key] = gateway(key, sequenceSet);
				for (let ix = 0; ix < sequenceSet.length; ix += 1) {
					const slug = sequenceSet[ix];
					seqTrack.slugMap[slug] = key;
					seqTrack.gateways[key].onOpen(() => {
						addTimingRecord(seqTrack.slugRecord[slug], true);
					});
				}
			}
		}
	}
	/**
	 * Gets performance entries by type safely
	 *
	 * @param {string} type
	 * @returns PerformanceEntry[]
	 * @private
	 * @memberof timings
	 */
	function getEntriesByType(type) {
		if (dom().window.performance.getEntriesByType) {
			return dom().window.performance.getEntriesByType(type);
		}
		if (type === 'navigation' && dom().window.performance.timing) {
			return [dom().window.performance.timing];
		}
		return [];
	}
	/**
	 * Gets a list of incomplete entries while also facilitating a handler function for complete entries
	 *
	 * @param {String[]} types Entry types to check
	 * @param {Function} outlierCallback callback for entries found
	 * @memberof timings
	 * @private
	 */
	function getIncompleteEntryTypes(types, outlierCallback) {
		const initTypes = types;
		const result = [];
		for (let index = 0; index < initTypes.length; index += 1) {
			const type = initTypes[index];
			const entries = getEntriesByType(type);
			if (entries.length === 0) {
				result.push(type);
			} else {
				outlierCallback(entries);
			}
		}

		return result;
	}
	/**
	 * Sets up event listeners to record various Ad Library events
	 *
	 * @memberof timings
	 * @private
	 */

	function setupEventListeners() {
		const eventConfig = config.events;
		// eslint-disable-next-line no-restricted-syntax
		for (const eventKey in eventConfig) {
			if (Object.prototype.hasOwnProperty.call(eventConfig, eventKey)) {
				const { record, once, event } = eventConfig[eventKey];
				if (event && event.gpt) {
					moduleManager.viaModule(GOOGLE_PUBLISHER_TAG, ({ gptAction }) => {
						gptAction((gpt) => {
							gpt.pubads().addEventListener(event.name, () => {
								if (!eventTracking[eventKey]) {
									addTimingRecord({
										type: 'timing',
										timingType: 'mark',
										...record,
										amount: dom().window.performance.now(),
									});
									eventTracking[eventKey] = true;
								}
							});
						});
					});
				} else {
					const subscription = eventEmitter.on(
						eventKey,
						(arg1) => {
              if(record.slug === AD_DENSITY.SLUG) {
                addTimingRecord({
                  type: 'timing',
                  timingType: 'mark',
                  ...record,
                  ...arg1,
                  amount: dom().window.performance.now(),
                });
              } else {
                addTimingRecord({
                  type: 'timing',
                  timingType: 'mark',
                  ...record,
                  amount: dom().window.performance.now(),
                });
             }
						},
						once || false
					);
					listeners.push(subscription);
				}

			}
		}
	}
	// function setupEventListeners() {
	//   const eventConfig = config.events;

	//   Object.entries(eventConfig).forEach(([eventKey, eventValue]) => {
	//     const { record, once, event } = eventValue;
	//     if (event && event.gpt) {
	//       moduleManager.viaModule(GOOGLE_PUBLISHER_TAG, ({ gptAction }) => {
	//         gptAction(gpt => {
	//           gpt.pubads().addEventListener(event.name, () => {
	//             if (!eventTracking[eventKey]) {
	//               addTimingRecord({
	//                 type: 'timing',
	//                 timingType: 'mark',
	//                 ...record,
	//                 amount: dom().window.performance.now(),
	//               });
	//               eventTracking[eventKey] = true;
	//             }
	//           });
	//         });
	//       });
	//     } else {
	//       const subscription = eventEmitter.on(eventKey, () => {
	//         addTimingRecord({
	//           type: 'timing',
	//           timingType: 'mark',
	//           ...record,
	//           amount: dom().window.performance.now(),
	//         });
	//       }, once || false);
	//       listeners.push(subscription);
	//     }
	//   });
	// }
	/**
	 * Records a single script's records
	 *
	 * @param {PerformanceResourceTiming} entry A script's resource timing
	 * @param {Object} item Item Record object
	 * @memberof timings
	 * @private
	 */
	function recordScript(entry, item = null) {
    // eslint-disable-next-line no-underscore-dangle
		const _item = item || scriptCfg.items.find((v) => v.match.test(entry.name));
    // tLogger.logInfo("recordScript: ", entry, _item);
		if (_item) {
			addTimingRecord({
				type: 'timing',
				timingType: 'mark',
				name: `${_item.record.name} Loaded`,
				slug: _item.record.slug,
				amount: entry.responseEnd,
			});
			addTimingRecord({
				type: 'timing',
				timingType: 'measure',
				name: `${_item.record.name} Load Duration`,
				slug: `${_item.record.slug}-duration`,
				amount: entry.duration,
			});
		}
	}
	/**
	 * Setup function for handling utag timing
	 *
	 * @memberof timings
	 * @private
	 */
	function setupUtag() {
		utagHandling.timer = setTimeout(() => {
			if (utagHandling.lastEntry) {
				recordScript(utagHandling.lastEntry, {
					record: {
						type: 'timing',
						timingType: 'measure',
						name: 'Time to UTag',
						slug: 'script-utag',
					},
				});
				utagHandling.reported = true;
			}
		}, 5000);
	}
	/**
	 * Resets the utag handling process due to a new entry being found
	 *
	 * @param {PerformanceResourceTiming} entry Most recent UTag entry
	 * @memberof timings
	 * @private
	 */
	function resetUtag(entry) {
		if (utagHandling.reported) return;
		if (utagHandling.timer) {
			clearTimeout(utagHandling.timer);
		}
		utagHandling.lastEntry = entry;
		setupUtag();
	}
	/**
	 * Handles recording resource entries
	 *
	 * @param {Array|Performance} updatedList Array or Performance object to extract the entries from
	 * @memberof timings
	 * @private
	 */
	function recordResourceCheck(updatedList) {
		const entries = updatedList.getEntries ? updatedList.getEntries() : updatedList;
		for (let index = 0; index < entries.length; index += 1) {
			const entry = entries[index];
			if (entry.entryType === 'resource' && entry.initiatorType === 'script') {
				if (scriptCfg.matchPartRe && scriptCfg.matchPartRe.test(entry.name)) {
          // tLogger.logInfo("script: ", entry.name, scriptCfg.matchPartRe.test(entry.name));
					recordScript(entry);
				} else if (config.utag && /(tags\.tiqcdn\.com\/utag\/cbsi)/gm.test(entry.name)) {
					resetUtag(entry);
				} else if (scriptCfg.matchManual.length > 0) {
					const hasManualMatch = scriptCfg.matchManual.find((v) => v.match.test(entry.name));
					if (hasManualMatch) {
						recordScript(entry, hasManualMatch);
					}
				}
			}
		}
	}
	/**
	 * Sets up script measurement handling
	 *
	 * @param {Object} scriptConfigs script configuration to measure
	 * @memberof timings
	 * @private
	 */
	function setupScriptsToMeasure(scriptConfigs) {
    const matchParts = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const scriptSlug in scriptConfigs) {
        if (Object.prototype.hasOwnProperty.call(scriptConfigs, scriptSlug)) {
            const cfg = scriptConfigs[scriptSlug];
            cfg.record = {
                ...scriptCfg.defaultRecordData,
                ...cfg.record
            }
            if(cfg.matchPart){
                matchParts.push(cfg.matchPart);
                cfg.match = new RegExp(`(${cfg.matchPart})`, 'gm')
            } else {
                cfg.match = new RegExp(...(Array.isArray(cfg.match) ? cfg.match : [cfg.match, 'gm']))
                scriptCfg.matchManual.push(cfg);
            }
            scriptCfg.items.push(cfg);
        }
    }
    scriptCfg.matchPartRe = new RegExp(`(${matchParts.join("|")})`,'gm');
	}
	/**
	 * Exposed function getter for timings
	 * @memberof timings
	 * @exposedAs getAnalyticsTimings
	 * @private
	 */
	function getTimings() {
		return timings;
	}
	/**
	 * Initialization function for module
	 *
	 * @memberof timings
	 * @private
	 */
	function initialize() {
		setupSequences();
		setupEventListeners();
		if (!dom().window.performance.getEntries) return;
		setupScriptsToMeasure(config.scripts);
		if (config.utag) {
			setupUtag();
		}
		getIncompleteEntryTypes(['resource'], recordResourceCheck);
		// eslint-disable-next-line no-undef
		resourceObserver = new PerformanceObserver(recordResourceCheck);

		// Entry Types: https://w3c.github.io/timing-entrytypes-registry/#registry
		resourceObserver.observe({ entryTypes: ['resource'] });
		// initTime = win.performance.now();

		postInitQueue.run();

    // fire off ad density calculation 1000 ms after ad call is fired
    eventEmitter.on( "adCallFired", () => { setTimeout(() => {getAdDensityObj(true) }, 3000) });

    // add some markers to the console to make debugging easier.
    eventEmitter.on( "auction", (unitConfig) =>{tLogger.logInfo("Marker: Page fired Auction request", unitConfig)});
    $$PREBID_GLOBAL$$.que.push(() => {
      $$PREBID_GLOBAL$$.onEvent('userIdsAdded', (adUnits, initSubmodules)=>{tLogger.logInfo("Marker: UserIds added", adUnits, initSubmodules)});
    });
    eventEmitter.on( "bidsRequested", (unitConfig)=>{tLogger.logInfo("Marker: Bids requested", unitConfig)});
    eventEmitter.on( "biddingDone", (unitConfig)=>{tLogger.logInfo("Marker: Bidding done", unitConfig)});
    eventEmitter.on( "adCallFired", (slots) => {tLogger.logInfo("Marker: Ad call fired", slots)});
	}
	/**
	 * Teardown function for module
	 *
	 * @memberof timings
	 * @private
	 */
	function deregister() {
		for (let index = 0; index < listeners.length; index += 1) {
			const unsubscribe = listeners[index];
			unsubscribe();
		}
		resourceObserver.disconnect();
	}
	// Expose method
	exposureApi.expose({
		getAnalyticsTimings: getTimings,
    getAdDensityObj,
	});

	return {
		initialize,
		register,
		deregister,
		addTimingRecord,
		getIncompleteEntryTypes,
		name: ANALYTICS_TIMINGS,
    getAdDensityObj: () => getAdDensityObj()
	};
})();

export const timingsAnalyticsHandler = moduleManager.register(timingsHandler, [BIDBARREL_ANALYTICS]);
export default timingsAnalyticsHandler;
