import { eventEmitter } from '../../../events.js';
import CONSTANTS from '../../../constants.json';
import { moduleManager } from '../../../moduleManager.js';
import { dom } from '../../../global.js';
import { getUnits } from '../../../unitManager.js';

const {
	EVENTS: { HEADER_BIDDING_RESPONSE, HEADER_BIDDING_REQUEST, IDENTIFIED, REFRESH },
	MODULES: { BIDBARREL_ANALYTICS, AMAZON_HEADER_BIDDING, ANALYTICS_AMAZON },
	ANALYTICS_RECORD_TYPES: { NO_BID, BID, BID_USED },
} = CONSTANTS;

/**
 * Handler module for Amazon Bid Analytics recording
 *
 * @module amazonAnalyticsHandler
 * @private
 */
export const amazonHandler = (() => {
	/**
	 * Variable for tracking the event listeners for easy teardown
	 *
	 * @memberof amazonAnalyticsHandler
	 * @private
	 */
	let listeners;
	/**
	 * Mapping object to track currently processing bids for a specific ad slot
	 *
	 * @memberof amazonAnalyticsHandler
	 * @private
	 */
	const bidMapping = {};
	/**
	 * Request timestamp to allow tracking of inflight bid request start
	 *
	 * @memberof amazonAnalyticsHandler
	 * @private
	 */
	let reqTimestamp = 0;
	/**
	 * Request timestamp to allow tracking of inflight bid request duration
	 *
	 * @memberof amazonAnalyticsHandler
	 * @private
	 */
	let reqTime = 0;

	/**
	 * Handles parsing and recording amazon bids
	 *
	 * @param {AmazonBid[]} amznBids Array of amazon bids or analytics record bid(when bid_used)
	 * @param {String} [passedType=null] record type
	 * @memberof amazonAnalyticsHandler
	 * @private
	 */
	function recordAmazonBids(amznBids, passedType = null) {
		let type = passedType;
		// ['adUnitCode', 'bidder', 'bidderCode', 'adId', 'pbCg', 'requestTimestamp', 'responseTimestamp', 'statusMessage', 'height', 'width', 'timeToRespond'];
		const amznRespTimestamp = new Date().getTime();
		const amznRespTime = dom().window.performance.now();
		for (let index = 0; index < amznBids.length; index += 1) {
			let record;
			if (type !== BID_USED) {
				const { size, slotID, mediaType } = amznBids[index];
				const { amznp, amzniid, amznbid, amznsz } = amznBids[index].targeting;
				type = ['0', '1', '2'].indexOf(amznp) >= 0 ? NO_BID : BID;
				const wrapperSource = 'amazon';
				if (type === NO_BID) {
					record = {
						type,
						wrapperSource,
						bidder: amznp,
						adUnitCode: slotID,
						createTimestamp: new Date().getTime(),
					};
				} else {
					// const statusMessage = type === NO_BID ? statuses[amznp] : "Bid Available"
					let width;
					let height;
					if (mediaType === 'video') {
						width = 640;
						height = 480;
					} else if (typeof size !== 'undefined') {
						[width, height] = size.split('x').map((v) => parseInt(v, 10));
					} else if (typeof amznsz !== 'undefined') {
						[width, height] = amznsz.split('x').map((v) => parseInt(v, 10));
					}
					record = {
						type,
						wrapperSource,
            mediaType,
						bidder: amznp,
						adUnitCode: slotID,
						bidderCode: amznp,
						cpm: amznbid,
						createTimestamp: new Date().getTime(),
						adId: amzniid,
						statusMessage: 'Bid Available',
						width,
						height,
						pbCg: amznbid,
						requestTimestamp: reqTimestamp,
						responseTimestamp: amznRespTimestamp,
						timeToRespond: Math.round(amznRespTime - reqTime),
					};
				}
				bidMapping[slotID] = record;
			} else {
				record = amznBids[index];
				record.type = BID_USED;
			}
			moduleManager.viaModule(BIDBARREL_ANALYTICS, ({ addRecord }) => {
				if ([BID, BID_USED].indexOf(record.type) === -1) {
					addRecord(record);
				} else if (record.type === BID) {
          if(record.mediaType === 'video'){
            addRecord(record);
          } else {
            eventEmitter.on(
              `${record.adUnitCode}.${REFRESH}`,
              (unit) => {
                addRecord({ ...record, impId: unit.targeting.iid });
              },
              true
            );
          }
				} else {
					const unit = getUnits()[record.adUnitCode];
					addRecord({
						...record,
						impId: unit.targeting.iid,
					});
				}
			});
		}
	}
	/**
	 * Status mappings for Amazon bids
	 *
	 * @memberof amazonAnalyticsHandler
	 * @private
	 */
	// const statuses = {
	//     "0": "APS Server Request has not been made",
	//     "1": "DFP called before Bid Targeting set",
	//     "2": "No bid available"
	// }
	/**
	 * Registers all listeners need for module to work
	 *
	 * @memberof amazonAnalyticsHandler
	 * @private
	 */
	function addListeners() {
		// eslint-disable-next-line no-unused-vars
		const reqListener = eventEmitter.on(`${AMAZON_HEADER_BIDDING}.${HEADER_BIDDING_REQUEST}`, (bidderCode, units, bids) => {
			reqTimestamp = new Date().getTime();
			reqTime = dom().window.performance.now();
		});
		const resListener = eventEmitter.on(`${AMAZON_HEADER_BIDDING}.${HEADER_BIDDING_RESPONSE}`, (bidderCode, units, bids) => {
			recordAmazonBids(bids);
		});
		const identityListener = eventEmitter.on(IDENTIFIED, (identity) => {
			if (identity.provider === 'amazon') {
				recordAmazonBids([bidMapping[identity.code]], BID_USED);
			}
		});
		listeners = [reqListener, resListener, identityListener];
	}

	/**
	 * Module registration function
	 *
	 * @memberof amazonAnalyticsHandler
	 * @private
	 */
	function register() {
		moduleManager.viaModule(AMAZON_HEADER_BIDDING, () => {
			addListeners();
		});
	}
	/**
	 * Module teardown function. Unsubscribes from all event listeners
	 *
	 * @memberof amazonAnalyticsHandler
	 * @private
	 */
	function deregister() {
		for (let index = 0; index < listeners.length; index += 1) {
			const unsubscribe = listeners[index];
			unsubscribe();
		}
		listeners = [];
	}

	return {
		register,
		deregister,
		name: ANALYTICS_AMAZON,
	};
})();
// Registers module
export const amazonAnalyticsHandler = moduleManager.register(amazonHandler, [BIDBARREL_ANALYTICS, AMAZON_HEADER_BIDDING]);
