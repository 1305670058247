/**
 * Simple object check.
 * @param item
 * @returns {boolean}
 */
function isObject(item) {
  return (item && typeof item === 'object' && !Array.isArray(item));
}

/**
 * Merge two objects deeply.
 *
 * @param {* = {} } target
 * @param  {...any} sources
 * @returns
 */
// eslint-disable-next-line import/prefer-default-export
export function mergeDeep(target = {}, ...sources) {
  if (!sources.length) return target;
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    Object.entries(source).forEach(([key, value]) => {
      if (isObject(value)) {
        if (!target[key]) Object.assign(target, { [key]: {} });
        mergeDeep(target[key], value);
      } else {
        Object.assign(target, { [key]: value });
      }
    });
  }

  return mergeDeep(target, ...sources);
}

