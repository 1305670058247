import { moduleManager } from '../../moduleManager.js';
import { bidCache } from '../../services/bidCache.js';
import { bbLogger } from '../../utilities/logger.js';
import { adServerRequest } from '../../bidbarrel.js';
import { getConfig } from '../../config.js';
import { dom } from '../../global.js';

import CONSTANTS from '../../constants.json';
import { elementSort } from '../../utilities/elementSort.js';

const { AUTO_PRIORITY } = CONSTANTS.MODULES;

/**
 * Auto Priority Order
 *
 * Automatically assigns priority order based upon scroll value
 *
 * @module AutoPriority
 * @private
 */
const autoPriorityBase = (function ap() {
	/**
	 * General config for module
	 *
	 * @memberof AutoPriority
	 * @private
	 */
	let config = { enabled: true, refresh: true, bids: true };
	/**
	 * Holds the current scroll value
	 *
	 * @memberof AutoPriority
	 * @private
	 */
	let scrollY = 0;
	/**
	 * Registers the module and assigns the scrollY value based upon scroll event
	 *
	 * @private
	 * @memberof AutoPriority
	 */
	function register() {
		getConfig("autoPriority", value => {
			config = value;
		})
		dom().window.document.addEventListener('scroll', () => {
			scrollY = dom().window.pageYOffset || dom().window.document.documentElement.scrollTop;
		});
	}

/**
	 * Called when a refresh sort is needed
	 *
	 * @param {BidBarrel~AdUnit[]} unitCollection The entire collection of ad units from BidBarrel
	 * @returns {BidBarrel~AdUnit[]}
	 * @private
	 * @memberof AutoPriority
	 */
function autoSortCollection(unitCollection) {
  return elementSort(unitCollection, getConfig('priorityAxis'));
}
  /**
	 * If Auto Priority config is set to true for refresh, get the newly sorted units from the auto sorting function.
	 *
	 * @param {function} next Function to move to the next callback in the hook
	 * @param {BidBarrel~AdUnit[]} unitCollection The entire collection of ad units from BidBarrel
	 * @param {function} resolve /core/utilities/queue.js:onResolve
	 * @private
	 * @memberof AutoPriority
	 */
	function refreshPriorityHook(next, unitCollection, resolve) {
		const sortedCollection = autoSortCollection(unitCollection);
		next(sortedCollection, resolve);
	}
  /**
	 * Sorts unit codes by distance from scrollY var
	 *
	 * @param {string} unitACode the element id for unit A
	 * @param {string} unitBCode the element id for unit B
	 * @returns {number} the offset location of the current evaluation
	 * @private
	 * @memberof AutoPriority
	 */
	function autoSort(unitACode, unitBCode) {
		const elA = dom().window.document.getElementById(unitACode);
		const elB = dom().window.document.getElementById(unitBCode);

		const elATopBound = elA.getBoundingClientRect().top;
		const elBTopBound = elB.getBoundingClientRect().top;

		const elAScrollPosDiff = elATopBound - scrollY;
		const elBScrollPosDiff = elBTopBound - scrollY;

		// bbLogger.logInfo('sorting', unitACode, unitBCode, elAScrollPosDiff, elBScrollPosDiff, elATopBound, elBTopBound);
		return elAScrollPosDiff - elBScrollPosDiff > 0 ? 1 : -1;
	}
  /**
	 * If Auto Priority config is set to true for bids, get the newly sorted units from the auto sorting function.
	 *
	 * @param {function} next Function to move to the next callback in the hook
	 * @param {String[]} unitCodes Unit codes passed from prebid
	 * @private
	 * @memberof AutoPriority
	 */
	function bidPriorityHook(next, unitCodes) {
		const shouldNotSort = unitCodes.some(code => {
      // video ad units have --# appended to the end of the id
      const id = code.match(/.*--\d+$/) ? code.slice(0, -3) : code;
      const el = dom().window.document.getElementById(id);
			if (!el) return true;
			return el.style.display === 'none';
		});
		if (shouldNotSort) {
			bbLogger.logMessage(
				`Ad unit div not found or has a style of display:none;. Autoprioritization for Bid Priority has been turned off for this evaluation. Please check your divs.`,
				unitCodes
			);
			next(unitCodes);
		} else {
			next(unitCodes.sort(autoSort));
		}
	}

	/**
	 * Initializes module and calls the appropriate function if Auto Priority config is true for bids or refresh
	 *
	 * @private
	 * @memberof AutoPriority
	 */
	function initialize() {
		if (!config.enabled) return;

		if (config.refresh) {
			adServerRequest.before(refreshPriorityHook);
		}

		if (config.bids) {
			bidCache.applyPriorityOrder.after(bidPriorityHook);
		}
	}



	/**
	 * Cleans up remnants of module
	 *
	 * @private
	 * @memberof AutoPriority
	 */
	function deregister() {
		adServerRequest.getHook({ hook: refreshPriorityHook }).remove();
		bidCache.applyPriorityOrder.getHook({ hook: bidPriorityHook }).remove();
	}
	return {
		name: AUTO_PRIORITY,
		register,
		initialize,
		deregister
	};
})();

export const autoPriorityModule = moduleManager.register(autoPriorityBase);
export default autoPriorityModule;
