import { makeSafe } from './safeFunction';
import isEmpty from './helpers/isEmpty';
import { bbLogger } from './logger';

export const gateway = function(name, initialGates = []) {
	const _name = name;
	const _gates = {};
	const _callbacks = {all:[]};

	if(initialGates.length > 0){
		for (let index = 0; index < initialGates.length; index++) {
			const gateName = initialGates[index];
			register(gateName);
		}
	}

	function register(gateName) {
		_gates[gateName] = false;
		_callbacks[gateName] = [];
	}

	function open(gateName) {
		if (typeof _gates[gateName] === 'undefined') return;
		_gates[gateName] = true;
		bbLogger.atVerbosity(4).logInfo(`Opening gate ${_name}.${gateName}`);
		runCallbacks(gateName);
		if (Object.values(_gates).indexOf(false) === -1) {
			runCallbacks('all');
		}
	}

	function onOpen(callback, providedGateName = 'all') {
		const gateName = providedGateName || 'all';
		if ((gateName !== 'all' && typeof _gates[gateName] === 'undefined') || (gateName === 'all' && isEmpty(_gates)) || (gateName !== 'all' && typeof _callbacks[gateName] === 'undefined') || _gates[gateName] === true) {
			bbLogger.atVerbosity(4).logInfo(`Running Callback ${_name}.${gateName}`);
			makeSafe(() => callback());
			return;
		}
		_callbacks[gateName].push(callback);
	}

	function runCallbacks(gateName = 'all') {
		if (!_callbacks[gateName]) return;
		for (let index = 0; index < _callbacks[gateName].length; index++) {
			const callback = _callbacks[gateName][index];
			makeSafe(() => callback());
		}
		_callbacks[gateName] = [];
	}

	function reset() {
		for (const key in _gates) {
			if (_gates.hasOwnProperty(key)) {
				_gates[key] = false;
			}
		}
	}

	function isOpen(gateName){
		return _gates[gateName] !== false;
	}

	return {
		onOpen,
		register,
		open,
		getGates: () => _gates,
		isOpen,
		reset,
		name: _name
	};
};