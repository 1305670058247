import cookies from 'js-cookie';
import GLOBAL_CONFIG from '../../../../configs/global-config.js';
import { getConfig } from '../../../config.js';
import defaultsDeep from '../../../utilities/helpers/defaultsDeep.js';
import get from '../../../utilities/helpers/get.js';
import omit from '../../../utilities/helpers/omit.js';
import { hookedFn } from '../../../utilities/hookedFunction.js';
import { bbLogger } from '../../../utilities/logger.js';
// eslint-disable-next-line import/named
import { richObject } from '../../../utilities/richObject.js';
import { getDate } from '../../../utilities/time.js';


/**
 * Cookie storage engine
 *
 * @module cookieEngine
 * @private
 */
// eslint-disable-next-line import/prefer-default-export
export const cookieStorageEngine = (function cse (){

    /**
     * Underlying rich object to facilitate data updates
     *
     * This allows the engine to still operate even when the underlying tech isn't available or enabled(consent)
     *
     * @memberof cookieEngine
     * @private
     */
    const data = richObject({});
    /**
     * Engine configuration object
     *
     * @memberof cookieEngine
     * @private
     */
    let config = GLOBAL_CONFIG.cookie;
    /**
     * Underlying method for setting cookie value
     *
     * @param {string} key cookie key or alias
     * @memberof cookieEngine
     * @private
     */
     function coreSetCookie(key, value, options){
      if(!key) return;
      const cookieConfig = get(config, key);
      if(cookieConfig && cookieConfig.readOnly) return;
      const cookieKey = cookieConfig ? cookieConfig.cookieName : key;
      let cookieValue = value;
      if(cookieConfig && cookieConfig.serialize){
          if(cookieConfig.serialize.match(value)){
              cookieValue = cookieConfig.serialize.process(value);
          }
      } else {
          cookieValue = typeof cookieValue === "object" ? JSON.stringify(cookieValue) : cookieValue;
      }
      const setter = getConfig(`targeting.cookie.setters.${cookieKey}`);
      if(setter){
          setter(cookieKey, cookieValue, options.expires, options.path, options.domain);
      } else {
          bbLogger.atVerbosity(3).logInfo(`set cookie key=${cookieKey} value=${cookieValue}`, options);
          cookies.set(cookieKey, cookieValue, options);
          if(value){
              data.setValue(key, value, {id: 'internalSet'})
          }
      }
  }
  /**
   * Underlying method for getting cookies
   *
   * @param {string} key cookie key or alias
   * @memberof cookieEngine
   * @returns {*} cookie value or deserialized value
   * @private
   */
  function coreGetCookie(key){
      if(!key) return undefined;
      const cookieConfig = get(config, key);
      const cookieKey = cookieConfig ? cookieConfig.cookieName : key;
      const getter = getConfig(`targeting.cookie.getters.${cookieKey}`);
      if(getter){
          return getter(cookieKey);
      }

          let value = cookies.get(cookieKey);
          if(cookieConfig && cookieConfig.deserialize){
              if(cookieConfig.deserialize.match(value)){
                  value = cookieConfig.deserialize.process(value);
              }
          }
          bbLogger.atVerbosity(3).logInfo(`get cookie key=${cookieKey} value=`, value);
          if(value){
              data.setValue(key, value, {id: 'internalSet'});
          }
          return value;

  }

    /**
     * Hookable method for setting cookies
     *
     * @param {string} key cookie key
     * @param {object} value cookie value
     * @param {object} options js-cookie options [read more](https://github.com/js-cookie/js-cookie#cookie-attributes)
     * @memberof cookieEngine
     * @private
     */
    const setCookie = hookedFn('sync', coreSetCookie);
    /**
     * Hookable method for getting cookies
     *
     * @param {string} key cookie key
     * @param {object} value cookie value
     * @param {object} options js-cookie options [read more](https://github.com/js-cookie/js-cookie#cookie-attributes)
     * @memberof cookieEngine
     * @private
     */
    const getCookie = hookedFn('sync', coreGetCookie);

    /**
     * Method for cookie deletion
     *
     * @param {string} key cookie key or alias
     * @param {object} options js-cookie options [read more](https://github.com/js-cookie/js-cookie#cookie-attributes)
     * @memberof cookieEngine
     * @private
     */
    function deleteCookie(key, options){
        const cookieConfig = get(config, key);
        const cookieKey = cookieConfig ? cookieConfig.cookieName : key;
        const deleter = getConfig(`targeting.cookie.deleters.${cookieKey}`);
        if(deleter){
            deleter(cookieKey, options.expires, options.path, options.domain);
        } else {
            cookies.remove(cookieKey, options);
            data.deleteKey(key, {id: 'internalSet'});
        }
    }
 /**
     * Extracts existing cookie values
     *
     * @memberof cookieEngine
     * @private
     */
  function pullValueFromCookies(){
    const initialValue = omit(config, ['defaultOptions']);
    // eslint-disable-next-line no-restricted-syntax
    for (const propertyName in initialValue) {
        if (Object.prototype.hasOwnProperty.call(initialValue, propertyName)) {
            getCookie(propertyName);
        }
    }
}

    /**
     * Method for setting up this engine
     *
     * @memberof cookieEngine
     * @private
     */
    function setup(){
        getConfig("cookie",(newValue) => {
            config = newValue;
            pullValueFromCookies();
        })
        // data.getValue("*", (newValue, setOpts) => {
        //     if(!setOpts || (['cookieStorageUpdate','initialSet','internalSet'].indexOf(setOpts.id) === -1)){
        //         const meta = data.getMeta();
        //         for (const cookieKey in newValue) {
        //             if (Object.prototype.hasOwnProperty.call(newValue, cookieKey)) {
        //                 const cookieValue = newValue[cookieKey];
        //                 const options = getOptions(cookieKey, meta);
        //                 setCookie(cookieKey, cookieValue, options);
        //                 // localStorageEngine.setValue(`cookies:${cookieKey}.opts`, options);
        //             }
        //         }
        //     }
        // })
    }

    /**
     * Defaults the options for a given cookie
     *
     * @param {string} key cookie key or alias
     * @param {object} allMeta richObject metadata
     * @memberof cookieEngine
     * @private
     */
    function getOptions(key, allMeta = data.getMeta()){
        const cookieConfig = get(config, key);
        const defaultOptions = defaultsDeep({}, get(cookieConfig,'defaultOptions', {}), config.defaultOptions, {
            expires: null,
            path: null,
            domain: null
        })

        const meta = allMeta[key] || {};

        if(defaultOptions.expires && Object.prototype.toString.call(defaultOptions.expires) !== '[object Date]'){
            defaultOptions.expires = getDate(defaultOptions.expires);
        }

        if(meta.expires){
            meta.expires = new Date(meta.expires);
        }

        return {
            ...defaultOptions,
            ...meta
        }
    }
    /**
     * Wraps the richObject.setValue to apply business logic
     *
     * @param {string|object} topic topic or full value
     * @param {*} value value for a given topic
     * @param {object} setOptions options for setting the value
     * @memberof cookieEngine
     * @private
     */
    function setValue(topic, value, setOptions){
        let modifiedOptions = setOptions;
        if(modifiedOptions){
            const optionsExtended = {expires:{id: `cookies.${topic}`}};
            modifiedOptions = defaultsDeep(modifiedOptions, optionsExtended);
        }
        data.setValue(topic, value, modifiedOptions);
        setCookie(topic, value, getOptions(topic))
    }
    /**
     * Wrapper method for richObject.getValue
     *
     * @param  {...any} args
     * @memberof cookieEngine
     * @private
     */
    function getValue(...args){
        return data.getValue(...args) || getCookie(...args);
    }

    return {
        name: 'cookie',
        async: false,
        setup,
        setValue,
        getValue,
        setCookie,
        getCookie,
        deleteCookie,
        raw: {
            getValue: getCookie,
            setValue: setCookie
        }
    }
})();
