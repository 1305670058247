import { localStorageEngine } from "./storage/engines/localStorage";
import { cookieStorageEngine } from "./storage/engines/cookie";
import { eventEmitter } from "../events";
import { getConfig } from "../config";

/**
 * Storage Service
 * 
 * Facilitates all usage of localStorage and cookies
 * 
 * @module storage
 * @private
 */
export const storage = (function(){
    /**
     * Setup method to facilitate consent restrictions
     * 
     * @memberof storage
     * @private
     */
    function setup(){
        const consent = getConfig("consent");
        if(typeof consent === "undefined" || consent === true){
            enable();
        } else if(consent === false){
            eventEmitter.on("consentChanged", consentGiven => {
                if(consentGiven){
                    enable();
                }
            })
        }
    }
    /**
     * Method to enable all engines
     * 
     * @memberof storage
     * @private
     */
    function enable(){
        localStorageEngine.setup();
        cookieStorageEngine.setup();
    }

    return {
        setup,
        getLocalStorage: localStorageEngine.getValue,
        getLs: localStorageEngine.getValue,
        setLocalStorage: localStorageEngine.setValue,
        setLs: localStorageEngine.setValue,
        deleteLs: localStorageEngine.deleteKey,
        getCookie: cookieStorageEngine.getValue,
        setCookie: cookieStorageEngine.setValue,
        deleteCookie: cookieStorageEngine.deleteCookie,
        raw: {
            getCookie: cookieStorageEngine.raw.getValue,
            setCookie: cookieStorageEngine.raw.setValue,
            getLs: localStorageEngine.raw.getValue,
            setLs: localStorageEngine.raw.setValue,
            deleteFromLs: localStorageEngine.raw.deleteKey
        }
    };
})();