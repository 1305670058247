import { makeSafe } from "./safeFunction";
import defaultsDeep from "./helpers/defaultsDeep";
import isEmpty from "./helpers/isEmpty";

const defaultMergeOptions = {
    overwrite: false,
    arguments: []
}

export function reduceMerger(currentValue, mergeSet, providedOptions){
    let {overwrite, ...options} = {...defaultMergeOptions, ...providedOptions};
    let modifiedValue = currentValue;
    if(modifiedValue === null){
        modifiedValue = null;
    } else if(Array.isArray(mergeSet)){
        for (let index = 0; index < mergeSet.length; index++) {
            const mergeSetItem = mergeSet[index];
            modifiedValue = makeSafe(() => reduceMerger(modifiedValue, mergeSetItem, options));
            if(modifiedValue === null) {
                break;
            } else if(typeof modifiedValue === "undefined"){
                modifiedValue = undefined;
                break;
            }
        }        
    } else if(typeof mergeSet === "object" && overwrite) {
        modifiedValue = mergeSet;
    } else if(typeof mergeSet === "object") {
        modifiedValue = defaultsDeep({}, modifiedValue, mergeSet);
    } else if(typeof mergeSet === "function") {
        const result = makeSafe(() => mergeSet(modifiedValue, ...(options.arguments || [])))
        if(Array.isArray(result) && !isEmpty(modifiedValue)){
            modifiedValue = [modifiedValue, ...result];
        } else {
            modifiedValue = result;
        }
    }

    return modifiedValue;
}

export function createReduceMerger(configValue){
    let mergeSet = configValue || [];
    function addConfig(item){
        let newItem = item;
        if(newItem === null || typeof newItem === "undefined"){
            return;
        }
        if(!Array.isArray(newItem)){
            newItem = [item];
        }
        if(Array.isArray(mergeSet)){
            mergeSet = [...mergeSet, ...newItem];
        } else {
            mergeSet = [mergeSet, ...newItem];
        }
    }
    function getMergeSet(){
        return mergeSet;
    }
    function processObject(existingObj = {}, options){
        return reduceMerger(existingObj, mergeSet, options);
    }

    return {
        addConfig,
        processObject,
        getMergeSet
    }
}