
const defaultResolver = (...args) => args[0]

export default function memoize(fn, resolver = defaultResolver){
    const cache = {};
    return (...args) => {
        const key = resolver(...args);
        if(!cache[key]){
            cache[key] = fn(...args);
        }
        return cache[key];
    }
}