import defaultsDeep from './utilities/helpers/defaultsDeep.js';
import { makeSafe } from './utilities/safeFunction.js';
import { logger, bbLogger } from './utilities/logger.js';
import { dom } from './global.js';
import { readOnlyGetter } from './utilities/readOnly.js';
// Logger
const eaLogger = logger({ name: 'ExposureApi', bgColor: '#F4B835' });
/**
 * The BidBarrel object
 *
 * @public
 * @namespace {Object} BidBarrel
 */
const globalVar = {};
/**
 * The Secret object
 *
 * @private
 * @namespace {Object} viacbs_at
 */
const secretVar = {};
/**
 * Exposure API
 *
 * This handles exposing methods off of BidBarrel.exposedApi() and the BidBarrel class itself
 *
 * @module exposureApi
 * @private
 */
export const exposureApi = (() => {
	/**
	 * Object that tracks exposed methods
	 *
	 * @memberof exposureApi
	 * @private
	 */
	let exposedApi = {};
	/**
	 * Internal var for tracking deprecated methods
	 *
	 * @memberof exposureApi
	 * @private
	 * @expose readonly
	 */
	const deprecatedMethods = [];
	/**
	 * Object that tracks the exposed secret methods
	 *
	 * @memberof exposureApi
	 * @private
	 * @expose readonly
	 */
	let secretApi = {};
	/**
	 * Method to allow for method/property exposure
	 *
	 * @param {Object|Function} objOrFunction
	 * @param {boolean} isSecret
	 * @memberof exposureApi
	 * @private
	 */
	// eslint-disable-next-line no-shadow
	function expose(objOrFunction, isSecret = false) {
		if (typeof objOrFunction === 'function' && !isSecret) {
			makeSafe(() => {
				if(isSecret){
					secretApi = objOrFunction(secretApi);
				} else {
					exposedApi = objOrFunction(exposedApi);
				}
			}, eaLogger.logError);
		} else if(isSecret){
				secretApi = defaultsDeep({}, objOrFunction, secretApi);
			} else {
				exposedApi = defaultsDeep({}, objOrFunction, exposedApi);
			}
		if(isSecret){
      Object.keys(secretApi).forEach(key => {
        if (Object.prototype.hasOwnProperty.call(secretApi, key)) {
					secretVar[key] = secretApi[key];
				}
      });
		} else {
      Object.keys(exposedApi).forEach(key => {
        if (Object.prototype.hasOwnProperty.call(exposedApi, key)) {
					globalVar[key] = exposedApi[key];
				}
      });
		}
	}
	/**
	 * Method to allow exposing of methods off of the root scope
	 *
	 * @param {Object} obj object of exposed key/value properties
	 * @param {boolean} isSecret
	 * @memberof exposureApi
	 * @private
	 */
	function rootScope(obj, isSecret = false) {
    Object.keys(obj).forEach(key => {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
				if(isSecret){
					secretVar[key] = obj[key];
				} else {
					globalVar[key] = obj[key];
				}
			}
    });
	}
  /**
	 * Allows definition of single getter for global scope
	 *
	 * @param {String} key Property name
	 * @param {String} getter Getter function
	 * @param {boolean} isSecret
	 * @private
	 * @memberof exposureApi
	 */
	function rootScopeGetter(key, getter, isSecret = false){
		if(isSecret){
			readOnlyGetter(secretVar, key, getter, `Cannot set read-only property $$BB_SECRET_VAR$$.${key}.`)
		} else {
			readOnlyGetter(globalVar, key, getter, `Cannot set read-only property $$BB_VAR$$.${key}.`)
		}
	}
	/**
	 * Allows for definitions of getters on the root scope object
	 *
	 * @param {Object} obj passed in reference object
	 * @param {boolean} isSecret
	 * @private
	 * @memberof exposureApi
	 */
	function rootScopeGetters(obj, isSecret = false){
    Object.keys(obj).forEach(key => {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
				rootScopeGetter(key, obj[key], isSecret);
			}
    });
	}
	/**
	 * Binds the global variable to the window object
	 *
	 * @private
	 * @memberof exposureApi
	 */
	function bindToWindow(){
		dom().window.$$BB_VAR$$ = globalVar;
		dom().window.$$BB_SECRET_VAR$$ = secretVar;
	}
	/**
	 * Method getter for the exposed api
	 *
	 * @memberof exposureApi
	 * @returns {Object}
	 * @private
	 * @method
	 * @exposed
	 * @exposedAs exposedApi()
	 */
	function api() {
		return exposedApi;
	}
	/**
	 * Method deprecation assistive function
	 *
	 * @param {String[]} methods Array of method names that are deprecated
	 * @param {String} version Version at which they will be deprecated
	 * @memberof exposureApi
	 * @private
	 */
	function deprecateMethods(methods, version){
		for (let index = 0; index < methods.length; index+=1) {
			const methodName = methods[index];
			deprecatedMethods.push(methodName);
			globalVar[methodName] = () => bbLogger.logWarn(`$$BB_VAR$$.${methodName} is deprecated. Please remove all references before version ${version} is released. To see all deprecated methods please check $$BB_VAR$$.deprecatedMethods`)
		}
	}

	rootScope({
		exposedApi: api
	})

	rootScopeGetters({
		deprecatedMethods: () => deprecatedMethods
	})

	return {
		api,
		expose,
		rootScope,
		rootScopeGetters,
		rootScopeGetter,
		bindToWindow,
		deprecateMethods
	};
})();

/**
 * Functional method of exposing functin/properties
 *
 * @export
 * @param {Object|Function} objOrFunction
 * @private
 */
export function expose(objOrFunction) {
	exposureApi.expose(objOrFunction);
}
