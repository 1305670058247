/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import { getConfig } from './config.js';
import CONSTANTS from './constants.json';
import { exposureApi } from './exposureApi.js';
import { dom } from './global.js';
import { errorReporting } from './services/errorReporting.js';
import { cloneDeep } from './utilities/cloneDeep.js';
import defaultsDeep from './utilities/helpers/defaultsDeep.js';
import keyBy from './utilities/helpers/keyBy.js';
import pick from './utilities/helpers/pick.js';
import sortBy from './utilities/helpers/sortBy.js';
import { hookedFn } from './utilities/hookedFunction.js';
import { bbLogger } from './utilities/logger.js';
import { unitHasElement } from './utilities/unitHasElement.js';

/**
 * Unmodified unit collection
 *
 * @memberof UnitManager
 * @exposed readonly
 * @exposedAs UNITS
 */
// eslint-disable-next-line import/no-mutable-exports
export let rawUnits = {};
/**
 * Unit collection registry containing all units and their modifications. Can be referenced as `adUnitsObj` or `unitRegistry`
 *
 * @memberof UnitManager
 * @exposed readonly
 * @exposedAs adUnitsObj
 */
// eslint-disable-next-line import/no-mutable-exports
export let unitRegistry = {};
/**
 * Unit collection of templates. These templates are used when initially creating an incremental unit
 *
 * @memberof UnitManager
 * @exposed readonly
 */
// eslint-disable-next-line import/no-mutable-exports
export let unitTemplates = {};
/**
 * Resets all unit objects
 *
 * @memberof UnitManager
 * @private
 */
export function clearUnits() {
	rawUnits = {};
	unitRegistry = {};
	unitTemplates = {};
}
/**
 * Sets up all unit objects
 *
 * @private
 * @param {BidBarrel~Units[]} units Collection of ad unit configurations
 * @memberof UnitManager
 */
export function setupUnits(units) {
	let _units = units;
	if (Array.isArray(_units)) {
		_units = keyBy(units, 'code');
	}
	unitRegistry = _units;
	unitTemplates = JSON.parse(JSON.stringify(unitRegistry));
	rawUnits = JSON.parse(JSON.stringify(unitRegistry));
}

export function mergeUnits(newUnits) {
	const newUnitsByKey = keyBy(newUnits, 'code');
	unitRegistry = defaultsDeep(newUnitsByKey, unitRegistry);
	unitTemplates = cloneDeep(unitRegistry);
	rawUnits = cloneDeep(unitRegistry);
}
/**
 * Function getter for unmodified units
 *
 * @returns {Object}
 * @private
 * @memberof UnitManager
 */
export function getRawUnits() {
	return rawUnits;
}
/**
 * Function getter for unit registry
 *
 * @returns {Object}
 * @private
 * @memberof UnitManager
 */
export function getUnits() {
	return unitRegistry;
}
/**
 * Function getter for unit templates
 *
 * @returns {Object}
 * @private
 * @memberof UnitManager
 */
export function getUnitTemplates() {
	return unitTemplates;
}

/**
 * Hookable function that allows modification of units on define
 *
 * @param {BidBarrel~AdUnit} unitConfig
 * @param {string|BidBarrel~AdUnit|Array} designation
 * @private
 * @method
 * @memberof UnitManager
 * @returns {BidBarrel~AdUnit}
 */
// eslint-disable-next-line no-unused-vars
export const defineUnit = hookedFn('sync', (unitConfig, designation) => {
	unitRegistry[unitConfig.code] = unitConfig;
	return unitRegistry[unitConfig.code];
});
/**
 * Hookable function for updating unit configurations
 *
 * @param {String|String[]|BidBarrel~AdUnit|BidBarrel~AdUnit[]} Array of updated units or unit codes to update
 * @param {Object} [config=null] Partial unit config to update to
 * @param {Boolean} [updateTemplates=true] Flag to indicate whether or not to apply this update to the unit templates
 * @private
 * @memberof UnitManager
 */
export const updateUnit = hookedFn('sync', (unit, config = null, updateTemplates = true) => {
	if (Array.isArray(unit)) {
		for (let index = 0; index < unit.length; index += 1) {
			const currentUnit = unit[index];
			updateUnit(currentUnit, config);
		}
		return;
	}
	if ((typeof unit === 'string' && !unitRegistry[unit]) || (typeof unit !== 'string' && !unitRegistry[unit])) {
		bbLogger.logError('Attempting to update unit that does not exist', unit, config);
		const errorObj = new Error(`Attempting to update unit that does not exist.`);
		errorObj.unit = unit;
		errorReporting.report(errorObj);
	} else if (!config && typeof unit !== 'string') {
		unitRegistry[unit.code] = { ...unitRegistry[unit.code], ...unit };
		if (updateTemplates) {
			unitTemplates[unit.code] = { ...unitTemplates[unit.code], ...unit };
		}
	} else if (typeof unit === 'string' && config) {
		unitRegistry[unit] = { ...unitRegistry[unit], ...config };
		if (updateTemplates) {
			unitTemplates[unit] = { ...unitTemplates[unit], ...config };
		}
	}
});

/**
 * Gets the unit codes for a set of designations
 *
 * @param {string[]|BidBarrel~AdUnit[]|Array[]} designations
 * @returns {string[]} Array of string unit codes
 * @memberof UnitManager
 */
export function getUnitCodes(designations) {
	const result = [];
	for (let index = 0; index < designations.length; index += 1) {
		const designation = designations[index];
		if (Array.isArray(designation) && designation[1] === null) {
			result.push(designation[0]);
		} else if (Array.isArray(designation)) {
			const generateCode = getConfig('generateIncrementalUnitCode');
			result.push(generateCode(designation[0], designation[1]));
		} else if (typeof designation === 'string') {
			result.push(designation);
		} else if (typeof designation === 'object') {
			result.push(designation.code);
		}
	}
	return result;
}
/**
 * Creates a new unit from a object configuration
 *
 * @memberof UnitManager
 * @private
 * @returns {BidBarrel~AdUnit}
 */
function createNewUnitFromObject(newUnitConfig, index = 0) {
	newUnitConfig.orderIndex = index;
	newUnitConfig.originalCode = newUnitConfig.originalCode || newUnitConfig.code;
	return newUnitConfig;
}
/**
 * Gets a unit from the unit registry
 *
 * @memberof UnitManager
 * @private
 * @returns {BidBarrel~AdUnit}
 */
function getUnitByCode(unitCode, index, targeting, modifiers) {
	let foundUnit = unitRegistry[unitCode];
	if (foundUnit) {
		foundUnit.orderIndex = index;
		foundUnit.originalCode = foundUnit.originalCode || unitCode;
		if (targeting) {
			foundUnit.targeting = defaultsDeep({}, targeting, foundUnit.targeting);
		}
		if (modifiers) {
			const adjustedModifiers = pick(modifiers, CONSTANTS.UNIT_CONFIG_ALLOWED_MODIFIERS);
			foundUnit = defaultsDeep(foundUnit, adjustedModifiers);
		}
	} else {
		bbLogger.atVerbosity(2).logError('Unit', unitCode, 'does not exist. Skipping definition.');
		const errorObj = new Error(`'${unitCode}': Unit does not exist. Skipping definition.`);
		errorObj.unit = unitCode;
		errorReporting.report(errorObj);
	}
	return foundUnit;
}
/**
 * Gets/generates an incremental unit configuration
 *
 * @memberof UnitManager
 * @private
 * @returns {BidBarrel~AdUnit}
 */
function getIncrementalUnit(templateCode, increment, index, targeting = {}, modifiers = {}) {
	let unitCode = Array.isArray(increment) ? templateCode + (increment[0] || '') : templateCode + increment;
	const config = getConfig();
	if (config.generateVideoUnitCode && unitTemplates[templateCode] && unitTemplates[templateCode].isVideo) {
		unitCode = config.generateVideoUnitCode(templateCode, increment, unitTemplates[templateCode]);
	} else if (unitTemplates[templateCode] && !unitTemplates[templateCode].incremental && unitTemplates[templateCode].isVideo) {
		unitCode = templateCode;
	} else if (config.generateIncrementalUnitCode) {
		unitCode = config.generateIncrementalUnitCode(templateCode, increment, unitTemplates[templateCode]);
	}
	let foundUnit = null;
	if (unitRegistry[unitCode]) {
		foundUnit = unitRegistry[unitCode];
	} else {
		foundUnit = unitTemplates[templateCode] && (unitTemplates[templateCode].incremental || unitTemplates[templateCode].isVideo) ? cloneDeep(unitTemplates[templateCode]) : false;
	}

	if (foundUnit) {
		foundUnit.code = unitCode;
		if (increment !== null && !Array.isArray(increment)) {
			foundUnit.index = increment;
			if (foundUnit.isVideo) {
				foundUnit.sequenceIndex = 1;
			}
		} else if (increment !== null && Array.isArray(increment) && increment.length === 2) {
			// eslint-disable-next-line prefer-destructuring
			foundUnit.index = increment[0];
			// eslint-disable-next-line prefer-destructuring
			foundUnit.sequenceIndex = increment[1];
			// MLS testout:
			// 	[foundUnit.index, foundUnit.sequenceIndex] = increment;
		}
		foundUnit.orderIndex = index;
		foundUnit.originalCode = templateCode;
		if (targeting) {
			foundUnit.targeting = defaultsDeep({}, targeting, foundUnit.targeting);
		}
		if (modifiers) {
			const adjustedModifiers = pick(modifiers, CONSTANTS.UNIT_CONFIG_ALLOWED_MODIFIERS);
			foundUnit = defaultsDeep({}, adjustedModifiers, foundUnit);
		}
	} else {
		bbLogger.atVerbosity(2).logError('Unit Template for', templateCode, 'does not exist or is configured improperly(not incremental?). Skipping definition.');
		const errorObj = new Error(`Unit Template does not exist or is configured improperly(not incremental?). Skipping definition.`);
		errorObj.unit = templateCode;
		errorReporting.report(errorObj);
	}
	return foundUnit;
}

/**
 * This function handles getting a units sizes for the headeer bidding process
 * @param {String|BidBarrel~AdUnit} unitCode The unit code we're trying to get the sizes for
 * @returns {Array<Number[]>} The sizes for the current matching viewport
 * @memberof UnitManager
 * @exposed
 */
export function getSizes(unitOrCode) {
	const unit = typeof unitOrCode === 'string' ? unitRegistry[unitOrCode] : unitOrCode;
	if (!unit.sizeMappings) {
		return unit.sizes;
	}
	if (unit.mappedSizes) {
		return unit.mappedSizes;
	}
	// We multiply the numbers by -1 to get a reverse sort and the highest numbers come first
	const widthSortFn = (mapping) => -1 * mapping.viewport[0];
	const heightSortFn = (mapping) => -1 * mapping.viewport[1];
	const sortedMappings = sortBy(unit.sizeMappings, [widthSortFn, heightSortFn]);
	for (let i = 0; i < sortedMappings.length; i += 1) {
		const mapping = sortedMappings[i];
		if (dom().window.innerWidth >= mapping.viewport[0] && mapping.viewport[1] <= dom().window.innerHeight) {
			unit.mappedSizes = mapping.sizes;
			return mapping.sizes;
		}
	}
	bbLogger.atVerbosity(3).logWarn('No matching mappings found for viewport', unit.code, sortedMappings);
	return unit.sizes;
}
/**
 * Allows to set lazy loading via implementation. If no unit codes are provided all units will be modified.
 *
 * @param {boolean} [value=true] Value to set the lazy load flag to
 * @param {String[]} [unitCodes=[]] Array of unit codes
 * @param {boolean} [applyToTemplates=false] Whether or not to apply this to unitTemplates(where inc units are pulled from pre-generation)
 * @public
 * @type {Function}
 * @method
 * @memberof UnitManager
 * @exposed
 */
export const setLazyLoad = hookedFn('sync', (value = true, unitCodes = [], applyToTemplates = false) => {
	if (!unitCodes || unitCodes.length === 0) {
		unitCodes = Object.keys(unitRegistry);
	}
	let updateObj = { lazyLoad: value };
	if (value) {
		updateObj = { cache: value, lazyLoad: value };
	}
	updateUnit(unitCodes, updateObj, applyToTemplates);
});
/**
 * Allows you to set units to an out of page status
 *
 * @param {String|String[]} unitCodes
 * @memberof UnitManager
 * @exposed
 */
export function setOutOfPage(unitCodes) {
	updateUnit(unitCodes, { outOfPage: true }, true);
}
/**
 * Sets the unit to auto collapse if specified
 *
 * @param {String|String[]} unitCodes The unit codes currently being processed
 * @type {Function}
 * @method
 * @memberof UnitManager
 * @exposed
 */
export const setCollapse = hookedFn('sync', (unitCodes, arg) => {
	updateUnit(unitCodes, { collapseEmptyDiv: arg }, true);
	return { unitCodes, arg };
});
/**
 * Checks to see if the ad unit is lazy loaded
 *
 * @param {BidBarrel~AdUnit} unit
 * @returns {boolean}
 * @private
 * @memberof UnitManager
 */
export function isLazyLoaded(unitOrCode) {
	const unit = typeof unitOrCode === 'object' ? unitOrCode : getUnits()[unitOrCode];
	if (!unit) return false; // Lazyload off by default
	const result = typeof unit.lazyLoad === 'undefined' ? unit.cache : unit.lazyLoad;
	if (result) {
		const unitElement = dom().window.document.getElementById(unit.code);
		if (unitElement && unitElement.style && unitElement.style.display === 'none') {
			bbLogger.logMessage(
				`${unit.code}: Cannot lazy load ad units where the element has a style of display:none;. This unit will evaluate as 'not lazy loaded' until it does not have a style of display: none;`
			);
			return false;
		}
	}
	return result;
}

/**
 * Applies size mappings to unit configs and slots
 *
 * @param {String|String[]} unitCode
 * @param {BidBarrel~SizeMapping[]} mappings
 * @memberof UnitManager
 * @method
 * @exposed
 */
export const setSizeMappings = hookedFn('sync', (unitCode, mappings) => {
	updateUnit(unitCode, { sizeMappings: mappings }, true);
});
/**
 * Displays ad slots by a given array of units
 *
 * @param {BidBarrel~AdUnit[]} unit
 * @memberof UnitManager
 * @private
 */
export const displayUnit = hookedFn('sync', (unit) => {
	if (unit.constructor === Array) {
		bbLogger.atVerbosity(3).logInfo('Displaying units', unit);
		unit.forEach(displayUnit);
	}
});
/**
 * Allows for post processing ad units without needing to perform additional loops
 *
 *
 * @param {BidBarrel~AdUnit} unit
 * @param {string|BidBarrel~AdUnit|Array} designation
 * @returns {BidBarrel~AdUnit|null|undefined}
 * @type {Function}
 * @method
 * @private
 * @memberof UnitManager
 */
// eslint-disable-next-line no-unused-vars
export const postProcessUnit = hookedFn('sync', (unit, designation) => {
	unit.isLazyLoaded = () => isLazyLoaded(unit.code);
	unit.getSizes = () => getSizes(unit.code);
	unit.element = dom().window.document.getElementById(unit.code);
	return unit;
});
/**
   * Handles lookup of ad units based on string designations while also allowing new definitions as objects(<b>if exists, replaces</b>) and incremental ad units
   *
   *
   * @param {string[]|BidBarrel~AdUnit[]|Array[]} units
   * @returns {BidBarrel~AdUnit[]}
   * @type {Function}
   * @method
   * @memberof UnitManager
   * @exposed
   *
   * @example
   *
   BidBarrel.getUnitCollection([
       "leader-top", // Standard pre-defined unit
       ["mpu-inc", 2, {pos:"inc"}], // Incremental ad unit
       ["leader-top", null, {pos:"top"}], // standard predefined unit with incremental unit syntax
       ["leader-top", null, {pos:"top"}, {cache: true, collapseEmptyDiv:[true]}], // standard predefined unit with incremental unit syntax and unit modifiers(also work on inc units, review BidBarrel unit docs for more info)
       {
           code: "leader-top", //  Define units on the fly, and replace if exists(Not recommended)
           cache: false,
           sizes: [
               [970, 90],
               [970, 66],
               [728, 90],
               [970, 250]
           ],
           targeting: {
               pos: "top"
           },
           bids: [
               {
                   bidder: "yahooAds",
                   params: {
                       network: "9928.1",
                       placement: "3687854"
                   }
               },
               {
                   bidder: "yahooAds",
                   params: {
                       network: "9928.1",
                       placement: "5002410"
                   }
               },
               {
                   bidder: "appnexus",
                   params: {
                       placementId: "5757416"
                   }
               }
           ]
       }
   ])
   */
export const getUnitCollection = hookedFn('sync', (units) => {
	if (!Array.isArray(units)) {
		return getUnitCollection([units]); // MLS why is this recursive?
	}
	const resultCollection = units.reduce(
		(result, unit, index) => {
			const designation = unit;
			if (unit === null || typeof unit === 'undefined') {
				bbLogger.logError('SKIPPING UNIT: Unit designation is null or undefined at index', index, 'provided:', units);
				const errorObj = new Error(`SKIPPING UNIT: Unit designation is null or undefined at index.`);
				errorObj.unit = `${units}[${index}]`;
				errorReporting.report(errorObj);
				return result;
			}
			let newUnit = null;
			if (typeof unit === 'object' && unit.constructor !== Array) {
				// handles new unit definition (if exists, replaces)
				newUnit = createNewUnitFromObject(unit, index);
			} else if (unit.constructor === Array) {
				// handles incremental units
				if (unit[1] === null) {
					newUnit = getUnitByCode(unit[0], index, unit[2], unit[3]);
				} else {
					newUnit = getIncrementalUnit(unit[0], unit[1], index, unit[2], unit[3]);
				}
			} else if (typeof unit === 'string') {
				newUnit = getUnitByCode(unit, index);
			}
			newUnit = newUnit ? postProcessUnit(newUnit, unit) : newUnit;
			if (newUnit && (!result.flags[newUnit.code] || newUnit.isVideo)) {
				newUnit.getDesignation = () => designation;
				result.flags[newUnit.code] = true;
				if (unitHasElement(newUnit) || isLazyLoaded(newUnit)) {
					result.units.push(defineUnit(newUnit, unit));
				} else {
					bbLogger.logError(newUnit.code, ': Unit designation does not have an element. If you are lazy loading, this unit is not configured for lazy loading.', newUnit);
					const errorObj = new Error(`'${newUnit.code}': Unit designation does not have an element. If you are lazy loading, this unit is not configured for lazy loading.`);
					errorObj.unit = newUnit.code;
					errorReporting.report(errorObj);
				}
			}
			return result;
		},
		{ flags: {}, units: [] }
	).units;
	return resultCollection;
});

exposureApi.rootScope({
	setOutOfPage,
	setLazyLoad,
	isLazyLoaded,
	setCollapse,
	getSizes,
	setSizeMappings,
	getUnitCollection,
});

exposureApi.rootScopeGetters({
	unitRegistry: () => getUnits(),
	adUnitsObj: () => getUnits(),
	adUnits: () => Object.values(getUnits()),
	unitTemplates: () => getUnitTemplates(),
	UNITS: () => getRawUnits(),
});

/**
 * A read-only collection of all registered ad units
 *
 *
 * @name BidBarrel.adUnits
 * @readonly
 * @type {BidBarrel~AdUnit[]}
 */

/**
  * Ad Unit Configs define properties around how Bid Barrel operates on a
  * specific div/ad inventory item. These configs(`units.json`) can be updated by filing a ticket against the REVSYS project, assign to Jordy Kopp, CC Stephen M Smith, add the label "bidbarrel" and provide a short description of what changes you would like made.
  *
  * @typedef {Object} BidBarrel~AdUnit
  *
  * @param {String} code Unique identifier for ad unit that matches
  * the `id` attribute of the div it represents. These must be unique
  * for all sites. You should not have a units with the same code property
  * @param {Boolean} [cache=false] Defines whether or not to apply lazy loading
  * optimizations to this ad unit.
  *
  * This property can be modified (implementation-side)
  * **Important:** Prioritization and Lazy Loading can only work correctly if the ad div is on the page and does **NOT** have a style of `display:none;`
  * @param {BidBarrel~SizeMapping[]} [sizeMappings] Array of size mappings for
  * this ad placement provided it matches a set of viewport dimensions
  *
  * This property can be modified (implementation-side)
  * @param {Boolean} [collapseEmptyDiv] Specify whether or not to call
  * setCollapseEmptyDiv on this unit's slot. Leaving this
  * property and the <code>bidBarrelConfig.collapseAllEmptyDivs</code> undefined results in
  * shortcircuiting this method all together. For more info
  * checkout the {@link https://support.google.com/admanager/answer/3072674?hl=en|Ad Manager docs}
  * or {@link https://developers.google.com/doubleclick-gpt/reference#googletag.Slot_setCollapseEmptyDiv|Google Publisher Tag docs}
  *
  * This property can be modified (implementation-side)
  * @param {Boolean} [outOfPage] Flag to specify a unit as an out of page
  * unit
  *
  * This property can be modified (implementation-side)
  * @param {Boolean} [lazyLoad=false] Alias for the cache property
  * **Important:** Prioritization and Lazy Loading can only work correctly if the ad div is on the page and does **NOT** have a style of `display:none;`
  * @param {Boolean} [incremental] Flag to designate this unit a template
  * for incremental processing
  * @param {Array<Array|String>} sizes Acceptable ad sizes for this specific ad
  * unit. Accepts an array of array integers in the format of `[width, height]`
  * or "fluid"
  * @param {Object} [targeting] unit specific targeting to be applied to
  * this unit's {@link #GoogleTagSlot|GoogleTagSlot} in the format of key value pairs as an
  * object
  * @param {Object[]} allowedTypes Object of boolean flags for each of the possible Prebid `mediaTypes` (banner, native, video) to be enabled.
  * @param {Boolean} [isVideo] Boolean flag that indicates this ad unit configuration is for a video ad
  * @param {Object[]} bids Bidder specific parameter configurations.
  * Documentation around these objects can be found in the "Bidder Params"
  * menu option at the top. Example below.
  *
  * @example
* var AdUnit = {
    code: "leader-top",
    cache: false,
    incremental: false,
    collapseEmptyDiv: false,
    sizes: [
      [
        970, 90
      ],
      [
        970, 66
      ],
      [
        728, 90
      ],
      "fluid",
      [970, 250]
    ],
    targeting: {
      pos: "top"
    },
    bids: [
      {
        bidder: "yahooAds",
        params: {
          network: "9928.1",
          placement: "3687854"
        }
      }, {
        bidder: "yahooAds",
        params: {
          network: "9928.1",
          placement: "5002410"
        }
      }, {
        bidder: "appnexus",
        params: {
          placementId: "5757416"
        }
      }
    ]
  }
  *
  */
/**
 * Size Mapping objects allow you to define sizes for specific viewport dimensions
 * the parsing of these objects is done both by googletag and BidBarrel
 * BidBarrel parses these object to apply the appropriate sizes for header
 * bidding while googletag handles the iframe in which the ads render
 *
 * @typedef BidBarrel~SizeMapping
 * @type Object
 *
 * @param {Number[]} viewport Viewport dimensions in the fortmat of [VIEWPORT_WIDTH,VIEWPORT_WIDTH]
 * @param {Array<Number[]>} sizes the sizes for this ad unit if it matches the viewport
 * dimensions
 */
/**
 * Bid Objects are significant in that they represent an actual ad that
 * could be rendered provided the cpm is more than the competing ad on
 * Google's exchange.
 *
 * Bid Barrel's bid objects are the same as {@link http://prebid.org/dev-docs/publisher-api-reference.html#module_pbjs.getBidResponses| a Prebid bid response}
 * with a few additional properties to assist with how Bid Barrel
 * interacts with the ad process.
 *
 * @typedef BidBarrel~Bid
 * @type Object
 * @readonly
 *
 * @param {Boolean} [pending=false] Flag to determine if a bid is
 * currently in processing for an existing ad unit auction.
 * @param {String} [pendingFor] Represents the ad unit code that this bid
 * is currently in processing for
 * @param {String} [originalCode] Represents the ad unit code that this
 * bid was originally received for.
 * @param {Number} [expireTime] The time the bid was recieved plus the
 * bidder provided time to live(TTL) property in MS. Used to determine
 * when a bid is no longer usable.
 * @example
 * var bidBarrelBid = {
	bidderCode: "criteo",
	width: 728,
	height: 90,
	statusMessage: "Bid available",
	adId: "99bb5b26-4194-4fc1-809e-1980bf8d0518",
	mediaType: "banner",
	source: "client",
	cpm: 0.147983580827713,
	ad: "<AD CODE OMITTED>",
	currency: "USD",
	netRevenue: true,
  ttl: 60,
  pending: true,
  pendingFor: "oas_Top",
  originalCode: "oas_Top",
	creativeId: "12fff9a464ba97e",
	auctionId: "f0d00208-6c21-4d4f-8583-3466de63ff4c",
	responseTimestamp: 1559671172778,
	requestTimestamp: 1559671172476,
	bidder: "criteo",
	adUnitCode: "oas_Top",
	timeToRespond: 302,
	pbLg: "0.00",
	pbMg: "0.10",
	pbHg: "0.14",
	pbAg: "0.10",
	pbDg: "0.14",
	pbCg: "0.14",
	size: "728x90",
	adserverTargeting: {
		hb_bidder: "criteo",
		hb_adid: "99bb5b26-4194-4fc1-809e-1980bf8d0518",
		hb_pb: "0.14",
		hb_size: "728x90",
		hb_source: "client",
		hb_format: "banner"
	},
	expireTime: 1559671233099
}

 */
