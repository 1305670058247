import { eventEmitter } from '../events.js';
import { bbLogger } from '../utilities/logger.js';
import { getConfig } from '../config.js';
import { bidCache } from './bidCache.js';
import CONSTANTS from '../constants.json';
import omit from '../utilities/helpers/omit.js';
import { makeSafe } from '../utilities/safeFunction.js';
import { exposureApi } from '../exposureApi.js';

// vendor.bidder codes for adhesion
const ADHESION_KEYS = ['prebid.kargo', 'amazon.gumgum', 'google.33across'];
// Dynamic identity values
const ADDITIONAL_IDENTITY_VALUES = {
	// eslint-disable-next-line no-unused-vars
	prebid: (unitConfig, gptEventObj, identity) => ({
		cpm: parseFloat(gptEventObj.slot.getTargeting('hb_pb')),
	}),
};

const { IDENTIFIED } = CONSTANTS.EVENTS;

/**
 * Ad identification service
 *
 * @module adIdentier
 * @private
 */
// eslint-disable-next-line import/prefer-default-export
export const adIdentifier = (() => {
	/**
	 * Object tracking queue'd overrides
	 *
	 * @module adIdentifier
	 * @private
	 */
	let queuedOverrides = {};

	/**
	 * Constructs the array of event names
	 *
	 * @param {Object} identity Identity object
	 * @param {String} unitCode Ad unit code
	 * @returns {String[]} Array of event names
	 * @module adIdentifier
	 * @private
	 */
	function constructEventNames(identity, unitCode) {
		return [`${IDENTIFIED}`, `${unitCode}.${IDENTIFIED}`, `${IDENTIFIED}.${identity.type}`, `${unitCode}.${IDENTIFIED}.${identity.type}`];
	}
	/**
	 * Gets the ad type
	 *
	 * @param {BidBarrel~AdUnit} unitConfig unit configuration
	 * @param {Object} gptEventObj GPT Event object
	 * @param {Object} identity partial identification of current ad
	 * @param {Prebid~Bid} bid prebid bid if prebid won
	 * @returns {String} ad type
	 * @module adIdentifier
	 * @private
	 */
	function getType(unitConfig, gptEventObj, identity, bid = {}) {
		let result = 'display';
		if (gptEventObj.size && gptEventObj.size.join('x') === '5x5') {
			result = 'skybox';
		}
		if (bid.mediaType === 'native' || (gptEventObj.size && gptEventObj.size.join('x') === '11x11') || (identity.provider === 'prebid' && identity.subprovider === 'medianet')) {
			result = 'native';
		}
		if (ADHESION_KEYS.indexOf(`${identity.provider}.${identity.subprovider}`) >= 0) {
			result = 'adhesion';
		}
		if (bid && bid.size === '6x6') {
			result = 'adhesion';
		}
		if (identity.provider === 'prebid' && identity.subprovider === 'gumgum' && bid.ad.indexOf('<gumgum-ad product="2"') >= 0) {
			result = 'adhesion';
		}
		if (gptEventObj.size && gptEventObj.size.join('x') === '7x7') {
			result = 'flex';
		}
		return result;
	}
	/**
	 * Gets the ad vendor identity values
	 *
	 * @param {BidBarrel~AdUnit} unitConfig Unit config
	 * @param {Object} gptEventObj GPT Event Object
	 * @returns {Object} partial identification object
	 * @module adIdentifier
	 * @private
	 */
	function getVendor(unitConfig, gptEventObj) {
		const advertiserId = parseInt(gptEventObj.advertiserId, 10);
		const { slot } = gptEventObj;
		let hbBidder = slot.getTargeting('hb_bidder');
		let amznBidder = slot.getTargeting('amznp');

		if (hbBidder.length > 0) {
			// eslint-disable-next-line prefer-destructuring
			hbBidder = hbBidder[0];
		}
		if (amznBidder.length > 0) {
			// Amazon de-obfuscated bidder IDs
			const amazonIds = getConfig('identity.amazon.providerTranslations');
			amznBidder = amazonIds[amznBidder[0]] ? amazonIds[amznBidder[0]] : amznBidder[0];
		}

		const verify = {
			'33across': {
				provider: 'google',
				subprovider: '33across',
			},
			prebid: {
				provider: 'prebid',
				subprovider: hbBidder,
			},
			amazon: {
				provider: 'amazon',
				subprovider: amznBidder,
			},
		};

		let vendorResult = {
			provider: 'google',
			subprovider: 'unknown',
		};

		// Pulled from: https://docs.google.com/spreadsheets/d/1z_nFfFyosarG7Gx36B8sjq9bO40pLrMNhf_jDo6oXg4/edit?ts=5de82fb5#gid=777213395
		const advertiserIds = getConfig('identity.gam.advertiserIds');
		Object.keys(advertiserIds).forEach((vendor) => {
			const ids = advertiserIds[vendor];
			if (ids.indexOf(advertiserId) >= 0) {
				vendorResult = verify[vendor];
			}
		});
		return vendorResult;
	}

	/**
	 * Gets the dsp name
	 *
	 * @param {BidBarrel~AdUnit} unitConfig unit configuration
	 * @param {Object} gptEventObj GPT Event object
	 * @param {Object} identity partial identification of current ad
	 * @returns {String} dsp name
	 * @module adIdentifier
	 * @private
	 */
	function getDsp(unitConfig, gptEventObj, identity) {
		if (identity.subprovider === 'medianet') {
			const bids = bidCache.getAllBids();
			const { slot } = gptEventObj;
			const adId = slot.getTargeting('hb_adid');
			const creativeId = bids[adId].creativeId.toString();
			const mnDspId = creativeId.substr(3, parseInt(creativeId.charAt(2), 10));
			const medianetDspIds = getConfig('identity.medianet.providerIds');
			return medianetDspIds[mnDspId] || mnDspId;
		}
		return null;
	}
	/**
	 * Method for queueing an override for ad identity
	 *
	 * @param {Object} identity full or partial ad identity
	 * @module adIdentifier
	 * @private
	 */
	function overrideIdentity(identity) {
		if (!identity.code) return;
		makeSafe(() => {
			queuedOverrides[identity.code] = identity;
			eventEmitter.on(
				`${identity.code}.slotRenderEnded`,
				() => {
					setTimeout(() => {
						queuedOverrides = omit(queuedOverrides, [identity.code]);
					}, 50);
				},
				true
			);
		});
	}

	/**
	 * Evaluates the rendered ad
	 *
	 * @param {BidBarrel~AdUnit} unitConfig unit config passed into the rendered event
	 * @param {Object} gptEventObj GPT event objects for processing
	 * @module adIdentifier
	 * @private
	 */
	function evaluate(unitConfig, gptEventObj) {
		const vendor = getVendor(unitConfig, gptEventObj);
		if (vendor.provider === 'prebid') {
			vendor.bid = bidCache.getLatestPendingBid(unitConfig.code);
		}
		let identification = {
			...vendor,
			code: unitConfig.code,
		};

		identification.size = identification.provider === 'prebid' && identification.bid ? identification.bid.size.split('x').map(Number) : gptEventObj.size;

		identification.gpt = {
			advertiserId: gptEventObj.advertiserId,
			orderId: gptEventObj.campaignId,
			creativeId: gptEventObj.creativeId,
			lineId: gptEventObj.lineItemId,
			size: gptEventObj.size,
		};

		const type = getType(unitConfig, gptEventObj, vendor, identification.bid || {});
		identification.type = type;

		const dsp = getDsp(unitConfig, gptEventObj, vendor);
		identification.dsp = dsp;

		if (ADDITIONAL_IDENTITY_VALUES[identification.vendor]) {
			identification = {
				...identification,
				...ADDITIONAL_IDENTITY_VALUES[identification.vendor](unitConfig, gptEventObj, identification),
			};
		}

		if (queuedOverrides[identification.code]) {
			identification = {
				...identification,
				...queuedOverrides[identification.code],
			};
			queuedOverrides = omit(queuedOverrides, [identification.code]);
		}

		bbLogger.logInfo('Ad Identified', unitConfig.code, identification);

		eventEmitter.emit(constructEventNames(identification, unitConfig.code), identification, unitConfig, gptEventObj);
	}
	/**
	 * Sets up the identification process
	 *
	 * @module adIdentifier
	 * @private
	 */
	function setupListeners() {
		eventEmitter.on('rendered', evaluate);
	}

	exposureApi.expose(
		{
			selfIdentify: overrideIdentity,
		},
		true
	);

	// Returned API
	return {
		setupListeners,
	};
})();

/**
 * Rendered Ad Identity respresents an object to assist in handling certain types ads
 *
 * @typedef BidBarrel~AdIdentity
 * @type {Object}
 *
 * @param {String} code the unit code/element id for where this ad has been rendered.
 * @param {String} provider indicates what service the ad was pulled from. The accuracy for this value is not fully guaranteed and should be taken as a "best guess" result.
 * possible values: `amazon`, `prebid`, and `google`
 * @param {String} [subprovider="unknown"] indicates the upstream vendor that the ad came from for Amazon and Prebid this will be the bidder code, for Google it will likely always be "unknown" unless it is 33Across. The accuracy for this value is not fully guaranteed and should be taken as a "best guess" result.
 * @param {String} [dsp=null] The downstream ad provider if detected.
 * @param {String} type the type of ad rendered. Possible values: `display`, `skybox`, `blank`, `native`, `adhesion`, `magnet`. Currently the accuracy of `native` is not guaranteed.
 * @param {Number[]} size The size of the ad in format of [width, height].
 * @param {Object} gpt contains GPT creative information: `creativeId`, `lineId`, `orderId`, advertiserId`, `size`.
 * @param {Float} [cpm] in the case of prebid bids this will contain the cpm value of the bid.
 */
