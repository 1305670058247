
import { getEnrichment, setEnrConfig, addContextToOrtb2 } from './enrichmentWorker.js';
import { getConfig } from '../../config.js';
import CONSTANTS from '../../constants.json';
import { eventEmitter } from '../../events.js';
import { exposureApi } from '../../exposureApi.js';
import { moduleManager } from '../../moduleManager.js';
import { requestManager } from '../../requestManager.js';

const { ENRICHMENT } = CONSTANTS.MODULES;
const { AUCTION } = CONSTANTS.EVENTS;

/**
 * Enrichment
 *
 * This module adds the Cohesion enrichment data to the ad request
 *
 * @module Enrichment
 * @private
 */
// eslint-disable-next-line func-names
const enrModuleBase = (function () {
	/**
	 * The register function is executed immediately when the moduleManager.register function
	 * below occurs unless this module has dependencies in which this function would execute
	 * after all dependencies have registered
	 *
	 * The register function gets the core BidBarrel library passed into it such that function
	 * hooks can be registered and core api usage can occur.
	 *
	 * @private
	 * @memberof Enrichment
	 */
	function register() {
		getConfig('enrichment', (value) => {
			setEnrConfig(value);
		});
	}

	let initializeDone = false;
	/**
	 * Initializes module which loads the enrichment data
	 *
	 * @private
	 * @memberof Enrichment
	 */
	function initialize() {
		if (initializeDone === false) {
			initializeDone = true;
			eventEmitter.on(AUCTION, () => addContextToOrtb2());
			requestManager.register('enrichment');
			getEnrichment(() => {
				requestManager.done('enrichment');
			});
		}
	}

	/**
	 * Fires off a new reqeust to the Enrichment module
	 *
	 * Requires module: `enrichment`
	 *
	 *
	 * @memberof Enrichment
	 * @private
	 * @exposed
	 */
	function refreshEnrichment() {
		getEnrichment();
	}

	exposureApi.expose({
		refreshEnrichment,
	});

	return {
		name: ENRICHMENT,
		register,
		initialize,
		refreshEnrichment,
	};
})();

const enrModule = moduleManager.register(enrModuleBase, null, { gate: 'consentGiven' });
export default enrModule;
