/* eslint-disable no-underscore-dangle */
import { getConfig } from './config.js';
import { pageTargetingObj } from './pageTargeting.js';
import { dom } from './global.js';
import { storage } from './services/storage.js';
import { richObject } from './utilities/richObject.js';
import { isStagingEnv } from './utilities/environment.js';
import get from './utilities/helpers/get.js';
import set from './utilities/helpers/set.js';
import { exposureApi } from './exposureApi.js';
import { bbLogger } from './utilities/logger.js';
import { eventEmitter } from './events.js';
import CONSTANTS from './constants.json';

const { AUCTION } = CONSTANTS.EVENTS;

const abTestStr = '$$TEST_STR$$';

/**
 * Context management module
 *
 * @module context
 * @private
 */
const context = (() => {
	/**
	 * Underlying rich object tracking all context values
	 *
	 * @private
	 * @memberof context
	 */
	const _context = richObject({
		site: '$bidbarrel.site$',
		variant: '$bidbarrel.variant$',
	});
	/**
	 * Array to track subscriptions to various other rich objects to ad reload capability
	 *
	 * @private
	 * @memberof context
	 */
	let _subscriptions = [];

	/**
	 * Setup AB Test String
	 *
	 * @private
	 * @memberof context
	 */
	function setABStr() {
		if (!get(dom().window, 'utag_data._test') && !/\$\$TEST_STR\$\$/gm.test(abTestStr)) {
			set(dom().window, 'utag_data._test', abTestStr);
		}
	}
	/**
	 * Helper method for updating the subscriptions array
	 *
	 * @param {Function} sub subscription return object(unsubscribe method)
	 * @memberof context
	 * @private
	 */
	function addSubscription(sub) {
		_subscriptions.push(sub);
	}
	/**
	 * Setup method for cookie context
	 *
	 * @memberof context
	 * @private
	 */
	function getCookieContext() {
		addSubscription(
			storage.getCookie('*', (cookie) => {
				_context.setValue({ cookie });
			})
		);
	}
	/**
	 * Setup method for targeting context
	 *
	 * @memberof context
	 * @private
	 */
	function getTargetingContext() {
		addSubscription(
			pageTargetingObj.getValue('*', (targeting) => {
				_context.setValue({ targeting });
			})
		);
	}
	/**
	 * Setup method for config context
	 *
	 * @memberof context
	 * @private
	 */
	function getConfigContext() {
		addSubscription(
			getConfig('*', (config) => {
				_context.setValue({
					config,
					authenticated: typeof getConfig('firstPartyData.eid') !== 'undefined',
				});
			})
		);
	}
	/**
	 * Setup method for window context
	 *
	 * @memberof context
	 * @private
	 */
	function getWindowContext() {
		_context.setValue({
			page: dom().window.location.href,
			referrer: dom().window.document.referrer,
			hostname: dom().window.location.hostname,
			abStr: get(dom().window, 'utag_data._test'),
		});
	}
	/**
	 * Setup method for client context
	 *
	 * @memberof context
	 * @private
	 */
	function getClientContext() {
		_context.setValue('client', {
			connectionSpeed: get(dom().window, 'navigator.connection.downlink'),
			deviceMemory: get(dom().window, 'navigator.deviceMemory'),
			viewportHeight: get(dom().window, 'innerHeight'),
			viewportWidth: get(dom().window, 'innerWidth'),
			maxViewportHeight: get(dom().window, 'screen.height'),
			maxViewportWidth: get(dom().window, 'screen.width'),
		});

		addSubscription(
			getConfig('clientInfo.data', (clientData) => {
				_context.setValue('client', {
					..._context.getValue('client'),
					country: get(clientData, 'country'),
					gmtOffset: get(clientData, 'gmtOffset'),
					subregion: get(clientData, 'region'),
					postalCode: get(clientData, 'postalCode'),
					connectionType: get(clientData, 'connection'),
				});
			})
		);

		addSubscription(
			getConfig('geo.regionCode', (region) => {
				_context.setValue('client.region', region);
			})
		);
	}
	/**
	 * Setup method for ad library context
	 *
	 * @memberof context
	 * @private
	 */
	function getAdLibContext() {
		_context.setValue({
			dfpPath: getConfig('dfpPathObj.string'),
			bidbarrelVersion: 'rv$bidbarrel.version$',
			isStage: isStagingEnv(),
		});
	}
	/**
	 * Setup method for initializing the module
	 *
	 * @private
	 * @memberof context
	 */
	function setup() {
		setABStr();
		getCookieContext();
		getTargetingContext();
		getConfigContext();
		getWindowContext();
		getClientContext();
		getAdLibContext();
		bbLogger.logInfo('Setup context', _context.getValue());
	}
	/**
	 * Reload method to allow for recapture of static properties
	 *
	 * @memberof context
	 * @private
	 */
	function reload() {
		for (let index = 0; index < _subscriptions.length; index += 1) {
			const unsubscribe = _subscriptions[index];
			if (typeof unsubscribe === 'function') {
				unsubscribe();
			}
		}
		_subscriptions = [];
		setup();
	}

	exposureApi.rootScopeGetter('context', () => _context.getValue());
	exposureApi.expose({
		reloadContext: reload,
	});

	eventEmitter.on(AUCTION, reload);

	return {
		setup,
		reload,
		setValue: _context.setValue,
		getValue: _context.getValue,
	};
})();

export default context;
