import { eventEmitter } from './events.js';
import { logger } from './utilities/logger.js';
import { makeSafe } from './utilities/safeFunction.js';
import omit from './utilities/helpers/omit.js';
import orderBy from './utilities/helpers/orderBy.js';
import { cloneDeep } from './utilities/cloneDeep.js';
import { gateway as _gateway } from './utilities/gateway.js';
import { exposureApi } from './exposureApi.js';
import { features } from './features.js';
import { headerBidderRequest, adLibInitialized } from './bidbarrel.js';
import CONSTANTS from './constants.json';

const mmLogger = logger({ name: 'moduleManager', bgColor: '#FF9E1A', textColor: '#FFF' });
const {
	EVENTS: { MODULE_DEREGISTERED, LOAD, MODULE_REGISTERED, MODULE_INITIALIZED, INITIALIZE },
	QUERY_PARAMS: { MOD_SUPPRESS },
} = CONSTANTS;

/**
 * This component handles management of all modules and adds additional scaffolding to streamline module creation
 *
 * @module
 * @private
 */
// eslint-disable-next-line import/prefer-default-export, func-names
export const moduleManager = (function () {
	/**
	 * Gateway concept to allow modules to block other modules
	 *
	 * @memberof moduleManager
	 * @private
	 */
	const gateways = _gateway('moduleManager');
	/**
	 * Queue'd callbacks to run once a module has been registered, in the case of a delayed module registration
	 *
	 * @memberof moduleManager
	 * @private
	 */
	const onRegisterQueue = [];
	/**
	 * Registry for all modules
	 *
	 * @memberof moduleManager
	 * @private
	 */
	let registeredModules = {};
	/**
	 * Flag to track initialization state
	 *
	 * @memberof moduleManager
	 * @private
	 */
	const initialized = false;

	/**
	 * Initialize one module
	 * @param module
	 * @memberof moduleManager
	 * @private
	 */
	function initializeOneModule(module) {
		if (module.initialize) {
			makeSafe(() => module.initialize(), mmLogger.atVerbosity(1).logError);
		}
		// eslint-disable-next-line no-param-reassign
		module.isInitialized = true;
		eventEmitter.emit([MODULE_INITIALIZED, `${module.name}.${MODULE_INITIALIZED}`], module.name, module);
	}
	/**
	 * Scaffolding around performing a callback on all registered modules
	 *
	 * @param {Function} action
	 * @memberof moduleManager
	 * @private
	 */
	function performModuleAction(action, queueUp) {
		const modules = orderBy(Object.values(registeredModules), ['index'], ['asc']);
		for (let index = 0; index < modules.length; index += 1) {
			const module = registeredModules[modules[index].name];
			if (module) {
				action(module, module.name);
			}
		}
		// TODO: this appears to be broken. MLS
		if (queueUp) {
			onRegisterQueue.push((module, moduleName) => (module ? action(module, moduleName) : false));
		}
	}
	/**
	 * Processes all module initialize callbacks
	 *
	 * @memberof moduleManager
	 * @private
	 */
	function onInitialize() {
		if (!initialized) {
			performModuleAction((module) => initializeOneModule(module), true);
		}
	}
	/**
	 * Handles safely modifying units for each unit and additionally rolls back changes if errors are encountered
	 *
	 * @param {BidBarrel~AdUnit[]} units
	 * @returns {BidBarrel~AdUnit[]} Modified ad unit collection
	 * @memberof moduleManager
	 * @private
	 */
	function bidRequest(units) {
		let modifiedUnits = units;
		performModuleAction((module) => {
			if (module.bidRequest) {
				const rollback = cloneDeep(modifiedUnits);
				makeSafe(
					() => {
						modifiedUnits = module.bidRequest(modifiedUnits);
					},
					mmLogger.atVerbosity(1).logError,
					() => {
						modifiedUnits = rollback;
					}
				);
			}
		});
		return modifiedUnits;
	}
	/**
	 * Performs the bids requested hook
	 *
	 * @param {Function} next
	 * @param {BidBarrel~AdUnit[]} unitCollection
	 * @memberof moduleManager
	 * @private
	 */
	function bidRequestHook(next, unitCollection) {
		// eslint-disable-next-line no-param-reassign
		unitCollection = bidRequest(cloneDeep(unitCollection));
		next(unitCollection);
	}
	/**
	 * Sets up BidBarrel object hooks
	 *
	 * @memberof moduleManager
	 * @private
	 */
	function setupHooks() {
		headerBidderRequest.before(bidRequestHook);
	}
	/**
	 * On load event handler that performs all necessary setup around the module managers predefined hooks
	 *
	 * @param {BidBarrel} bb
	 * @memberof moduleManager
	 * @private
	 */
	// eslint-disable-next-line no-unused-vars
	function onLoad(bb) {
		setupHooks();
	}

	/**
	 * Deregisters a module and all modules
	 *
	 * @param {String} name the module name
	 * @memberof moduleManager
	 * @private
	 */
	function deregister(name) {
		const module = registeredModules[name];
		if (module.deregister) {
			makeSafe(() => module.deregister(), mmLogger.atVerbosity(1).logError);
		}
		eventEmitter.emit([MODULE_DEREGISTERED, `${name}.${MODULE_DEREGISTERED}`], name, module);
		registeredModules = omit(registeredModules, [name]); // orphans the module, may want to delete instead
	}
	/**
	 * Gets all registered module names
	 *
	 * This method is available via `BidBarrel.exposedApi().getRegisteredModules()`
	 * @returns {String[]}
	 * @memberof moduleManager
	 * @public
	 * @exposed
	 */
	function getRegisteredModules() {
		return Object.keys(registeredModules);
	}
	/**
	 * Checks if a module is enabled/defined
	 *
	 * @param {String} moduleName
	 * @returns {Boolean}
	 * @memberof moduleManager
	 * @private
	 */
	function isEnabled(moduleName) {
		return typeof registeredModules[moduleName] !== 'undefined';
	}
	/**
	 * Allows processing callbacks only if a module is enabled and available
	 *
	 * @param {String} moduleName
	 * @param {Function} viaCallback
	 * @param {boolean} [runAnyway=false]
	 * @memberof moduleManager
	 * @private
	 */
	function viaModule(moduleName, viaCallback, runAnyway = false) {
		if (isEnabled(moduleName)) {
			viaCallback({ ...registeredModules[moduleName], isEnabled: true });
		} else if (runAnyway) {
			viaCallback({ isEnabled: false });
		}
	}
	/**
	 * Handles all logic around dependencies of modules
	 *
	 * @param {String[]} dependsOn array of module names that the current module depends on
	 * @param {Object} module module object
	 * @returns {Object}
	 * @memberof moduleManager
	 * @private
	 */
	function withDependenciesConsidered(dependsOn, module) {
		// eslint-disable-next-line no-param-reassign
		dependsOn = dependsOn.constructor === Array ? dependsOn : [dependsOn];
		const allDependenciesEnabled = () => dependsOn.reduce((result, moduleName) => result && isEnabled(moduleName), true);
		eventEmitter.on(MODULE_DEREGISTERED, () => {
			if (!allDependenciesEnabled()) {
				deregister(module.name);
			}
		});
		if (allDependenciesEnabled()) {
			// eslint-disable-next-line no-use-before-define
			return register(module);
		}
		eventEmitter.on(MODULE_REGISTERED, () => {
			if (allDependenciesEnabled()) {
				// eslint-disable-next-line no-use-before-define
				register(module);
			}
		});

		return module;
	}
	/**
	 * Registration method. This method handles setting up a module.
	 *
	 * @param {Object} module
	 * @param {boolean|String[]} [dependency=false] A set of named dependencies such that a module is only registered if the modules it depends on are registered
	 * @param {Object} [options={}] Module handling options
	 * @returns {Object} the registered module
	 * @memberof moduleManager
	 * @private
	 */
	function register(module, dependency = false, options = {}) {
		if (options.queue) {
			const queuePush = options.queue.push || options.queue;
			queuePush((resolve) => {
				register(module, dependency, omit(options, ['queue']));
				if (typeof resolve === 'function') {
					resolve();
				}
			});
			return module;
		}
		if (options.gate && !gateways.isOpen(options.gate)) {
			gateways.onOpen(() => {
				register(module, dependency, omit(options, ['gate']));
				if (adLibInitialized() === true) {
					// AdLib already fired INITIALIZED so we need to ping the module's initialize manually
					initializeOneModule(module);
				}
			}, options.gate);
			mmLogger.logInfo(`Delaying registration of module ${module.name} for gateway ${options.gate}`);
			return module;
		}
		const { name } = module;
		if (dependency) {
			// eslint-disable-next-line no-param-reassign
			module.dependencies = dependency;
			return withDependenciesConsidered(dependency, module);
		}
		if (!features.get([`${MOD_SUPPRESS}.${name}`, `${MOD_SUPPRESS}.all`])) {
			if (!registeredModules[name] || !registeredModules[name].isRegistered) {
				registeredModules[name] = module;
				registeredModules[name].isRegistered = true;
				registeredModules[name].index = registeredModules[name].index || Object.keys(registeredModules).length - 1;
				if (registeredModules[name].register) {
					makeSafe(() => registeredModules[name].register(), mmLogger.atVerbosity(1).logError);
				}
				mmLogger.atVerbosity(2).logInfo('Registering Module', name, registeredModules[name]);
				eventEmitter.emit([MODULE_REGISTERED, `${module.name}.${MODULE_REGISTERED}`], name, registeredModules[name]);
				for (let index = 0; index < onRegisterQueue.length; index += 1) {
					const callback = onRegisterQueue[index];
					callback(registeredModules[name], name);
				}
			} else if (registeredModules[name]) {
				// eslint-disable-next-line no-param-reassign
				module = registeredModules[name];
			}
			return registeredModules[name];
		}
		mmLogger.atVerbosity(2).logInfo('Module Supressed', name, module);
		return module;
	}

	eventEmitter.on(INITIALIZE, onInitialize);
	eventEmitter.on(LOAD, onLoad);
	exposureApi.expose({ getRegisteredModules });

	return {
		register,
		deregister,
		isEnabled,
		viaModule,
		gateways,
		apiReady: true,
	};
})();
