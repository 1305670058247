import defaultsDeep from 'lodash/defaultsDeep';
import { logger } from '../../utilities/logger.js';
import { requestManager } from '../../requestManager.js';
import { renderScript } from '../../utilities/renderScript.js';
import { hookedFn } from '../../utilities/hookedFunction.js';
import { cloneDeep } from '../../utilities/cloneDeep.js';
import memoize from '../../utilities/helpers/memoize.js';
// import Promise from 'promise-polyfill';
import { exposureApi } from '../../exposureApi.js';
import { moduleManager } from '../../moduleManager.js';
import { eventEmitter } from '../../events.js';
import { features } from '../../features.js';
import { getConfig, setConfig } from '../../config.js';
import { gptModule } from './googletag.js';
import { dom } from '../../global.js';
import CONSTANTS from '../../constants.json';
import { getUnits } from '../../unitManager.js';
// import { urlQueryAsObject } from '../../utilities/queryParams';
import set from '../../utilities/helpers/set.js';
import get from '../../utilities/helpers/get.js';

import omit from '../../utilities/helpers/omit.js';
import { errorReporting } from '../../services/errorReporting.js';
import errorReplacer from '../../utilities/helpers/errorReplacer.js';

const amazonLogger = logger({ name: 'amazon', textColor: '#FFF', bgColor: '#f08804' });

// constants
const {
	MODULES: { AMAZON_HEADER_BIDDING, GEOLOCATION, VIDEO },
	EVENTS: { HEADER_BIDDING_REQUEST, HEADER_BIDDING_RESPONSE },
	QUERY_PARAMS: { BID_SUPPRESS },
} = CONSTANTS;

const TEN_MINUTES = 10 * 60 * 1000; // 10 mins in ms

/**
 * Amazon Header Bidding Adapter
 *
 * This module exposes `getAmazonBids` via `BidBarrel.exposedApi()`
 *
 * @module Amazon
 * @private
 */
// eslint-disable-next-line func-names
const amazonModule = (function () {
	/**
	 * Flag to track whether apstag.init has been called
	 *
	 * @memberof Amazon
	 * @private
	 */
	let initCalled = false;
	/**
	 * Flag to track geolocation pbu id
	 *
	 * @memberof Amazon
	 * @private
	 */
	let geoSet = false;
	/**
	 * Collection of all amazon bid sets
	 *
	 * @memberof Amazon
	 * @private
	 */
	const bidSets = [];
	/**
	 * Tracks allowed sizes for bid requests
	 *
	 * @memberof Amazon
	 * @private
	 */
	let allowedSizes = [];
	/**
	 * Internal object tracking video bid targeting
	 *
	 * @memberof Amazon
	 * @private
	 */
	const videoBidTargeting = {};
	/**
	 * Sets up global apstag object
	 *
	 * @memberof Amazon
	 * @private
	 */
	function setupGlobals() {
		// eslint-disable-next-line no-underscore-dangle
		const queueUp = (c, r) => dom().window.apstag._Q.push([c, r]);
		dom().window.apstag = dom().window.apstag || {
			_Q: [],
			// eslint-disable-next-line
			init: function () {
				// eslint-disable-next-line prefer-rest-params
				queueUp('i', arguments);
			},
			// eslint-disable-next-line
			fetchBids: function () {
				// eslint-disable-next-line prefer-rest-params
				queueUp('f', arguments);
			},
			// eslint-disable-next-line
			setDisplayBids: function () {},
		};
		renderScript({
			id: 'bb-amazon',
			async: true,
			src: '//c.amazon-adsystem.com/aax2/apstag.js',
		});
	}
	/**
	 * Handles applying amazon params(targeting) to video ad url object
	 *
	 * @param {Function} next next hook fn
	 * @param {BidBarrel~AdUnit} unit unit config
	 * @param {Number} index ad index
	 * @param {BidBarrel~VideoUrlOptions} options options config
	 * @memberof Amazon
	 * @private
	 */
	function applyVideoBidParams(next, unit, index, opts) {
		const existingCustParams = get(opts, 'params.cust_params') || {};

		const amznTargeting = omit(videoBidTargeting[unit.code], ['timeout']);

		set(opts, 'params.cust_params', defaultsDeep(amznTargeting, existingCustParams));
		next(unit, index, opts);
	}

	/**
	 * Initialize amazon adapter
	 *
	 * @memberof Amazon
	 * @private
	 */
	const initialize = hookedFn('sync', () => {
		amazonLogger.atVerbosity(3).logInfo('Initialized');
		setupGlobals();
		moduleManager.viaModule(VIDEO, ({ getVideoUnitParams }) => {
			getVideoUnitParams.before(applyVideoBidParams);
		});
		requestManager.register('amazon');
	});

	/**
	 * Sets the config for the amazon adapter
	 *
	 * @param {object} bidBarrelConfig
	 * @memberof Amazon
	 * @private
	 */
	function register() {
		getConfig('amazon.allowedSizes', (sizes) => {
			allowedSizes = sizes.map((size) => size.join('x'));
		});
		if (!geoSet) {
			moduleManager.viaModule(GEOLOCATION, ({ onRegionSet, getRegionCode }) => {
				geoSet = true;
				const setRegionConfig = () => {
					let regionConfig = {};
					if (getConfig(`amazon.regionConfig.${getRegionCode()}`)) {
						regionConfig = getConfig(`amazon.regionConfig.${getRegionCode()}`);
						amazonLogger.atVerbosity(3).logInfo('Applying region based config', regionConfig);
					}
					setConfig('amazon', { ...getConfig('amazon'), ...regionConfig });
				};
				const regionCode = getRegionCode();
				if (regionCode) {
					setRegionConfig();
				} else {
					amazonLogger.atVerbosity(3).logInfo('Listening for region code');
					onRegionSet(setRegionConfig);
				}
			});
		}
	}
	/**
	 * Creates a amazon unit from a prebid unit
	 *
	 * @param {object} unit prebid unit
	 * @returns {object} amazon unit
	 * @memberof Amazon
	 * @method
	 * @private
	 */
	const createFromUnit = memoize(
		(unit) => {
			const unitId = unit.elementId || unit.code;
			const tamUnit = {
				slotID: unitId,
				slotName: `${getConfig('dfpPathObj.string')}/${unitId}`,
			};

			if (unit.isVideo) {
				tamUnit.mediaType = 'video';
			} else {
				tamUnit.sizes = unit.getSizes().filter((size) => typeof size !== 'string' && allowedSizes.indexOf(size.join('x')) >= 0);
			}

			return tamUnit;
		},
		(unit) => getConfig('dfpPathObj.string') + (unit.elementId || unit.code)
	);
	/**
	 * Parses the bid request units and looks for amazon specific unit configs
	 *
	 * @param {object[]} units prebid units to parse
	 * @returns {Array<Array<Object>>}
	 * @memberof Amazon
	 * @private
	 */
	function parseUnits(units) {
		const moduleUnits = [];
		const sendBackUnits = [];
		for (let index = 0; index < units.length; index += 1) {
			const unit = units[index];
			const newUnit = cloneDeep(unit);
			newUnit.bids = [];
			for (let bidConfigIndex = 0; bidConfigIndex < unit.bids.length; bidConfigIndex += 1) {
				const bidConfig = unit.bids[bidConfigIndex];
				if (bidConfig.bidder === 'amazon' && !features.get([`${BID_SUPPRESS}.${unit.code}.amazon`, `${BID_SUPPRESS}.${unit.code}.all`])) {
					// explicit add
					if (bidConfig.params.included === '1' || bidConfig.params.included === true) {
						moduleUnits.push(createFromUnit(unit));
					} else {
						moduleUnits.push({ ...createFromUnit(unit), ...bidConfig.params });
					}
				} else {
					newUnit.bids.push(bidConfig);
				}
			}
			sendBackUnits.push(newUnit);
		}
		return [moduleUnits, sendBackUnits];
	}
	/**
	 * Handles parsing out the video bids and setting the targeting on the ad unit
	 *
	 * @param {AmazonBid[]} bids
	 * @returns {AmazonBid[]} non-video bids
	 * @memberof Amazon
	 * @private
	 */
	function setVideoBidTargeting(bids) {
		const units = getUnits();
		const resultBids = [];
		for (let index = 0; index < bids.length; index += 1) {
			const { slotID } = bids[index];
			if (units[slotID].isVideo) {
				if (videoBidTargeting[slotID] && videoBidTargeting[slotID].timeout) {
					clearTimeout(videoBidTargeting[slotID].timeout);
				}
				videoBidTargeting[slotID] = bids[index].targeting;
				videoBidTargeting[slotID].timeout = setTimeout(() => {
					if (videoBidTargeting[slotID].amzniid && videoBidTargeting[slotID].amzniid === bids[index].targeting.amzniid) {
						videoBidTargeting[slotID] = {};
					}
				}, TEN_MINUTES);
			} else {
				resultBids.push(bids[index]);
			}
		}
		return resultBids;
	}

	/**
	 * Handler for when the amazon bids are returned
	 *
	 * ```
	 *  var AmazonBid = {
	 *    "amzniid": "IuFmog3sY_tlWRamYUtNohIAAAFmF8b7lgEAAAvdAYyvWRc",
	 *    "amxnbid": "1sxpmo0",
	 *    "amznp": "r291xc",
	 *    "slotID": "mpu_middle",
	 *    "size": "300x250",
	 *    "amznsz": "300x250"
	 *  }
	 * ```
	 *
	 * @param {AmazonBid[]} bids - array of amazon bids
	 * @memberof Amazon
	 * @private
	 */
	function bidsBackHandler(bids) {
		bidSets.push(bids);
		amazonLogger.atVerbosity(1).logInfo('Amazon - Bids Returned', bids);
		// eslint-disable-next-line no-unused-vars
		gptModule.gptAction((gpt) => {
			dom().window.apstag.setDisplayBids();
			const nonVideoBids = setVideoBidTargeting(bids);
			setTimeout(() => {
				for (let index = 0; index < nonVideoBids.length; index += 1) {
					const bid = nonVideoBids[index];
					if (bid.amznbid !== '2') {
						const slotTargeting = gptModule.getSlotTargeting(bid.slotID);
						if (slotTargeting.amzniid === bid.amzniid && bid.slotID && slotTargeting.amzniid === bid.amzniid) {
							const slot = gptModule.getSlot(bid.slotID);
							if (slot) {
								amazonLogger.logInfo('Clearing Amazon Targeting on unit', bid.slotID, bid);
								slot.clearTargeting(Object.keys(bid));
							}
						}
					}
				}
			}, TEN_MINUTES);
			requestManager.done('amazon');
		});
	}
	/**
	 * Fetches bids for Amazon
	 *
	 * ```
	 *   var AmazonBidderUnit = {
	 *    "sizes": [
	 *      [300, 250],
	 *      [320, 50]
	 *    ],
	 *    "slotID": "mpu_middle",
	 *    "slotName": "22309610186/aw-cnet/mpu_middle"
	 *   };
	 * ```
	 *
	 * @param {AmazonBidderUnit[]} units - Array of unit configurations to send off to amazon
	 * @memberof Amazon
	 * @private
	 */
	function fetchBids(units) {
		return new Promise((resolve, reject) => {
			try {
				if (!initCalled) {
					// logInfo("Amazon - Initializing", config);
					dom().window.apstag.init({
						pubID: getConfig('amazon.pubID'),
						timeout: getConfig('bidderTimeout'),
						adServer: getConfig('amazon.adServer'),
						deals: true,
						signals: { ortb2: dom().window.pbjs.getConfig('ortb2') },
					});
					initCalled = true;
				}
				amazonLogger.atVerbosity(1).logMessage('Amazon - Setting FPD signals from ortb2', { ortb2: dom().window.pbjs.getConfig('ortb2') });
				amazonLogger.atVerbosity(1).logMessage('Amazon - Fetching bids', units);
				dom().window.apstag.fetchBids(
					{
						slots: units,
						timeout: getConfig('bidderTimeout'),
					},
					(bids) => {
						bidsBackHandler(bids);
						eventEmitter.emit([HEADER_BIDDING_RESPONSE, `${AMAZON_HEADER_BIDDING}.${HEADER_BIDDING_RESPONSE}`], AMAZON_HEADER_BIDDING, units, bids);
						resolve(this);
					}
				);
				eventEmitter.emit([HEADER_BIDDING_REQUEST, `${AMAZON_HEADER_BIDDING}.${HEADER_BIDDING_REQUEST}`], AMAZON_HEADER_BIDDING, units);
			} catch (err) {
				amazonLogger.atVerbosity(1).logError('Amazon Fetch Bids Error -', err.message);
				const errorObj = new Error(`Amazon Fetch Bids Error - ${err.message}. ${JSON.stringify(err, errorReplacer)}`);
				errorReporting.report(errorObj);
				requestManager.done('amazon');
				reject(err);
			}
		});
	}

	/**
	 * Listen for request bids
	 *
	 * @memberof Amazon
	 * @private
	 */
	function bidRequest(adUnits) {
		if (features.get([`${BID_SUPPRESS}.amazon`, `${BID_SUPPRESS}.all`])) {
			requestManager.done('amazon');
			return adUnits;
		}
		const [amazonUnits, modifiedUnits] = parseUnits(adUnits);
		if (amazonUnits.length > 0) {
			fetchBids(amazonUnits);
		} else {
			requestManager.done('amazon');
		}
		return modifiedUnits;
	}

	/**
	 * Gets the bid sets for the amazon adapter
	 *
	 * Requires module: `amazon`
	 *
	 * @returns <Array<Array<AmazonBid>> bidSets
	 * @memberof Amazon
	 * @private
	 * @exposed
	 */
	function getAmazonBids() {
		return bidSets;
	}

	exposureApi.expose({
		getAmazonBids,
	});

	return {
		initialize,
		register,
		getAmazonBids,
		name: AMAZON_HEADER_BIDDING,
		bidRequest,
	};
})();

export const amazon = moduleManager.register(amazonModule, null, { gate: 'consentGiven' });
export default amazon;
