import unfetch from 'unfetch';
import { dom } from '../global.js';

const canAbort = typeof dom().window.AbortController !== "undefined";

export const fetch = dom().window.fetch || unfetch.default || unfetch;

export const fetchWithAbort = function fwa(url, opts = {}){
    let cancelMethod = () => false;
    if(canAbort){
        const AbortControllerCons = dom().window.AbortController;
        const controller = new AbortControllerCons();
        const {signal} = controller;
        cancelMethod = () => controller.abort();
        // eslint-disable-next-line no-param-reassign
        opts.signal = signal;
    }
    const fetchReq = fetch(url, opts);
    fetchReq.cancel = cancelMethod;
    return fetchReq;
}
