/* eslint-disable no-underscore-dangle */
import { getConfig } from '../config.js';
import { dom } from '../global.js';
import { getPageTargeting } from '../pageTargeting.js';
import memoize from './helpers/memoize.js';
import { hookedFn } from './hookedFunction.js';
import parseUrl from './parseUrl.js';
import { urlQueryAsObject } from './queryParams.js';

const BUNDLE_SCRIPTNAME = `$bidbarrel.filename$`;
function isStageQp() {
	const query = urlQueryAsObject();
	return query.adTargeting_env === 'stage';
}

function getScriptEnvVal() {
	if (!dom().window.performance.getEntriesByType) return null;
	const scriptName = BUNDLE_SCRIPTNAME.split('.')[0];
	const script = dom()
		.window.performance.getEntriesByType('resource')
		.find((v) => v.name.indexOf(scriptName) >= 0);
	if (!script || !script.name) return null;
	const url = parseUrl(script.name);
	if (url.hostname && url.hostname.indexOf('at.adtech.redventures.io') >= 0) {
		const re = new RegExp(`/lib/dist/(.*)/${scriptName}`, 'gm');
		const matches = re.exec(url.pathname);
		if (matches && matches.length >= 2 && matches[1]) {
			return matches[1];
		}
		return null;
	}
	return null;
}
function isStageScript() {
	return getScriptEnvVal() === 'stage';
}

function isStageTargeting() {
	const env = getPageTargeting('env');
	return ['stage', 'staging', 'dev'].indexOf(env) >= 0;
}
function isStageConfig() {
	const env = getConfig('pageTargeting.env');
	return ['stage', 'staging', 'dev'].indexOf(env) >= 0;
}
function _getEnvVal() {
	const qp = urlQueryAsObject().adTargeting_env; // || getConfig('pageTargeting.env') || getPageTargeting('env');
	// const result = ['prs', 'stage', 'prod', 'develop', 'main'].indexOf(qp) >= 0 ? qp : null;
	// eslint-disable-next-line no-use-before-define
	return qp || getScriptEnvVal() || (isStagingEnv() ? 'stage' : 'prod');
}

/**
 * Function to determine if current environment is a staging environment
 *
 * @memberof BidBarrel
 * @returns {boolean}
 * @method
 * @exposed
 */
export const isStagingEnv = hookedFn('sync', () => isStageQp() || isStageScript() || isStageTargeting() || isStageConfig());

export function getEnvCfg(cfg) {
	if (typeof cfg !== 'object' || Array.isArray(cfg)) return cfg;
	if (isStagingEnv() && (cfg.dev || cfg.stage)) {
		return cfg.dev || cfg.stage;
	}
	return cfg.prod;
}

export const getEnvVal = memoize(_getEnvVal);
